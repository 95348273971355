import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import {
  getOneprescriptionLayout,
  prescriptionLayoutList,
  loadPrescriptionLayouts,
  fetchCachedPrescriptionLayout,
} from "ducks/prescriptionLayout";
import {
  fetchOneDoctorPreference,
  getOneDoctorPreferences,
} from "ducks/doctorPreferences";
import {
  loadLocations,
  locationByLocaID,
  doctorLocation,
} from "ducks/location";
import { loadUserMe, getUserMe } from "ducks/user";
import { getClinicSelector } from "ducks/schedule";
import {
  Location,
  Preferences,
  PrescriptionLayoutFilter,
  PrescriptionLayoutView,
} from "@udok/lib/api/models";
import { PrescriptionAny } from "@udok/lib/internal/constants";
import moment from "moment";

export const usePrescriptionListLayouts = (
  filter?: PrescriptionLayoutFilter & { plteID?: string; doctor?: boolean }
) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      filter?.plteID
        ? dispatch(fetchCachedPrescriptionLayout(filter.plteID))
        : undefined,
      dispatch(loadPrescriptionLayouts(filter)),
    ]).finally(() => setLoading(false));
  }, [filter, dispatch]);

  const { prescriptionLayouts } = useSelector(prescriptionLayoutList);
  const { clinByID } = useSelector(getClinicSelector);

  const listFilter = React.useCallback(
    (layout: PrescriptionLayoutView) => {
      if (layout?.deletedAt) {
        return false;
      }
      if (filter) {
        if (filter?.plteID && layout?.plteID === filter.plteID) {
          return true;
        }
        if (filter?.doctor && !layout?.doctID) {
          return false;
        }
        if (filter?.clinID && layout?.clinID !== filter.clinID) {
          return false;
        }
        if (filter?.locaID && layout.locations.indexOf(filter.locaID) !== -1) {
          return false;
        }
        if (
          filter?.prescriptionModel &&
          layout?.prescriptionModel !== filter.prescriptionModel &&
          layout?.prescriptionModel !== PrescriptionAny
        ) {
          return false;
        }
      }
      return true;
    },
    [filter]
  );

  const list = prescriptionLayouts
    .filter(listFilter)
    .sort((a, b) =>
      moment(b?.createdAt).diff(moment(a?.createdAt))
    ) as PrescriptionLayoutView[];

  return [loading, list, clinByID] as [
    typeof loading,
    typeof list,
    typeof clinByID
  ];
};

export const usePrescriptionGetLayout = (plteID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(fetchCachedPrescriptionLayout(plteID)),
      dispatch(loadUserMe()),
      dispatch(
        fetchOneDoctorPreference(
          Preferences.prescriptionUseDigitalizedSignature
        )
      ),
    ]).finally(() => setLoading(false));
  }, [plteID, dispatch]);

  const getLayout = React.useCallback(getOneprescriptionLayout({ plteID }), [
    plteID,
  ]);
  const getPreference = React.useCallback(
    getOneDoctorPreferences({
      prefID: Preferences.prescriptionUseDigitalizedSignature,
    }),
    []
  );

  const { layout } = useSelector(getLayout);
  const { currentUser } = useSelector(getUserMe);
  const { location: signaturePref } = useSelector(getPreference);
  const signatureVisible = !!signaturePref?.options?.useInSignature;

  return [loading, layout, currentUser, signatureVisible] as [
    typeof loading,
    typeof layout,
    typeof currentUser,
    typeof signatureVisible
  ];
};

export const useTemplateView = (plteID?: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(fetchOneDoctorPreference(Preferences.presDefaultLocation)),
      dispatch(loadLocations()),
      dispatch(loadUserMe()),
      plteID ? dispatch(fetchCachedPrescriptionLayout(plteID)) : undefined,
    ]).finally(() => setLoading(false));
  }, [dispatch, plteID]);

  const getPreference = React.useCallback(
    getOneDoctorPreferences({ prefID: Preferences.presDefaultLocation }),
    []
  );
  const getLayout = React.useCallback(
    getOneprescriptionLayout({ plteID: plteID ?? "" }),
    [plteID]
  );
  const { location: locationPref } = useSelector(getPreference);
  const locationByID = useSelector(locationByLocaID);
  const doctorLocations = useSelector(doctorLocation);
  const { currentUser } = useSelector(getUserMe);
  const { layout } = useSelector(getLayout);

  const defaulID: string | undefined =
    layout?.locations?.[0] ||
    locationPref?.options?.locaID ||
    doctorLocations[0];

  const location: Location | undefined = locationByID[defaulID ?? 0];

  return [loading, location, currentUser] as [
    typeof loading,
    typeof location,
    typeof currentUser
  ];
};

export const useCreateTemplate = (plteID?: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(loadUserMe()),
      dispatch(
        fetchOneDoctorPreference(
          Preferences.prescriptionUseDigitalizedSignature
        )
      ),
      ...(plteID ? [dispatch(fetchCachedPrescriptionLayout(plteID))] : []),
    ]).finally(() => setLoading(false));
  }, [dispatch, plteID]);

  const getPreference = React.useCallback(
    getOneDoctorPreferences({
      prefID: Preferences.prescriptionUseDigitalizedSignature,
    }),
    []
  );
  const getLayout = React.useCallback(
    getOneprescriptionLayout({ plteID: plteID ?? "" }),
    [plteID]
  );
  const { location: signaturePref } = useSelector(getPreference);
  const { currentUser } = useSelector(getUserMe);
  const { layout } = useSelector(getLayout);

  const signatureVisible = !!signaturePref?.options?.useInSignature;
  let layoutC = layout;
  if (!!layout) {
    layoutC = { ...layout, name: `${layout.name} (Copia)` };
  }

  return [loading, currentUser, signatureVisible, layoutC] as [
    typeof loading,
    typeof currentUser,
    typeof signatureVisible,
    typeof layoutC
  ];
};
