import { createSelector } from "reselect";
import { hen, Hen } from "@udok/lib/internal/store";
import { RootState } from "ducks/state";
import { getUserMe } from "./user";
import { patientRepositoryByUserID, patientRepository } from "./patient";
import axios from "axios";
import Websocket from "@udok/lib/internal/socket/websocket";
import moment from "moment";
import { getToken, UNAUTHORIZED } from "ducks/auth";
import { ThunkAction } from "redux-thunk";
import { ActivitiesGroup, Message, Msg } from "@udok/lib/api/models";

moment.locale("pt-br");

export type Error = any;

export type Profile = {
  userID: string;
  avatar: string;
  name: string;
  patiID?: string;
};

function getMsgPreview(msg: Msg) {
  const txt: { [k: string]: string } = {
    audio: "Enviou um áudio",
    archive: "Enviou um arquivo",
    image: "Enviou uma imagem",
  };
  return txt[msg.type] ?? msg.value ?? "Enviou uma mensagem";
}

type MsgFilter = {
  BeforeID: string | null;
  FromUserID: string; // loggedin user
  Limit: number;
  ToUserID: string; // user being chatted with
};

type OutgoingMessage = {
  messID: string;
  timestamp: number;
  error?: string;
};

export type Activity = {
  activeAt: string;
  messagePreview: string;
  unreadCount: number;
  user: any;
  username: string;
  withUserID: string;
};

export const WS = {
  Idle: "idle",
  Connected: "connected",
  Disconnected: "disconnected",
  Connecting: "connecting",
  Sending: "sending",
};

export const WSsocket = {
  0: WS.Idle,
  1: WS.Connected,
  2: WS.Disconnected,
  3: WS.Connecting,
  4: WS.Sending,
};

export enum ActionTypes {
  Connect = "WSConnect",
  Connected = "WSConnected",
  Send = "Wssand",
  Receive = "WSReceive",
  Disconnect = "WSDisconnect",
  Reset = "socket.reset",
  LoadStartMessage = "listmessage.LoadStartMessage",
  messageList = "listmessage.messageList",
  LoadErrorMessage = "listmessage.LoadErrorMessage",
  LoadStartActivity = "listmessage.LoadStartActivity",
  activityList = "listmessage.activityList",
  LoadErrorActivity = "listmessage.LoadErrorMessage",
  ListMessage = "ListMessage",
  ListActivity = "ListActivity",
  activityListGroup = "listmessage.activityListGroup",
  receiveMessage = "listmessage.receiveMessage",
  readMessage = "listmessage.readMessage",
  clientsStatusList = "listmessage.clientsStatusList",
  deleteMessage = "listmessage.deleteMessage",
  Logout = "Logout",
  clearMessages = "clearMessages",
  clearActivities = "clearActivities",
  clearActivitiesGroup = "clearActivitiesGroup",
}

export type Actions = {
  Connect: { type: ActionTypes.Connect };
  Connected: { type: ActionTypes.Connected };
  Send: { type: ActionTypes.Send; payload: any };
  Receive: { type: ActionTypes.Receive };
  Disconnect: { type: ActionTypes.Disconnect };
  Reset: { type: ActionTypes.Reset };
  LoadStartMessage: { type: ActionTypes.LoadStartMessage };
  messageList: {
    type: ActionTypes.messageList;
    payload: { appliedFilter: MsgFilter; messages: Array<Msg> };
  };
  LoadErrorMessage: { type: ActionTypes.LoadErrorMessage; payload: Error };
  LoadStartActivity: { type: ActionTypes.LoadStartActivity };
  activityList: { type: ActionTypes.activityList; payload: any };
  activityListGroup: { type: ActionTypes.activityListGroup; payload: any };
  LoadErrorActivity: { type: ActionTypes.LoadErrorActivity; payload: Error };
  receiveMessage: {
    type: ActionTypes.receiveMessage;
    payload: { context: string; message: Msg };
  };
  readMessage: { type: ActionTypes.readMessage; payload: any };
  clientsStatusList: { type: ActionTypes.clientsStatusList; payload: any };
  Logout: { type: ActionTypes.Logout };
  clearMessages: { type: ActionTypes.clearMessages };
  clearActivities: { type: ActionTypes.clearActivities };
  clearActivitiesGroup: { type: ActionTypes.clearActivitiesGroup };
  deleteMessage: {
    tyle: ActionTypes.deleteMessage;
    payload: { message: Message };
  };
};

export type LoadingSections = {
  "listmessage.list": boolean;
  "listactivity.list": boolean;
};

export type InitialState = {
  profileByUserID: { [userID: string]: Profile };
  messageByID: { [messID: string]: Msg };
  messageByMegrID: { [megrID: string]: Array<string> };
  messagesWithUserID: { [userID: string]: Array<string> };
  outgoingMessages: Array<OutgoingMessage>;
  messages: Array<any>;
  clientsStatus: Array<any>;
  activities: any;
  activitiesGroup: ActivitiesGroup[];
  status: string;
  loading: LoadingSections;
  error?: Error;
  granted?: boolean;
};

//reducers
const initialState: InitialState = {
  profileByUserID: {},
  messageByID: {},
  messageByMegrID: {},
  messagesWithUserID: {},
  outgoingMessages: [],
  status: WS.Disconnected,
  clientsStatus: [],
  messages: [],
  activities: [],
  activitiesGroup: [],
  loading: {
    "listactivity.list": false,
    "listmessage.list": false,
  },
  error: undefined,
  granted: undefined,
};

let socket: any = null;
class SocketSlice extends Hen<InitialState> {
  socketConnect() {
    this.state.status = WS.Connecting;
  }
  socketConnected(event: any) {
    this.state.status = WS.Connected;
  }
  socketStatusChange(s: string) {
    this.state.status = s;
  }
  socketDisconnect(event: any) {
    this.state.status = WS.Disconnected;
  }
  socketReceived(event: any) {
    this.state.status = WS.Connected;
  }
  socketSent(event: Message) {
    let msg = {
      ...event,
      messID: event.messID,
      loading: true,
      fromID: event.fromID,
      toID: event.toID,
      megrID: event.megrID,
    };
    this.state.messageByMegrID = this.state.messageByMegrID ?? {};
    this.state.messageByID[msg.messID] = msg as any as Msg;
    let megr = new Set(
      this.state.messageByMegrID
        ? this.state.messageByMegrID[msg.megrID as any]
        : []
    );
    let to = new Set(this.state.messagesWithUserID[msg.toID] ?? []);
    let from = new Set(this.state.messagesWithUserID[msg.fromID] ?? []);
    megr.add(msg.messID);
    from.add(msg.messID);
    to.add(msg.messID);
    this.state.messagesWithUserID[msg.fromID] = Array.from(from);
    this.state.messagesWithUserID[msg.toID] = Array.from(to);
    if (msg.megrID && this.state.messageByMegrID) {
      this.state.messageByMegrID[msg.megrID] = Array.from(megr);
    }
    // add to outgoing queue
    this.state.outgoingMessages = [
      ...this.state.outgoingMessages,
      { timestamp: new Date().getTime(), messID: msg.messID },
    ];
  }
  mediaUploadFailed(messID: string) {
    const msg = this.state.messageByID[messID];
    this.state.outgoingMessages = this.state.outgoingMessages.map((out) =>
      out.messID === messID
        ? { ...out, error: `Failed to upload ${msg.type}` }
        : out
    );
  }
  profileLoaded(p: Profile) {
    this.state.profileByUserID ?? (this.state.profileByUserID = {});
    this.state.profileByUserID[p.userID] = p;
  }
  setMessageLoading(messID: string, load: boolean) {
    const message = this.state.messageByID[messID];
    const newMessage = {
      ...message,
      loading: load,
    };
    this.state.messageByID[messID] = newMessage;
  }
}
//Reducers
export const extraReducers = {
  [ActionTypes.messageList](state: InitialState, a: Actions["messageList"]) {
    state = state || {};
    let usersUpdated: Set<string> = new Set();
    let appoUpdate: Set<string> = new Set();
    state.messageByMegrID = state.messageByMegrID ?? {};
    a.payload.messages?.map((msg: Msg) => {
      state.messageByID[msg.messID] = msg;
      usersUpdated.add(msg.toID);
      usersUpdated.add(msg.fromID);
      if (msg.megrID) {
        appoUpdate.add(msg.megrID);
      }
      state.messagesWithUserID[msg.toID] =
        state.messagesWithUserID[msg.toID] ?? [];
      state.messagesWithUserID[msg.fromID] =
        state.messagesWithUserID[msg.fromID] ?? [];
      (state.messagesWithUserID[msg.toID] ?? []).push(msg.messID);
      (state.messagesWithUserID[msg.fromID] ?? []).push(msg.messID);
      if (msg.megrID) {
        state.messageByMegrID[msg.megrID as string] =
          state.messageByMegrID[msg.megrID as string] ?? [];
        state.messageByMegrID[msg.megrID as string].push(msg.messID);
      }
    });
    usersUpdated.forEach((userID) => {
      state.messagesWithUserID[userID] = Array.from(
        new Set(state.messagesWithUserID[userID] ?? [])
      );
    });
    appoUpdate.forEach((megrID) => {
      state.messageByMegrID[megrID] = Array.from(
        new Set(state.messageByMegrID[megrID] ?? [])
      );
    });
    state.loading["listmessage.list"] = false;
    return state;
  },
  [ActionTypes.receiveMessage](
    state: InitialState,
    a: Actions["receiveMessage"]
  ) {
    const msg = a.payload.message;
    const contextMessID = a.payload.context;
    state.messageByID[msg.messID] = msg;
    state.messageByMegrID = state.messageByMegrID ?? {};
    let megr = new Set(state.messageByMegrID[msg.megrID as any] ?? []);
    let to = new Set(state.messagesWithUserID[msg.toID] ?? []);
    let from = new Set(state.messagesWithUserID[msg.fromID] ?? []);
    megr.add(msg.messID);
    from.add(msg.messID);
    to.add(msg.messID);

    // remove context message
    delete state.messageByID[contextMessID];
    megr.delete(contextMessID);
    from.delete(contextMessID);
    to.delete(contextMessID);
    // remove from outgoing queue
    state.outgoingMessages = state.outgoingMessages.filter(
      (e) => e.messID !== contextMessID
    );

    state.messagesWithUserID[msg.fromID] = Array.from(from);
    state.messagesWithUserID[msg.toID] = Array.from(to);
    if (msg.megrID) {
      state.messageByMegrID[msg.megrID as string] = Array.from(megr);
    }

    // update activityList
    const idx = state.activities.findIndex(
      (act: any) => act.withUserID === msg.fromID
    );
    if (idx > -1) {
      state.activities[idx] = {
        ...state.activities[idx],
        unreadCount: (state.activities[idx].unreadCount ?? 0) + 1,
        messagePreview: getMsgPreview(msg),
      };
      state.activities = [...state.activities];
    }
    return state;
  },
  [ActionTypes.readMessage](state: InitialState, a: Actions["readMessage"]) {
    return state;
  },
  [ActionTypes.LoadStartMessage](
    state: InitialState,
    a: Actions["LoadStartMessage"]
  ) {
    state.loading["listmessage.list"] = true;
    return state;
  },
  [ActionTypes.LoadErrorMessage](
    state: InitialState,
    a: Actions["LoadErrorMessage"]
  ) {
    state.error = a.payload;
    state.loading["listmessage.list"] = false;
    return state;
  },
  [ActionTypes.activityList](
    state: InitialState,
    a: Actions["LoadStartActivity"]
  ) {
    state.loading["listactivity.list"] = true;
    return state;
  },
  [ActionTypes.activityList](state: InitialState, a: Actions["activityList"]) {
    if (!!a.payload.userID) {
      const idx = state.activities.findIndex(
        (act: any) => act.withUserID === a.payload.userID
      );
      state.activities[idx] = a.payload.messages[0] ?? state.activities[idx];
      state.activities = [...state.activities];
    } else {
      state.activities = a.payload.messages;
    }

    state.status = WS.Connected;
    state.loading["listactivity.list"] = false;
    return state;
  },
  [ActionTypes.LoadErrorActivity](
    state: InitialState,
    a: Actions["LoadErrorActivity"]
  ) {
    state.error = a.payload;
    state.loading["listactivity.list"] = false;
    return state;
  },
  [ActionTypes.activityListGroup](
    state: InitialState,
    a: Actions["activityListGroup"]
  ) {
    state.activitiesGroup = a.payload.messages;
    state.status = WS.Connected;
    state.loading["listactivity.list"] = false;
    return state;
  },
  [ActionTypes.clearActivitiesGroup](
    state: InitialState,
    a: Actions["clearActivitiesGroup"]
  ) {
    state.activitiesGroup = [];
    return state;
  },
  [ActionTypes.clientsStatusList](
    state: InitialState,
    a: Actions["clientsStatusList"]
  ) {
    const clientsStatus = state.clientsStatus;
    state.clientsStatus = { ...clientsStatus, ...a.payload };
    return state;
  },
  [ActionTypes.Logout](state: InitialState, a: Actions["Logout"]) {
    state = initialState;
    return state;
  },
  [ActionTypes.clearMessages](
    state: InitialState,
    a: Actions["clearMessages"]
  ) {
    state.messages = [];
    return state;
  },
  [ActionTypes.clearActivities](
    state: InitialState,
    a: Actions["clearActivities"]
  ) {
    state.activities = [];
    return state;
  },
  [ActionTypes.deleteMessage](
    state: InitialState,
    a: Actions["deleteMessage"]
  ) {
    const msg = a.payload.message;
    state.messageByID[msg.messID] = msg as any;
    return state;
  },
  [UNAUTHORIZED]: (state: InitialState) => {
    state = initialState;
    return state;
  },
};
export const [Reducer, actions] = hen(
  new SocketSlice(initialState),
  extraReducers
);

//Selectors
const mainSelector = (state: RootState) => state.socket;

export const getStatusSocket = createSelector(mainSelector, (state) => {
  return { statusSocket: state.status };
});

export const getLoadingMessages = createSelector(mainSelector, (state) => {
  return {
    loadingMessages: state.loading["listmessage.list"],
  };
});

export const getMess = createSelector(mainSelector, (state) => state.messages);
export const getMessageRepo = createSelector(
  mainSelector,
  (state) => state.messageByID
);

export const getMessageMegr = createSelector(
  mainSelector,
  (state) => state.messageByMegrID
);
export const getUserMessages = createSelector(
  mainSelector,
  (state) => state.messagesWithUserID
);
export const getUserProfiles = createSelector(
  mainSelector,
  (state) => state.profileByUserID
);

export const getMessages = (props: { userID: string }) =>
  createSelector(
    [
      getMessageRepo,
      getUserMessages,
      getUserProfiles,
      getUserMe,
      getToken,
      getActivities,
    ],
    (msg, userMsgs, profiles: any, currentUser, token, activities) => {
      const userID: any = props?.userID;
      const profile = {
        ...(profiles?.[userID] ?? {}),
        userID,
      };
      const messageList = userMsgs[profile.userID] || [];
      let messages: Array<any> = [];
      if (profile.userID) {
        messages = (messageList ?? [])
          .map((messID) => {
            const message = msg[messID];
            const file = message.data.file?.startsWith?.("file:///")
              ? message.data.file
              : message.data.fileID &&
                `${process.env.REACT_APP_API_PATH}/private-files/${message.data.fileID}/download`;
            return {
              _id: message.messID || message._id,
              createdAt: message.createdAt,
              fileID: message.data.fileID ? message.data.fileID : null,
              text:
                message.type !== "image" ? message.value || message.text : null,
              type: message.type || message.type,
              helper: false,
              status: message.status,
              loading: message.loading ? message.loading : false,
              timeAudio:
                message.type === "audio" ? message.data.timeAudio : null,
              uri: message.type === "archive" ? file : null,
              image: message.type === "image" ? file : null,
              audio: message.type === "audio" ? file : null,
              data: message.data,
              user: {
                currentUser: currentUser?.currentUser?.user,
                _id: message.fromID,
                avatar:
                  message.fromID === profile.userID
                    ? profile.avatar
                      ? `${process.env.REACT_APP_BASE_PATH}/files/${profile.avatar}`
                      : ""
                    : message.fromID === currentUser?.currentUser?.user?.userID
                    ? currentUser?.currentUser?.user?.avatar
                      ? `${process.env.REACT_APP_BASE_PATH}/files/${currentUser?.currentUser?.user?.avatar}`
                      : ""
                    : "",
              },
              deletedAt: message.deletedAt,
              megrID: message.megrID,
            };
          })
          .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));
      }

      const unreadMessages: Activity = (
        activities?.unreadActivities ?? []
      ).filter((m: any) => m.withUserID === userID)?.[0];

      return {
        unreadMessages,
        listMessages: messages || [],
        ...token,
        ...currentUser,
      };
    }
  );

export const getStatus = createSelector(mainSelector, (state) => ({
  status: state.status,
}));

export const getAct = createSelector(mainSelector, (state) => state.activities);

export const getActGroup = createSelector(
  mainSelector,
  (state) => state.activitiesGroup
);

export const getProfile = (state: RootState, props: { userID: string }) =>
  createSelector(mainSelector, (state) => {
    const userID = props.userID;
    return {
      profile: {
        ...(state.profileByUserID?.[userID] ?? {}),
        status: state.clientsStatus[userID as any],
      },
    };
  });

export const userStatusView = (props: { userID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      status: state.clientsStatus[props.userID as any],
    };
  });

export const getActivities = createSelector(
  getAct,
  patientRepository,
  patientRepositoryByUserID,
  (act, patientByID, patientByUserID) => {
    let unread: any = [];
    let activities: any = [];

    act.forEach((activity: any) => {
      const patiID = patientByUserID[activity.withUserID];
      let user = patientByID[patiID];
      let a = {
        ...(activity || {}),
        user: user,
      };
      if (activity.unreadCount > 0) {
        unread.push(a);
      }
      activities.push(a);
    });

    return {
      unreadActivities: unread,
      listActivities: activities.sort(function (a: any, b: any) {
        const byDate =
          a.activeAt > b.activeAt ? -1 : a.activeAt < b.activeAt ? 1 : 0;
        const byUnread =
          a.unreadCount > b.unreadCount
            ? -1
            : a.unreadCount < b.unreadCount
            ? 1
            : 0;
        return byUnread > 0 ? byUnread : byDate;
      }),
    };
  }
);

export const getStatusClients = createSelector(mainSelector, (state) => {
  return {
    listClientsStatus: state.clientsStatus || [],
    loading: state.loading["listactivity.list"],
    error: state.error,
  };
});

export const getActivitiesGroup = createSelector(getActGroup, (actGroup) => {
  return {
    listActivities: actGroup,
  };
});

export const getActivitiesGroupByMegrID = (props: { megrID?: string }) =>
  createSelector([getActGroup], (actGroup) => {
    const activeGroups = actGroup.filter(
      (a) => a.megrID === (props?.megrID ?? "")
    );
    return {
      activeGroups,
    };
  });

export const getConversationsView = createSelector(
  getActivities,
  getUserMe,
  getActivitiesGroup,
  (listActivities, currentUser, groupActivities) => {
    let activities = [...(listActivities?.listActivities ?? [])];
    let unreadCount = 0;
    (listActivities?.unreadActivities ?? []).forEach((ua: any) => {
      unreadCount += ua.unreadCount;
    });

    groupActivities?.listActivities?.forEach?.((activity: any) => {
      const index = activities.findIndex(
        (ua) => ua.withUserID === activity.withUserID
      );
      if (index === -1) {
        activities.push(activity);
      } else {
        const current = activities[index];
        const unreadCount = activity.unreadCount + current.unreadCount;
        const newAct = moment(activity.activeAt).isAfter(
          moment(current.activeAt)
        )
          ? activity
          : current;
        activities[index] = {
          ...newAct,
          unreadCount,
        };
      }
      if (activity.unreadCount > 0) {
        unreadCount += activity.unreadCount;
      }
    });

    return {
      ...currentUser,
      listActivities: activities,
      unreadCount,
      chat: listActivities,
    };
  }
);

export const getMessageAlertView = createSelector(
  getConversationsView,
  (view) => {
    return {
      unreadCount: view?.unreadCount ?? 0,
    };
  }
);

//Actions
export function disconnectWS() {
  try {
    socket.close();
  } catch (e) {
    console.log("close err", e);
  }
  socket = null;
  return {
    type: ActionTypes.Logout,
  };
}

export function reset(type: any) {
  return {
    type: ActionTypes.Reset,
    payload: { type },
  };
}

export function clearMessages() {
  return {
    type: ActionTypes.clearMessages,
  };
}

export function clearActivities() {
  return {
    type: ActionTypes.clearActivities,
  };
}

export function clearActivitiesGroup() {
  return {
    type: ActionTypes.clearActivitiesGroup,
  };
}

export function fetchMessages(
  userID: string,
  beforeID: number,
  megrID?: string
): ThunkAction<Promise<boolean>, RootState, any, any> {
  return (dispatch: any, getState: any) => {
    if (!socket) {
      return Promise.resolve(false);
    }
    dispatch({
      type: ActionTypes.LoadStartMessage,
    } as Actions["LoadStartMessage"]);

    let action: any;
    if (megrID) {
      action = {
        type: "listMessages",
        payload: {
          userID: userID,
          megrID: megrID,
          limit: 200,
        },
      };
    } else if (beforeID > -1) {
      action = {
        type: "listMessages",
        payload: {
          userID: userID,
          limit: 200,
          beforeID: beforeID,
        },
      };
    } else {
      action = {
        type: "listMessages",
        payload: {
          userID: userID,
          limit: 200,
        },
      };
    }

    const msgSent = socket.send(action);
    return Promise.resolve(msgSent);
  };
}

export function fetchActivities(filter?: { userID?: string; megrID?: string }) {
  return (dispatch: any, getState: any) => {
    if (!socket) {
      return;
    }
    dispatch({
      type: ActionTypes.LoadStartActivity,
    } as Actions["LoadStartActivity"]);
    const action = {
      type: "listActivity",
      payload: {
        limit: 100,
        ...(filter ?? {}),
      },
    };

    setTimeout(() => {
      try {
        socket.send(action);
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  };
}

export function fetchActivitiesGroup(filter?: { megrID?: string }) {
  return (dispatch: any, getState: any) => {
    if (!socket) {
      return;
    }
    dispatch({
      type: ActionTypes.LoadStartActivity,
    } as Actions["LoadStartActivity"]);
    const action = {
      type: "listActivityGroup",
      payload: {
        limit: 100,
        ...(filter ?? {}),
      },
    };
    setTimeout(() => {
      try {
        socket.send(action);
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  };
}

export function sendMessage(message: any, media?: Array<string>) {
  return (dispatch: any, getState: any) => {
    let id =
      Math.floor(
        (Math.random() * 1000 + 1) *
          (Math.random() * 1000 + 1) *
          (Math.random() * 1000 + 1) *
          (Math.random() * 1000 + 1)
      ).toString() + moment().unix();

    message.messID = id;
    message.createdAt = moment().utc().format();
    dispatch(actions.socketSent(message));
    dispatch(
      processOutgoingQueue({
        processFilter: { messID: message.messID },
        processNow: true,
      })
    );
  };
}

export function onReadMessage(targets: Array<string>, messIDs: Array<number>) {
  return (dispatch: any, getState: any) => {
    if (!socket) {
      return;
    }
    const action = {
      type: "setMessagesRead",
      payload: {
        targets: targets,
        messages: messIDs,
      },
    };

    socket.send(action);
  };
}

export function onDeleteMessage(messID: number) {
  return (dispatch: any, getState: any) => {
    if (!socket) {
      return;
    }
    dispatch(actions.setMessageLoading(messID.toString(), true));
    dispatch(deleteQueueProcess(messID));
  };
}

export function getClientsStatus(clients: Array<string>) {
  return (dispatch: any, getState: any) => {
    if (!socket) {
      return;
    }

    const action = {
      type: "getClientsStatus",
      payload: {
        clients: clients,
      },
    };

    socket.send(action);
  };
}

export function updateUsersStatus() {
  return (dispatch: any, getState: any) => {
    if (!socket) {
      return;
    }

    const clist = getState()?.socket?.messagesWithUserID ?? {};
    const clients = Object.keys(clist);
    if (clients.length === 0) {
      return;
    }

    const action = {
      type: "getClientsStatus",
      payload: {
        clients: clients,
      },
    };

    socket.send(action);
  };
}

let processingOutgoingQueue = false;
export type ProcessOutgoingQueueOptions = {
  // process only the messages that match these values
  processFilter?: {
    messID: string;
  };
  processNow?: boolean;
};
type SendMessagePayload = {
  targets: string[];
  message: {
    type: string;
    value: string;
    data: any;
    nonceID: string;
    megrID?: string;
  };
  context: string;
};

export function processOutgoingQueue(opts?: ProcessOutgoingQueueOptions) {
  return (dispatch: any, getState: () => RootState) => {
    if (!socket || (processingOutgoingQueue && !opts?.processNow)) {
      return;
    }
    processingOutgoingQueue = true;
    const outgoing = (getState()?.socket?.outgoingMessages ?? []).filter(
      (msg) => {
        if (opts?.processFilter?.messID) {
          return opts?.processFilter?.messID === msg.messID;
        }
        return true;
      }
    );
    const msgByID = getState()?.socket?.messageByID ?? {};
    outgoing.forEach(async (out) => {
      const now = new Date().getTime();
      const delaySeconds = 8 * 1000;
      if (
        (now - out.timestamp < delaySeconds && !opts?.processNow) ||
        !!out.error
      ) {
        return;
      }
      let msg = msgByID[out.messID];
      if (!msg) {
        return;
      }
      let action = {
        type: "sendMessage",
        payload: {
          targets: [msg.fromID, msg.toID],
          message: {
            type: msg.type,
            value: msg.value,
            data: msg.data ? msg.data : {},
            nonceID: msg.messID,
          },
          context: msg.messID,
        } as SendMessagePayload,
      };
      if (msg?.megrID) {
        action.payload.message.megrID = msg.megrID;
      }

      socket.send(action);
    });
    processingOutgoingQueue = false;
  };
}

export function deleteQueueProcess(messID: number) {
  return (dispatch: any, getState: () => RootState) => {
    if (!socket) {
      return;
    }
    const action = {
      type: "deleteMessage",
      payload: {
        messID: messID,
      },
    };
    let tries = 0;
    const t = setInterval(() => {
      const sent = socket.send(action);
      if (sent) {
        clearTimeout(t);
        return;
      }
      tries++;
      if (tries === 1000) {
        dispatch(actions.setMessageLoading(messID.toString(), false));
        clearTimeout(t);
      }
    }, 200);
  };
}

const responseActionResolver: {
  [k: string]: (action: { type: string; [k: string]: any }) => any;
} = {
  "listmessage.messageRead": (action) => {
    return () => {
      return (dispatch: any, getState: () => RootState) => {
        const state = getState();
        const userID = state.auth.token.payload.userID;
        const userIDs: string[] =
          action?.payload?.readers?.filter((r: string) => r !== userID) ?? [];
        userIDs.forEach((userID) => {
          dispatch(fetchActivities({ userID }));
        });
      };
    };
  },
};

export function connectWS() {
  return (dispatch: any, getState: () => RootState) => {
    const state = getState();
    const apiToken = state.auth.token.raw;
    socket?.close?.();
    socket = new Websocket(apiToken, process.env.REACT_APP_WS_PATH as string);
    return new Promise<void>((resolve, reject) => {
      socket.connect();

      socket.onopen = (e: any) => {
        dispatch(actions.socketConnected(e));
        dispatch(fetchActivities());
        resolve();
      };
      socket.onstatus = (e: 0 | 1 | 2 | 3 | 4) => {
        dispatch(actions.socketStatusChange(WSsocket[e]));
        dispatch(processOutgoingQueue());
        dispatch(updateUsersStatus());
      };

      socket.onmessage = (e: any) => {
        let fromServer = {};
        try {
          fromServer = JSON.parse(e.data);
        } catch (e) {
          console.warn(e);
          return;
        }

        const action = {
          ...(fromServer || {}),
          // @ts-ignore
          type: "listmessage." + fromServer.type,
        };
        dispatch(actions.socketReceived(e));
        dispatch(action);
        const resolver = responseActionResolver[action.type];
        if (resolver) {
          dispatch(resolver(action)());
        }
      };

      socket.onerror = (e: any) => {
        reject(e);
      };

      socket.onclose = (e: any) => {
        dispatch(actions.socketDisconnect(e));
      };
    }).catch((e) => {
      console.log("socket error", e);
    });
  };
}

export function getArchiveDownload(
  fileID: string
): ThunkAction<Promise<string>, RootState, any, any> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    const body = {
      userID: state.auth.token.payload.userID,
      validUntil: moment(moment.now()).add(1, "minutes"),
    };
    return axios
      .post(`${process.env.REACT_APP_API_PATH}/one-time-login`, body, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        const { token } = r.data.data.item;
        return `${process.env.REACT_APP_API_PATH}/private-files/${fileID}/download?token=${token}`;
      });
  };
}
