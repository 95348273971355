import { getToken, UNAUTHORIZED } from "./auth";
import { createSelector } from "reselect";
import { hen, Hen } from "@udok/lib/internal/store";
import { RootState, AppThunk } from "ducks/state";
import { newNotification, NotificationActions } from "./notification";
import { setCookie, eraseCookie } from "@udok/lib/internal/cookie";
import { doctorLocation, locationByLocaID } from "ducks/location";
import { getPreferenceRepo } from "ducks/doctorPreferences";
import { layoutHistory } from "ducks/prescriptionLayout";

import {
  SignTypeUdok,
  TemplateModel,
  SignTypeBirdID,
  CertificateModel,
  PrescriptionModel,
  CredentialsBirdID,
  TemplateModelFilter,
  PrescriptionDocument,
  CreateCertificateModel,
  SignerProvider,
  CID10,
  MedicationAutocomplete,
  Preferences,
  Location,
  TextEntry,
  Medicine,
  SignTypeVIDaaS,
} from "@udok/lib/api/models";

import {
  createSign,
  fetchModelsByFilter,
  createModels,
  fetchCertificates,
  fetchPrescription,
  fetchPrescriptions,
  createPrescription,
  authenticateBirdID,
  fetchPrescriptionShare,
  fetchPrescriptionDocuments,
  uploadCertificate,
  fetchAllCID10,
  fetchRecentCID10,
  registerCID10Usage,
  createPrescriptionShareUrl,
  PrescriptionEmailForm,
  createPrescriptionEmail,
  FilterMedication,
  fetchPrescriptionMedication,
  deletePrescriptionModel,
  prescriptionsPhoneNotification,
  createUnsignedPrescription,
  updatePrescriptionModel,
} from "@udok/lib/api/prescription";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export const BIRDID_COOKIENAME = `token${process.env.REACT_APP_APPLICATION_BIRDID}`;
export const VIDAAS_COOKIENAME = `token${process.env.REACT_APP_APPLICATION_VIDAAS}`;

export type InitialState = {
  certificateByID: { [certID: string]: CertificateModel };
  precriptionModels: TemplateModel[];
  prescriptionsByID: {
    [presID: string]: PrescriptionModel;
  };
  prescriptionDocumentsByPresID: { [presID: string]: PrescriptionDocument[] };
  cid10ListingSearch: CID10[];
  cid10ListingRecent: CID10[];
  autoCompletDrug: MedicationAutocomplete[];
};

const initialState: InitialState = {
  certificateByID: {},
  prescriptionsByID: {},
  precriptionModels: [],
  prescriptionDocumentsByPresID: {},
  cid10ListingSearch: [],
  cid10ListingRecent: [],
  autoCompletDrug: [],
};

// Reducers
class PrescriptionSlice extends Hen<InitialState> {
  loadedPrescription(v: PrescriptionModel) {
    this.state.prescriptionsByID[v.presID] = v;
  }

  loadedPrescriptions(v: Array<PrescriptionModel>) {
    v.forEach((p: PrescriptionModel) => {
      this.state.prescriptionsByID[p.presID] = p;
    });
  }

  loadedPrescriptionDocuments(
    presID: string,
    v: Array<PrescriptionDocument>,
    append?: boolean
  ) {
    let val = v;
    if (append) {
      const current = (
        this.state.prescriptionDocumentsByPresID[presID] ?? []
      ).filter(
        (pres1) => v.findIndex((pres2) => pres2.prdoID === pres1.prdoID) === -1
      );
      val = [...current, ...val];
    }
    this.state.prescriptionDocumentsByPresID[presID] = val;
  }

  loadCertificate(c: CertificateModel) {
    this.state.certificateByID[c.certID] = c;
  }

  loadCertificates(c: CertificateModel[]) {
    c.forEach((cert) => {
      this.state.certificateByID[cert.certID] = cert;
    });
  }

  loadedPrescriptionModel(m: TemplateModel) {
    this.state.precriptionModels = [...this.state.precriptionModels, m];
  }

  loadedPrescriptionsModel(m: Array<TemplateModel>) {
    this.state.precriptionModels = m;
  }

  updatePrescriptionsModel(m: TemplateModel) {
    let list = this.state.precriptionModels;
    const index = list.findIndex((t) => t.prmoID === m.prmoID);
    if (index === -1) {
      list = [...list, m];
    } else {
      list[index] = m;
    }
    this.state.precriptionModels = list;
  }

  removePrescriptionsModel(m: TemplateModel) {
    const currentList = this.state.precriptionModels;
    this.state.precriptionModels = currentList.filter(
      (pm) => pm.prmoID !== m.prmoID
    );
  }

  loadCID10Search(cids: CID10[]) {
    this.state.cid10ListingSearch = cids;
  }
  loadCID10Recent(cids: CID10[]) {
    this.state.cid10ListingRecent = cids;
  }
  clearSearchCID10() {
    this.state.cid10ListingSearch = [];
  }
  loadAutoCompletDrug(ad: MedicationAutocomplete[]) {
    this.state.autoCompletDrug = ad;
  }
}

export const [Reducer, actions] = hen(new PrescriptionSlice(initialState), {
  [UNAUTHORIZED]: () => {
    eraseCookie(BIRDID_COOKIENAME);
    eraseCookie(VIDAAS_COOKIENAME);
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.prescription;
export const autoCompletDrugRepository = (state: RootState) =>
  state.prescription.autoCompletDrug;
export const prescriptionDocumentRepository = (state: RootState) =>
  state.prescription.prescriptionDocumentsByPresID;
const precriptionModelsRepository = (state: RootState) =>
  state.prescription.precriptionModels;
const cid10ListingSearchRepository = (state: RootState) =>
  state.prescription.cid10ListingSearch;
const cid10ListingRecentRepository = (state: RootState) =>
  state.prescription.cid10ListingRecent;
const prescriptionsRepository = (state: RootState) =>
  state.prescription.prescriptionsByID;
const certificateRepository = (state: RootState) =>
  state.prescription.certificateByID;
export const prescriptionListView = createSelector(
  [mainSelector, getToken],
  (state, auth) => {
    return {
      list: Object.keys(state.prescriptionsByID)
        .map((presID) => state.prescriptionsByID[presID])
        .filter((p) => p.doctID === auth?.token?.payload?.doctID)
        .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
    };
  }
);

export const getPrescriptionInfoByID = (
  state: RootState,
  props: { presID: string }
) =>
  createSelector([mainSelector, getToken], (state, auth) => {
    return {
      prescription: state.prescriptionsByID[props.presID],
      prescriptionDocuments:
        state.prescriptionDocumentsByPresID[props.presID] ?? [],
      models: state.precriptionModels ?? [],
      readonly:
        state.prescriptionsByID[props.presID]?.doctID !==
        auth?.token?.payload?.doctID,
    };
  });

export const getCreateMedicalDocument = (props: {
  presID?: string;
  locaID?: string;
}) =>
  createSelector(
    [
      getPreferenceRepo,
      doctorLocation,
      locationByLocaID,
      layoutHistory,
      prescriptionsRepository,
    ],
    (pref, doctorLocation, locationByID, layoutHistory, prescriptionsByID) => {
      const locationPref = pref.preferenceByID[Preferences.presDefaultLocation];
      const locationOptions = (
        [
          locationByID[props?.locaID ?? ""],
          locationByID[locationPref?.options?.locaID ?? ""],
          locationByID[doctorLocation[0] ?? ""],
        ] as (Location | undefined)[]
      ).filter((l) => l && !l.deletedAt);

      let loc = locationOptions[0];
      if (!loc) {
        const firstValidLocation: Location | undefined = Object.keys(
          locationByID
        )
          .map((id) => locationByID[id])
          .filter((l) => l && !l.deletedAt)[0];
        loc = firstValidLocation;
      }

      const defaulID = loc?.locaID;
      const history = [...layoutHistory].sort((a, b) =>
        moment(b.usedAt).diff(moment(a.usedAt))
      );
      const plteID = loc?.prescriptionLayouts?.[0] || history[0]?.plteID;
      return {
        prescription: props.presID
          ? prescriptionsByID[props.presID]
          : undefined,
        defaulID,
        defaultPlteID: plteID,
      };
    }
  );

export const getPrescriptionCertificate = createSelector(
  [certificateRepository],
  (certificateByID) => {
    return {
      certificate:
        Object.keys(certificateByID)
          .map((id) => certificateByID[id])
          .sort((a, b) => (a.createdAt! > b.createdAt! ? -1 : 1))[0] ??
        undefined,
    };
  }
);

export const getPrescriptionModels = createSelector(
  [precriptionModelsRepository],
  (precriptionModels) => {
    return {
      models: precriptionModels,
    };
  }
);

export const getListCertificateView = createSelector(mainSelector, (state) => {
  return {
    list: Object.keys(state.certificateByID)
      .map((id) => state.certificateByID[id])
      .sort((a, b) => {
        const date1 = moment(b.invalidAt).utc();
        const date2 = moment(a.invalidAt).utc();
        return date1.diff(date2, "minutes");
      }),
  };
});

export const getCID10SearchView = createSelector(
  [cid10ListingSearchRepository, cid10ListingRecentRepository],
  (search, recent) => {
    return {
      listSearch: search ?? [],
      listRecent: recent ?? [],
    };
  }
);

export const getAutocompletDrug = createSelector(
  autoCompletDrugRepository,
  (autoCompletDrug) => {
    return {
      list: autoCompletDrug,
    };
  }
);

export const getPrescription = (state: RootState, props: { presID: string }) =>
  createSelector([mainSelector], (state) => {
    return {
      prescription: state.prescriptionsByID[props.presID],
    };
  });

export const getPrescriptionDocuments = (props: { presID: string }) =>
  createSelector([mainSelector], (state) => {
    return {
      documents: state.prescriptionDocumentsByPresID[props.presID] ?? [],
    };
  });

// Actions
export function duplicateNotification(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    dispatch(
      newNotification("general", {
        status: "success",
        message: "Documento duplicado com sucesso!",
      })
    );
  };
}

export function addPrescription(
  spec: PrescriptionModel
): AppThunk<Promise<PrescriptionModel>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createPrescription(apiToken, spec)
      .then((r) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
        dispatch(loadPrescription(r.presID));
        return Promise.resolve(r as PrescriptionModel);
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        return Promise.reject(e);
      });
  };
}

export function loadPrescriptions(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPrescriptions(apiToken)
      .then((r) => {
        dispatch(actions.loadedPrescriptions(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadPrescription(presID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPrescription(apiToken, presID)
      .then(async (r) => {
        dispatch(actions.loadedPrescription(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadPrescriptionDocuments(
  presID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPrescriptionDocuments(apiToken, presID)
      .then((r) => {
        dispatch(actions.loadedPrescriptionDocuments(presID, r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadCachedPrescriptionDocuments(
  presID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const documentExist = Boolean(
      state.prescription.prescriptionDocumentsByPresID[presID]
    );
    if (documentExist) {
      return Promise.resolve();
    }
    return dispatch(loadPrescriptionDocuments(presID));
  };
}

export function loadPrescriptionShareLink(
  prdoID: string
): AppThunk<Promise<void | string>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPrescriptionShare(apiToken, prdoID)
      .then((r) => {
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function createCertificate(
  fileInfo: CreateCertificateModel
): AppThunk<Promise<void>> {
  const data = new FormData();
  data.append("password", fileInfo.password as any);
  data.append("file", fileInfo.file);
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return uploadCertificate(apiToken, data)
      .then((c) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Certificado enviado com sucesso!",
          })
        );
        dispatch(actions.loadCertificate(c));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadAllCertificates(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCertificates(apiToken)
      .then((r) => {
        dispatch(actions.loadCertificates(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function createTemplate(
  prescription: TemplateModel
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createModels(apiToken, prescription)
      .then((r) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Modelo criado com sucesso!",
          })
        );
        dispatch(actions.loadedPrescriptionModel(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function updateTemplate(
  prmoID: string,
  data: { name: string; entries: Array<TextEntry> | Array<Medicine> }
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updatePrescriptionModel(apiToken, prmoID, data)
      .then((r) => {
        dispatch(actions.updatePrescriptionsModel(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Modelo atualizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadTemplatesByFilter(
  filter: TemplateModelFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchModelsByFilter(apiToken, filter)
      .then((r) => {
        dispatch(actions.loadedPrescriptionsModel(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function searchCID10(searchTerm: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAllCID10(apiToken, searchTerm)
      .then((r) => {
        dispatch(actions.loadCID10Search(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadRecentCID10(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchRecentCID10(apiToken)
      .then((r) => {
        dispatch(actions.loadCID10Recent(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function addCID10Usage(ciliID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return registerCID10Usage(apiToken, ciliID)
      .then(() => {})
      .catch(() => {});
  };
}

export function clearCID10(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(actions.clearSearchCID10());
  };
}

// Signer Actions
export function authenticatorBirdID(
  props: CredentialsBirdID,
  date?: Date
): AppThunk<Promise<CredentialsBirdID>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return authenticateBirdID(apiToken, props)
      .then((r) => {
        if (r?.accessToken && date) {
          setCookie(BIRDID_COOKIENAME, r.accessToken, date);
        }
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
        return Promise.resolve(r as CredentialsBirdID);
      })
      .catch((e) => {
        let message = e?.message;
        if (e?.message?.indexOf?.("authenticator birdid") !== -1) {
          message = "Erro na autenticação";
          if (e?.message?.indexOf?.("INVALID_CREDENTIALS") !== -1) {
            message =
              "Dados inválidos! Verifique se todos os campos foram preenchidos corretamente.";
          }
        }
        dispatch(
          newNotification("SignPrescription", {
            status: "error",
            message,
          })
        );
        return Promise.reject(e);
      });
  };
}

export function signPrescription(
  props: SignTypeBirdID | SignTypeUdok | SignTypeVIDaaS
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    if (
      !(props as SignTypeUdok)?.certID &&
      props.provider === SignerProvider.Udok
    ) {
      const err = "Certificado não identificado. Tente novamente.";
      dispatch(
        newNotification("general", {
          status: "error",
          message: err,
        })
      );
      return Promise.reject(err);
    }
    return createSign(apiToken, props)
      .then((r) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
        dispatch(loadPrescription(props.presID));
        dispatch(loadPrescriptionDocuments(props.presID));
      })
      .catch((e) => {
        let message =
          "Falha ao assinar o documento! Verifique seus dados e tente novamente.";
        let actions: NotificationActions[] = [];
        if (e?.message?.indexOf?.("password incorrect") !== -1) {
          message = "Dados incorretos, favor tentar novamente.";
        }
        if (e?.message?.indexOf?.("Invalid certificate") !== -1) {
          message = "Seu certificado expirou! ";
          actions = [
            {
              url: `${process.env.REACT_APP_BIRDID_SUPPORT}`,
              name: "Verifique com o BirdID como renová-lo.",
              type: "link",
              target: "_blank",
              rel: "noopener noreferrer",
            },
          ];
        }
        if (
          e?.message?.indexOf?.("text rune array is larger than supported") !==
          -1
        ) {
          message = "O conteúdo do documento é grande demais.";
        }
        if (e?.message?.indexOf?.("Certificate is not valid") !== -1) {
          message =
            "Certificado inválido! Verifique o seu certificado digital e tente novamente.";
        }
        if (
          e?.message?.indexOf?.(
            "status: 107, message: Error during signature operation"
          ) !== -1
        ) {
          message =
            "Muitas solicitações para o VIDaaS, tente novamente em 30s.";
        }
        dispatch(loadPrescription(props.presID));
        dispatch(loadPrescriptionDocuments(props.presID));
        dispatch(
          newNotification("SignPrescription", {
            status: "error",
            message,
            actions,
          })
        );
        throw e;
      });
  };
}

export function createShareUrlDocument(
  prdoID: string
): AppThunk<Promise<{ shareUrl: string; patientUrl: string }>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createPrescriptionShareUrl(apiToken, prdoID).catch((e) => {
      dispatch(
        newNotification("general", {
          status: "error",
          message: e.message,
        })
      );
      return Promise.reject(e);
    });
  };
}

export function sendPrescriptionEmail(
  p: PrescriptionEmailForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createPrescriptionEmail(apiToken, p)
      .then((r) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Email enviado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        return Promise.reject(e);
      });
  };
}

export function loadPrescriptionMedications(
  filter?: FilterMedication
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPrescriptionMedication(apiToken, filter)
      .then((r) => {
        dispatch(actions.loadAutoCompletDrug(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function deleteOnePrescriptionModel(
  prmoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deletePrescriptionModel(apiToken, prmoID)
      .then((r) => {
        dispatch(actions.removePrescriptionsModel(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Modelo excluído com sucesso.",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function sendPrescriptionPhonenotification(
  presID: string,
  recipientPhone: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return prescriptionsPhoneNotification(apiToken, presID, recipientPhone)
      .then((r) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Mensagem enviada com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: "Não foi possível enviar a mensagem",
          })
        );
        return Promise.reject(e);
      });
  };
}

export function createNewUnsignedPrescription(data: {
  presID: string;
}): AppThunk<Promise<PrescriptionDocument[]>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createUnsignedPrescription(apiToken, data)
      .then((r) => {
        dispatch(actions.loadedPrescriptionDocuments(data.presID, r, true));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
