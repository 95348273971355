import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import {
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import Payment from "payment";
import Selector from "@udok/lib/components/Selector";
import CreditcardImage from "@udok/lib/components/CreditCard/Image";
import { UfList } from "@udok/lib/internal/constants";
import { onlyNumbers, shrinker } from "@udok/lib/internal/util";
import { CreditCardForm } from "@udok/lib/api/models";
import CreditCardCVVPopover from "@udok/lib/components/Help/CreditCardCVVPopover";
import SwitchField from "@udok/lib/components/Input/SwitchField";
import SearchCep, { SearchCepReturn } from "containers/Location/SearchCep";
// @ts-ignore
import createDOMForm from "rc-form/lib/createDOMForm";
// @ts-ignore
import { formShape } from "rc-form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginTop: theme.spacing(1),
    },
    smallFieldContainer: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        "& > *:nth-child(n+2)": {
          marginLeft: theme.spacing(2),
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        "& > *": {
          width: "100%",
        },
      },
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row-reverse",
      margin: theme.spacing(0, 0, 3),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2, 0, 1),
      },
    },
    switchContainer: {
      margin: theme.spacing(1, 0, 0.5),
    },
  })
);

export type CardInitialValues = Partial<CreditCardForm> & {
  isDefault?: boolean;
};

export type CreditCardFormProps = {
  onSubmit: (
    err: any,
    values: CreditCardForm & {
      isDefault?: boolean;
    },
    form: formShape
  ) => void;
  initialValues?: CardInitialValues;
  form?: formShape;
  loading?: boolean;
};

const CreditCardFormView = (props: CreditCardFormProps) => {
  const { initialValues, loading, form, onSubmit } = props;
  const { getFieldProps, getFieldError, getFieldValue, setFieldsValue } = form;
  const theme = useTheme();
  const classes = useStyles();
  const cardType = React.useMemo(
    () => Payment.fns.cardType(getFieldValue("number")),
    [getFieldValue]
  );

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      form.validateFieldsAndScroll(
        { scroll: { offsetTop: theme.spacing(10) } },
        (err: any, values: any) => {
          const val: CreditCardForm = {
            ...values,
            brand: Payment.fns.cardType(values.number),
            billingAddress: {
              zipcode: values.billing_zipcode,
              street: values.billing_street,
              neighborhood: values.billing_neighborhood,
              streetNumber: values.billing_streetNumber,
              city: values.billing_city,
              state: values.billing_state,
              name: values.billing_name,
              country: "br",
            },
          };
          onSubmit(err, val, form);
        }
      );
    },
    [theme, form, onSubmit]
  );

  const validateNumber = React.useCallback(
    (val: any, src: any, cb: (r?: string) => void) => {
      if (src && isNaN(parseInt(src?.toString()))) {
        cb("Apenas números são aceitos");
      }
      if (!Payment.fns.validateCardNumber(src)) {
        cb("Número inválido");
      }
      cb();
    },
    []
  );

  const validateDate = React.useCallback(
    (val: any, src: any, cb: (r?: string) => void) => {
      if (!Payment.fns.validateCardExpiry(src)) {
        cb("Data inválida");
      }
      cb();
    },
    []
  );

  const validateCVC = React.useCallback(
    (val: any, src: any, cb: (r?: string) => void) => {
      if (!Payment.fns.validateCardCVC(src, cardType)) {
        cb("Código inválido");
      }
      cb();
    },
    [cardType]
  );

  const validateCep = React.useCallback(
    (val: any, src: any, cb: (r?: string) => void) => {
      if (!src) {
        cb("Campo obrigatório");
      }
      if (src && isNaN(parseInt(src?.toString()))) {
        cb("Apenas números são aceitos");
      }
      if (src.length !== 8) {
        cb("O CEP deve conter 8 dígitos");
      }
      cb();
    },
    []
  );

  const normalizeExpiry = React.useCallback(
    (t: string) =>
      onlyNumbers(t ?? "")
        ?.split?.("")
        ?.reduce?.(
          (a, b) => [
            ...a,
            ...(a.length <= 5 ? [b] : []),
            ...(a.length === 1 ? ["/"] : []),
          ],
          [] as string[]
        )
        ?.join?.(""),
    []
  );

  const normalizezipcode = React.useCallback((value?: SearchCepReturn) => {
    if (typeof value !== "string") {
      return value?.cep ?? "";
    }
    return value;
  }, []);

  const toUpper = React.useCallback(
    (s?: string) => s?.toLocaleUpperCase?.() ?? s?.toUpperCase?.(),
    []
  );

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <TextField
        required
        fullWidth
        label="Número do cartão"
        type="string"
        size="small"
        margin="dense"
        variant="outlined"
        helperText={getFieldError("number")}
        error={Boolean(getFieldError("number"))}
        inputProps={{ inputmode: "numeric", maxlength: 19 }}
        InputProps={{
          required: false,
          endAdornment: (
            <CreditcardImage
              creditcard={Payment.fns.cardType(getFieldValue("number")) as any}
              height={50}
              width={80}
            />
          ),
        }}
        {...getFieldProps("number", {
          rules: [validateNumber],
          normalize: Payment.fns.formatCardNumber,
          initialValue: initialValues?.number ?? "",
        })}
      />
      <div className={classes.smallFieldContainer}>
        <TextField
          required
          label="Validade"
          type="string"
          size="small"
          margin="dense"
          variant="outlined"
          InputProps={{ required: false }}
          error={Boolean(getFieldError("expiry"))}
          helperText={getFieldError("expiry")}
          inputProps={{ inputmode: "numeric", maxlength: 5 }}
          {...getFieldProps("expiry", {
            rules: [validateDate],
            initialValue: initialValues?.expiry ?? "",
            normalize: normalizeExpiry,
          })}
        />
        <TextField
          required
          label="Código de segurança"
          type="string"
          size="small"
          margin="dense"
          variant="outlined"
          error={Boolean(getFieldError("cvv"))}
          helperText={getFieldError("cvv")}
          onInput={(e: any) => {
            e.target.value = e.target.value.match?.(/^\d*/)?.[0] ?? "";
          }}
          inputProps={{ inputmode: "numeric", maxlength: 4 }}
          InputProps={{
            required: false,
            endAdornment: (
              <InputAdornment position="end">
                <CreditCardCVVPopover />
              </InputAdornment>
            ),
          }}
          {...getFieldProps("cvv", {
            rules: [validateCVC],
            initialValue: initialValues?.cvv ?? "",
          })}
        />
      </div>
      <TextField
        required
        fullWidth
        label="Nome no cartão"
        type="string"
        size="small"
        margin="dense"
        variant="outlined"
        error={Boolean(getFieldError("name"))}
        helperText={getFieldError("name")}
        InputProps={{ required: false }}
        inputProps={{ maxlength: 40 }}
        {...getFieldProps("name", {
          rules: [
            {
              type: "string",
              required: true,
              message: "Campo obrigatório",
            },
          ],
          normalize: toUpper,
          initialValue: initialValues?.name ?? "",
        })}
      />
      <Typography variant="subtitle1">Endereço de cobrança</Typography>
      <TextField
        required
        fullWidth
        label="Nome"
        type="string"
        size="small"
        margin="dense"
        variant="outlined"
        error={Boolean(getFieldError("billing_name"))}
        helperText={getFieldError("billing_name")}
        InputProps={{ required: false }}
        inputProps={{ maxlength: 40 }}
        InputLabelProps={shrinker(getFieldValue("billing_name"))}
        {...getFieldProps("billing_name", {
          rules: [
            { type: "string", required: true, message: "Campo obrigatório" },
          ],
        })}
      />
      <SearchCep
        required
        title="CEP"
        size="small"
        margin="dense"
        variant="outlined"
        style={{ marginBottom: 10 }}
        error={Boolean(getFieldError("billing_zipcode"))}
        errorMessage={getFieldError("billing_zipcode") as string}
        InputProps={{ required: false }}
        {...getFieldProps("billing_zipcode", {
          rules: [{ type: "string" }, { validator: validateCep }],
          initialValue: initialValues?.billingAddress?.zipcode,
          normalize: normalizezipcode,
          onChange: (value: SearchCepReturn) => {
            setFieldsValue({
              billing_street: value.street,
              billing_neighborhood: value.district,
              billing_city: value.city,
              billing_state: value.stateAcronym,
            });
          },
        })}
      />
      <TextField
        required
        fullWidth
        label="Bairro"
        type="string"
        size="small"
        margin="dense"
        variant="outlined"
        InputProps={{ required: false }}
        error={Boolean(getFieldError("billing_neighborhood"))}
        helperText={getFieldError("billing_neighborhood")}
        InputLabelProps={shrinker(getFieldValue("billing_neighborhood"))}
        {...getFieldProps("billing_neighborhood", {
          rules: [
            { type: "string", required: true, message: "Campo obrigatório" },
          ],
          initialValue: initialValues?.billingAddress?.neighborhood,
        })}
      />
      <TextField
        required
        fullWidth
        label="Rua"
        type="string"
        size="small"
        margin="dense"
        variant="outlined"
        InputProps={{ required: false }}
        error={Boolean(getFieldError("billing_street"))}
        helperText={getFieldError("billing_street")}
        InputLabelProps={shrinker(getFieldValue("billing_street"))}
        {...getFieldProps("billing_street", {
          rules: [
            { type: "string", required: true, message: "Campo obrigatório" },
          ],
          initialValue: initialValues?.billingAddress?.street,
        })}
      />
      <div className={classes.smallFieldContainer}>
        <TextField
          required
          label="Número"
          type="string"
          size="small"
          margin="dense"
          variant="outlined"
          InputProps={{ required: false }}
          error={Boolean(getFieldError("billing_streetNumber"))}
          helperText={getFieldError("billing_streetNumber")}
          InputLabelProps={shrinker(getFieldValue("billing_streetNumber"))}
          {...getFieldProps("billing_streetNumber", {
            rules: [
              { type: "string", required: true, message: "Campo obrigatório" },
            ],
            initialValue: initialValues?.billingAddress?.streetNumber,
          })}
        />
        <Selector
          required
          label="Estado"
          list={UfList}
          size="small"
          margin="dense"
          variant="outlined"
          style={{ minWidth: 150 }}
          InputProps={{ required: false }}
          error={Boolean(getFieldError("billing_state"))}
          errorMessage={getFieldError("billing_state") as string}
          InputLabelProps={shrinker(getFieldValue("billing_state"))}
          {...getFieldProps("billing_state", {
            rules: [
              { type: "string", required: true, message: "Campo obrigatorio" },
            ],
            initialValue: initialValues?.billingAddress?.state,
          })}
        />
        <TextField
          required
          label="Cidade"
          type="string"
          size="small"
          margin="dense"
          variant="outlined"
          InputProps={{ required: false }}
          error={Boolean(getFieldError("billing_city"))}
          helperText={getFieldError("billing_city")}
          InputLabelProps={shrinker(getFieldValue("billing_city"))}
          {...getFieldProps("billing_city", {
            rules: [
              { type: "string", required: true, message: "Campo obrigatorio" },
            ],
            initialValue: initialValues?.billingAddress?.city,
          })}
        />
      </div>
      <div className={classes.switchContainer}>
        <SwitchField
          name="isDefault"
          color="primary"
          label="Definir esse cartão como favorito para uso?"
          {...getFieldProps("isDefault", {
            initialValue: initialValues?.isDefault ?? false,
          })}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
        >
          <span>Salvar</span>
          {loading && <CircularProgress size={24} />}
        </Button>
      </div>
    </form>
  );
};

export default createDOMForm()(CreditCardFormView) as typeof CreditCardFormView;
