import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { MobileStepper, Button, DialogContent } from "@material-ui/core";
import {
  StepItem,
  StepStatus,
  StepPalette,
  StepperProps,
} from "@udok/lib/components/Stepper/Types";
import Icons from "@udok/lib/components/Icon";
import DialogTitle from "@udok/lib/components/Dialog/Title";
import clsx from "clsx";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    completedStepTitle: {
      backgroundColor: StepPalette.completedLight,
    },
    activeStepTitle: {
      backgroundColor: StepPalette.activeLight,
    },
    defaultStepTitle: {
      backgroundColor: StepPalette.defaultLight,
    },
    dialogTitle: {
      alignItems: "center",
      flexWrap: "nowrap",
    },
    oneLineText: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      height: "fit-content",
    },
    hidden: { visibility: "hidden" },
  })
);

type DialogMobileStepperProps = StepperProps & {
  showTitle?: boolean;
  onClose?: () => void;
  classes?: {
    title?: string;
    content?: string;
    stepper?: string;
  };
};

const DialogMobileStepper = (props: DialogMobileStepperProps) => {
  const {
    value = 0,
    children,
    steps = [],
    showTitle = true,
    classes: classesProps,
    onClose,
    handleClick,
  } = props;
  const currentStep: StepItem | undefined = steps[value];
  const classes = useStyles();

  const handleNext = () => {
    handleClick?.(value + 1);
  };
  const handleBack = () => {
    if (value > 0) {
      handleClick?.(value - 1);
    }
  };

  return (
    <>
      {showTitle ? (
        <DialogTitle
          id="test_mobile_stepper"
          onClose={onClose}
          className={clsx(classes.dialogTitle, classesProps?.title, {
            [classes.completedStepTitle]:
              currentStep?.state === StepStatus.completed,
            [classes.activeStepTitle]: currentStep?.state === StepStatus.active,
            [classes.defaultStepTitle]:
              currentStep?.state === StepStatus.default,
          })}
          titleTypographyProps={{ className: classes.oneLineText }}
          rightStyle={{ width: "auto" }}
        >
          {currentStep?.alertTitle ?? currentStep?.title ?? ""}
        </DialogTitle>
      ) : null}
      <DialogContent className={classesProps?.content}>
        {children}
      </DialogContent>
      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={value}
        className={classesProps?.stepper}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            className={clsx({
              [classes.hidden]:
                value === steps.length - 1 || steps[value + 1]?.disabled,
            })}
            disabled={value >= steps.length - 1 || steps[value + 1]?.disabled}
          >
            Proximo
            <Icons.KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={handleBack}
            className={clsx({ [classes.hidden]: value === 0 })}
            disabled={value <= 0}
          >
            <Icons.KeyboardArrowLeft />
            Anterior
          </Button>
        }
      />
    </>
  );
};

export default DialogMobileStepper;
