import React from "react";
import { useListHealthplans } from "hooks/schedule";
import { SelectProps, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { DefaultPlan } from "@udok/lib/internal/constants";
import ResourceAlertDialog from "containers/LimitedResourceAlert/Dialog";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import { PlanFeaturesPermissions } from "@udok/lib/api/models";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocompleteTag: {
      [theme.breakpoints.down("sm")]: {
        maxWidth: 100,
      },
    },
    autocompleteInputRoot: {
      [theme.breakpoints.down("sm")]: {
        paddingRight: 45,
      },
    },
  })
);

export interface SelectHealthplansProps {
  onChange?: (v: string | undefined | null | string[]) => void;
  error?: boolean;
  multiple?: boolean;
  value?: string | string[];
  style?: React.CSSProperties;
  fullWidth?: SelectProps["fullWidth"];
  placeholder?: string;
  label?: React.ReactNode;
  id?: string;
  disabled?: boolean;
  errorMessage?: string;
  filter?: string[];
  variant?: "standard" | "outlined" | "filled";
  margin?: "normal" | "dense" | "none";
  size?: "small" | "medium";
  required?: boolean;
  onlyMine?: boolean;
  autoSelect?: boolean;
  className?: string;
  validatePermission?: boolean;
  limitTags?: number;
  loadData?: boolean;
}
const [rendererResourceAlert, promiseResourceAlert] =
  createModal(ResourceAlertDialog);
const SelectHealthplans = React.forwardRef(
  (props: SelectHealthplansProps, ref: React.Ref<any>) => {
    const {
      onChange,
      style,
      error,
      multiple,
      value,
      label,
      fullWidth,
      placeholder,
      id,
      disabled,
      errorMessage,
      filter,
      variant = "standard",
      margin = "normal",
      size,
      required,
      onlyMine,
      autoSelect,
      validatePermission,
      limitTags = 3,
      loadData = true,
      ...others
    } = props;
    const [loading, list, doctorPlans] = useListHealthplans(loadData);
    const [activeResourceAlert, setActive] = React.useState(true);
    const classes = useStyles();

    let selectList = React.useMemo(
      () =>
        (onlyMine ? doctorPlans : list).map((i) => {
          return {
            label: i.name,
            value: i.heplID,
          };
        }),
      [onlyMine, doctorPlans, list]
    );

    React.useEffect(() => {
      if (autoSelect && !value && selectList.length > 0) {
        let defaultPLanIndex = selectList.findIndex(
          (l) => l.value === DefaultPlan
        );
        if (defaultPLanIndex === -1) {
          defaultPLanIndex = 0;
        }
        const selected = selectList[defaultPLanIndex].value;
        onChange?.(selected);
      }
    }, [value, autoSelect, onChange, selectList]);

    if (filter && filter.length > 0) {
      selectList = selectList.filter((hp) => filter.indexOf(hp.value) !== -1);
    }
    const singleVal = String(value ?? "");
    const val = multiple
      ? (Array.isArray(value) ? (value as string[]) : [value]).map((o) => ({
          value: o,
          label: selectList.find((e) => e.value === o)?.label,
        }))
      : {
          value: singleVal,
          label: selectList.find((e) => e.value === singleVal)?.label,
        };

    const handleChange = React.useCallback(
      async (e: any, value: any) => {
        if (
          validatePermission &&
          activeResourceAlert &&
          value !== DefaultPlan
        ) {
          await promiseResourceAlert({
            feature: PlanFeaturesPermissions.HealthPlans,
          }).then(() => setActive(false));
        }
        if (multiple && Array.isArray(value)) {
          onChange?.(value?.map?.((e) => e.value ?? ""));
        } else {
          onChange?.((value as { value: string })?.value);
        }
      },
      [multiple, validatePermission, activeResourceAlert, onChange]
    );

    return (
      <>
        <Autocomplete
          ref={ref}
          multiple={multiple}
          style={style}
          fullWidth={fullWidth}
          id={id}
          limitTags={limitTags}
          options={selectList}
          getOptionLabel={(option) => option?.label ?? ""}
          value={val}
          size={size}
          disabled={disabled}
          {...others}
          onChange={handleChange}
          classes={{
            tag: classes.autocompleteTag,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required={required}
              variant={variant as any}
              margin={margin}
              label={label}
              placeholder={placeholder}
              error={error}
              helperText={errorMessage}
              InputProps={{ ...params.InputProps, required: false }}
            />
          )}
          clearText="Limpar"
          closeText="Fechar"
          noOptionsText="Nenhum item encontrado"
          getOptionSelected={(option, value) => {
            return option?.value === value?.value;
          }}
          loading={loading}
        />
        {rendererResourceAlert}
      </>
    );
  }
);

export default SelectHealthplans;
