import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  Step,
  Stepper,
  StepButton,
  StepConnector,
  Typography,
  StepContent,
} from "@material-ui/core";
import Icons from "@udok/lib/components/Icon";
import {
  Orientation,
  StepItem,
  StepStatus,
  StepPalette,
  StepperProps,
} from "@udok/lib/components/Stepper/Types";

const ColorlibConnector = withStyles({
  line: {
    display: "none",
  },
})(StepConnector);

const MainConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 20,
    left: "calc(-50% + 25px)",
    right: "calc(50% + 25px)",
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 6,
    borderRadius: 6,
  },
}))(StepConnector);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      flex: 1,
      width: "100%",
      padding: "5px 0",
      display: "flex",
      justifyContent: "space-evenly",
      background: "none",
    },
    step: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    stepButton: {
      minWidth: 180,
      maxWidth: "100%",
      width: "auto",
      padding: "20px 0",
    },
    stepContent: {
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(-3),
        padding: theme.spacing(3),
      },
    },
  })
);

const CustomStepper = (props: StepperProps) => {
  const {
    steps = [],
    value = 0,
    orientation,
    children,
    paperProps,
    showCardInfo = true,
    showConnector = false,
    handleClick,
  } = props;
  const classes = useStyles();

  const returnPalette = (index: number) => {
    const step: StepItem | undefined = steps[index];
    switch (step?.state) {
      case StepStatus.completed:
        return (
          step?.completedStyle ?? {
            backgroundColor: StepPalette.completedLight,
            textColor: StepPalette.completedMain,
            borderColor: StepPalette.completedMain,
          }
        );
      case StepStatus.active:
        return (
          step?.activeStyle ?? {
            backgroundColor: StepPalette.activeLight,
            textColor: StepPalette.activeMain,
            borderColor: StepPalette.activeMain,
          }
        );
      default:
        return (
          step?.defaultStyle ?? {
            backgroundColor: StepPalette.defaultLight,
            textColor: StepPalette.defaultMain,
            borderColor: StepPalette.defaultMain,
          }
        );
    }
  };

  const StepIcon = (index: number) => {
    const step: StepItem | undefined = steps[index];
    return (
      <Icons.StepIcon
        width={60}
        height={60}
        text={step?.state !== StepStatus.completed ? String(index + 1) : ""}
        {...returnPalette(index)}
      />
    );
  };

  const currentStep: StepItem | undefined = steps[value];
  const currentStyle = returnPalette(value);
  const activeStep = steps.findIndex((s) => s.state === StepStatus.active);
  return (
    <>
      {showCardInfo ? (
        <CardStepInfo
          icon={currentStep?.icon}
          title={currentStep?.alertTitle ?? currentStep?.title ?? ""}
          subtitle={currentStep?.subtitle}
          currentOption={value ?? 0}
          totalOptions={steps.length}
          arrowVisible={!(orientation === Orientation.vertical)}
          styleProps={{
            backgroundColor: currentStyle.backgroundColor,
            borderColor: currentStyle.borderColor,
          }}
        />
      ) : null}
      <Stepper
        nonLinear
        className={
          orientation !== Orientation.vertical ? classes.stepper : undefined
        }
        alternativeLabel={orientation !== Orientation.vertical}
        orientation={orientation}
        activeStep={activeStep}
        connector={!showConnector ? <ColorlibConnector /> : <MainConnector />}
        {...paperProps}
      >
        {steps.map((step, index) => {
          return (
            <Step
              className={
                orientation !== Orientation.vertical ? classes.step : undefined
              }
              key={index}
              completed={step.state === StepStatus.completed}
            >
              <StepButton
                onClick={() => handleClick?.(index)}
                icon={StepIcon(index)}
                className={classes.stepButton}
                disabled={step?.disabled}
              >
                <Typography style={{ marginTop: 0 }}>{step?.title}</Typography>
              </StepButton>
              {children ? (
                <StepContent className={classes.stepContent}>
                  {children}
                </StepContent>
              ) : null}
            </Step>
          );
        })}
      </Stepper>
    </>
  );
};

type CardStepInfoPorps = {
  title: string;
  subtitle?: string;
  icon?: string;
  totalOptions: number;
  currentOption: number;
  arrowVisible?: boolean;
  styleProps?: {
    backgroundColor?: string;
    borderColor?: string;
  };
};

type CardStyle = {
  positionBefore?: string | number;
  positionAfter?: string | number;
  arrowVisible?: boolean;
  backgroundColor?: string;
  borderColor?: string;
};

const cardStyle = ({
  positionBefore,
  positionAfter,
  arrowVisible = true,
  backgroundColor,
  borderColor,
}: CardStyle) =>
  makeStyles((theme: Theme) =>
    createStyles({
      container: {
        position: "relative",
        marginBottom: 20,
        marginTop: 15,
        minHeight: "4.06rem",
        display: "flex",
        alignItems: "flex-end",
      },
      card: {
        width: "100%",
        position: "relative",
        display: "flex",
        padding: 10,
        backgroundColor: backgroundColor ?? StepPalette.defaultLight,
        borderColor: borderColor ?? StepPalette.defaultMain,
        boxShadow:
          "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        MozBorderRadius: "10px",
        WebkitBorderRadius: "10px",
        borderRadius: 10,
        border: "1px solid",
        "&::before, &::after": arrowVisible
          ? {
              content: "'\\0020'",
              display: "block",
              position: "absolute",
              bottom: -9,
              left: positionAfter ?? 14,
              zIndex: 2,
              width: 0,
              height: 0,
              overflow: "hidden",
              border: "solid 13px transparent",
              borderTop: 0,
              borderBottomColor: backgroundColor ?? StepPalette.defaultLight,

              transformOrigin: "center",
              transform: "rotate(-45deg)",
            }
          : undefined,
        "&::before": arrowVisible
          ? {
              bottom: -12,
              left: positionBefore ?? 16,
              zIndex: 1,
              borderBottomColor: borderColor ?? StepPalette.defaultMain,
              borderWidth: 14,
            }
          : undefined,
      },
      iconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
      },
      textContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 0,
      },
      text: {
        color: borderColor ?? StepPalette.defaultMain,
      },
      icon: {
        fill: borderColor ?? StepPalette.defaultMain,
        fontSize: 40,
      },
    })
  );

const CardStepInfo = (props: CardStepInfoPorps) => {
  const {
    title,
    subtitle,
    icon,
    totalOptions,
    currentOption,
    arrowVisible,
    styleProps,
  } = props;
  const size = 100 / (totalOptions || 1);
  const half = size / 2;
  const positionAfter = `calc((${size * currentOption}% + ${half}%) - 8.85px)`;
  const positionBefore = `calc((${
    size * currentOption
  }% + ${half}%) - 10.85px)`;
  const classes = cardStyle({
    positionAfter,
    positionBefore,
    arrowVisible,
    ...styleProps,
  })();
  const MappedIcons = {
    ...(Icons as any),
  };
  const Icon = icon ? MappedIcons[icon] : undefined;

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        {Icon ? (
          <div className={classes.iconContainer}>
            <Icon className={classes.icon} width={40} height={40} fontsi />
          </div>
        ) : null}
        <div className={classes.textContainer}>
          <Typography className={classes.text} variant="subtitle2">
            {title}
          </Typography>
          {subtitle ? (
            <Typography className={classes.text} variant="body1">
              {subtitle}
            </Typography>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CustomStepper;
