import React from "react";
import { Prompt } from "react-router";

const OnBeforeUnload = ({
  disabled,
  message,
}: {
  disabled?: boolean;
  message: string;
}) => {
  const [popstateInUse, setPopstateInUse] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("changedPopupState", (e: any) => {
      const onpopstate = e?.detail ?? {};
      setPopstateInUse(Object.keys(onpopstate).length > 0);
    });
  }, []);

  React.useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!disabled && !popstateInUse) {
        e.returnValue = message; // Standard for most browsers
        return message; // For Safari
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [disabled, popstateInUse, message]);

  return <Prompt when={!disabled && !popstateInUse} message={message} />;
};

export default OnBeforeUnload;
