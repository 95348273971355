import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { shrinker } from "@udok/lib/internal/util";
import { BillingInformationForm } from "@udok/lib/api/models";
import SearchCep, { SearchCepReturn } from "containers/Location/SearchCep";
// @ts-ignore
import { formShape } from "rc-form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallFieldContainer: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        "& > *:nth-child(n+2)": {
          marginLeft: theme.spacing(2),
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        "& > *": {
          width: "100%",
        },
      },
    },
  })
);

export type FormAddressProps = {
  initialValues?: Partial<BillingInformationForm["billingAddress"]>;
  form: formShape;
};

const FormAddress = (props: FormAddressProps) => {
  const { initialValues, form } = props;
  const { getFieldProps, getFieldError, getFieldValue, setFieldsValue } = form;
  const classes = useStyles();

  const validateString = React.useCallback(
    (a: any, value: string, cb: (e?: string) => void) => {
      if (!value || !value.trim()) {
        cb("Campo obrigatório");
        return;
      }
      cb();
    },
    []
  );

  const validateCep = React.useCallback(
    (val: any, src: any, cb: (r?: string) => void) => {
      if (!src) {
        cb("Campo obrigatório");
      }
      if (src && isNaN(parseInt(src?.toString()))) {
        cb("Apenas números são aceitos");
      }
      if (src.length !== 8) {
        cb("O CEP deve conter 8 dígitos");
      }
      cb();
    },
    []
  );

  const normalizezipcode = React.useCallback((value?: SearchCepReturn) => {
    if (typeof value !== "string") {
      return value?.cep ?? "";
    }
    return value;
  }, []);

  return (
    <>
      <TextField
        required
        fullWidth
        label="Nome"
        type="string"
        size="small"
        margin="dense"
        variant="outlined"
        error={Boolean(getFieldError("addressName"))}
        helperText={getFieldError("addressName")}
        InputProps={{ required: false }}
        inputProps={{ maxlength: 40 }}
        InputLabelProps={shrinker(getFieldValue("addressName"))}
        {...getFieldProps("addressName", {
          rules: [validateString],
          initialValue: initialValues?.name,
        })}
      />
      <SearchCep
        required
        title="CEP"
        size="small"
        margin="dense"
        variant="outlined"
        style={{ marginBottom: 10 }}
        error={Boolean(getFieldError("addressZipcode"))}
        errorMessage={getFieldError("addressZipcode") as string}
        InputProps={{ required: false }}
        {...getFieldProps("addressZipcode", {
          rules: [{ type: "string" }, { validator: validateCep }],
          initialValue: initialValues?.zipcode,
          normalize: normalizezipcode,
          onChange: (value: SearchCepReturn) => {
            setFieldsValue({
              addressStreet: value.street,
              addressNeighborhood: value.district,
            });
          },
        })}
      />
      <TextField
        required
        fullWidth
        label="Bairro"
        type="string"
        size="small"
        margin="dense"
        variant="outlined"
        InputProps={{ required: false }}
        error={Boolean(getFieldError("addressNeighborhood"))}
        helperText={getFieldError("addressNeighborhood")}
        InputLabelProps={shrinker(getFieldValue("addressNeighborhood"))}
        {...getFieldProps("addressNeighborhood", {
          rules: [
            { type: "string", required: true, message: "Campo obrigatório" },
          ],
          initialValue: initialValues?.neighborhood,
        })}
      />
      <div className={classes.smallFieldContainer}>
        <TextField
          required
          fullWidth
          label="Rua"
          type="string"
          size="small"
          margin="dense"
          variant="outlined"
          InputProps={{ required: false }}
          error={Boolean(getFieldError("addressStreet"))}
          helperText={getFieldError("addressStreet")}
          InputLabelProps={shrinker(getFieldValue("addressStreet"))}
          {...getFieldProps("addressStreet", {
            rules: [
              { type: "string", required: true, message: "Campo obrigatório" },
            ],
            initialValue: initialValues?.street,
          })}
        />
        <TextField
          required
          label="Número"
          type="string"
          size="small"
          margin="dense"
          variant="outlined"
          InputProps={{ required: false }}
          error={Boolean(getFieldError("addressStreetNumber"))}
          helperText={getFieldError("addressStreetNumber")}
          InputLabelProps={shrinker(getFieldValue("addressStreetNumber"))}
          {...getFieldProps("addressStreetNumber", {
            rules: [
              { type: "string", required: true, message: "Campo obrigatório" },
            ],
            initialValue: initialValues?.street_number,
          })}
        />
      </div>
    </>
  );
};

export default FormAddress;
