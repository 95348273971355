import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { Document } from "@udok/lib/api/models";
import {
  fetchDocumentTemplates,
  fetchDocumentTemplate,
  createDocumentTemplate,
  updateDocumentTemplate,
  deleteDocumentTemplate,
} from "@udok/lib/api/document";
import { getToken, UNAUTHORIZED } from "./auth";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  templateByID: { [doteID: string]: Document };
};

// Reducers
const initialState: InitialState = {
  templateByID: {},
};

class Documents extends Hen<InitialState> {
  templatesLoaded(v: Document[]) {
    v.forEach((s) => {
      this.state.templateByID[String(s.doteID)] = s;
    });
  }

  templateLoaded(v: Document) {
    this.state.templateByID[String(v.doteID)] = v;
  }

  templateRemoved(v: Document) {
    delete this.state.templateByID[String(v.doteID)];
  }
}

export const [Reducer, actions] = hen(new Documents(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.documents;

export const templateListView = createSelector(mainSelector, (state) => {
  return {
    list: Object.keys(state.templateByID)
      .map((doctID) => state.templateByID[doctID])
      .sort(function (a, b) {
        return moment(b.createdAt).diff(moment(a.createdAt));
      }),
  };
});

export const templateView = (state: RootState, props: { doteID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      template: state.templateByID[props.doteID],
    };
  });

export const documentsByAppoIDView = (
  state: RootState,
  props: { doteIDList?: string[] }
) =>
  createSelector(mainSelector, (state) => {
    const { templateByID } = state;
    const { doteIDList } = props;

    return {
      templates: (doteIDList ?? []).map((id) => templateByID[id] ?? {}),
    };
  });

// Actions
export function loadTemplates(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchDocumentTemplates(apiToken)
      .then((r) => {
        dispatch(actions.templatesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneDocumentTemplate(
  doteID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchDocumentTemplate(apiToken, doteID)
      .then((r) => {
        dispatch(actions.templateLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createTemplate(document: Document): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createDocumentTemplate(apiToken, document)
      .then((r) => {
        dispatch(actions.templateLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateTemplate(document: Document): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateDocumentTemplate(apiToken, document)
      .then((r) => {
        dispatch(actions.templateLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeTemplate(doteID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteDocumentTemplate(apiToken, doteID)
      .then((r) => {
        dispatch(actions.templateRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
