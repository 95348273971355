import { connect } from "react-redux";
import Delete from "@udok/lib/components/CRUD/Delete";
import { removeOneCreditCard } from "ducks/billing";

export default connect(
  () => ({}),
  (dispatch: any) => ({
    doDelete: (crcaID: string) => dispatch(removeOneCreditCard(crcaID)),
  })
)(Delete);
