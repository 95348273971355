import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  Location,
  GeoLocation,
  Preferences,
  GeoCity,
  FilterGeoCity,
} from "@udok/lib/api/models";
import {
  createLocation,
  fetchLocations,
  fetchLocation,
  deleteLocation,
  updateLocation,
  geoLocationsCity,
} from "@udok/lib/api/location";
import { fetchGeoLocationSearch } from "@udok/lib/api/search";
import { getPreferenceRepo } from "ducks/doctorPreferences";
import { plansSelector } from "ducks/schedule";
import { layoutBindingByID } from "ducks/prescriptionLayout";
import { getToken, UNAUTHORIZED } from "./auth";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  locationByID: { [locaID: string]: Location };
  doctorLocations: string[];
  cityByID: { [geciID: number]: GeoCity | undefined };
  listedCities: number[];
};

// Reducers
const initialState: InitialState = {
  locationByID: {},
  doctorLocations: [],
  cityByID: {},
  listedCities: [],
};

class Locations extends Hen<InitialState> {
  locaLoaded(loca: Location) {
    this.state.locationByID[loca.locaID] = loca;
    const doctorLocs = this.state.doctorLocations;
    if (loca?.doctID && doctorLocs.indexOf(loca.locaID) === -1) {
      this.state.doctorLocations = [...doctorLocs, loca.locaID];
    }
  }
  locationsLoaded(loca: Location[]) {
    let doctorLocs = this.state.doctorLocations;
    loca.forEach((l) => {
      this.state.locationByID[l.locaID] = l;
      if (l?.doctID && doctorLocs.indexOf(l.locaID) === -1) {
        doctorLocs = [...doctorLocs, l.locaID];
      }
    });
    this.state.doctorLocations = doctorLocs;
  }
  listCities(cities: GeoCity[]) {
    const list = cities.map((city) => {
      this.state.cityByID[city.geciID] = city;
      return city.geciID;
    });
    this.state.listedCities = list;
  }
}

export const [Reducer, actions] = hen(new Locations(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.location;
export const locationByLocaID = (state: RootState) =>
  state.location.locationByID;
export const doctorLocation = (state: RootState) =>
  state.location.doctorLocations;
const clinicSelector = (state: RootState) => state.schedule.clinByID;
const citySelector = (state: RootState) => state.location.cityByID;
const listedCitiesSelector = (state: RootState) => state.location.listedCities;

export const getListlocation = createSelector(
  [getPreferenceRepo, locationByLocaID, doctorLocation, clinicSelector],
  (pref, locationByID, doctorLocations, clinicByID) => {
    const locationPref = pref.preferenceByID[Preferences.presDefaultLocation];
    return {
      list: doctorLocations
        .map((locaID) => locationByID[locaID])
        .filter((l) => !!l && !l.deletedAt)
        .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
      listAllLocation: Object.keys(locationByID)
        .map((locaID) => locationByID[locaID])
        .filter((l) => !!l && !l.deletedAt)
        .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
      defaulID: locationPref?.options?.locaID as string,
      clinicByID,
    };
  }
);

export const getDoctorLocations = createSelector(
  [locationByLocaID, doctorLocation],
  (locationByID, doctorLocations) => {
    return {
      list: doctorLocations
        .map((locaID) => locationByID[locaID])
        .filter((l) => !!l && !l.deletedAt)
        .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
    };
  }
);

export const getOneLocation = (state: RootState, props: { locaID: string }) =>
  createSelector(
    [getPreferenceRepo, layoutBindingByID, mainSelector],
    (pref, layoutBinding, state) => {
      const location = state.locationByID[props.locaID] ?? {};
      const locationPref = pref.preferenceByID[Preferences.presDefaultLocation];
      const isDefault = location?.locaID === locationPref?.options?.locaID;
      const plteID = layoutBinding?.[props.locaID]?.attachResources?.[0];

      return {
        location,
        isDefault,
        plteID,
      };
    }
  );

export const SchedulePlansAlertView = (
  state: RootState,
  props: { locaID: string }
) =>
  createSelector([mainSelector, plansSelector], (state, plans) => {
    const location = state.locationByID[props.locaID] ?? {};
    const listPlans = Object.keys(plans).map((id) => plans[id]);
    return {
      location,
      listPlans,
    };
  });

export const searchGeoLocationCityListView = createSelector(
  [citySelector, listedCitiesSelector],
  (cityByID, listedCities) => {
    const allCities = Object.keys(cityByID)
      .map((id) => cityByID[parseInt(id)])
      .filter((b) => !!b) as GeoCity[];

    const filteredCities = listedCities
      .map((id) => cityByID[id])
      .filter((b) => !!b) as GeoCity[];
    return {
      allCities,
      filteredCities,
    };
  }
);

// Actions
export function createOneLocation(
  location: Location
): AppThunk<Promise<Location>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createLocation(apiToken, location)
      .then((r) => {
        dispatch(actions.locaLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Localização criada com sucesso",
          }) as any
        );
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadLocations(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchLocations(apiToken)
      .then((r) => {
        dispatch(actions.locationsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneLocation(locaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchLocation(apiToken, locaID)
      .then((r) => {
        dispatch(actions.locaLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedLocation(locaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const locationExist = Boolean(state.location.locationByID[locaID]);
    if (locationExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneLocation(locaID));
  };
}

export function removeLocation(locaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return deleteLocation(apiToken, locaID)
      .then((r) => {
        dispatch(actions.locaLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function changeLocation(location: Location): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateLocation(apiToken, location)
      .then((r) => {
        dispatch(actions.locaLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function searchLocationByCep(
  cep: string
): AppThunk<Promise<GeoLocation>> {
  return async (dispatch) => {
    return fetchGeoLocationSearch(cep)
      .then((r) => r[0])
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function searchGeoLocationCity(
  filter?: FilterGeoCity
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return geoLocationsCity(filter)
      .then((r) => {
        if (r) {
          dispatch(actions.listCities(r));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
