import {
  Location,
  Schedule,
  PrescriptionLayoutView,
  ScheduleLock,
  PlanFeaturesPermissions,
  ScheduleType,
} from "@udok/lib/api/models";

export type AccessPermission = {
  allowed: boolean;
  exceededLimit: boolean;
};
export function featureAccessPermission(
  features: PlanFeaturesPermissions[],
  LocList: Location[],
  schList: Schedule[],
  pltList: PrescriptionLayoutView[],
  locksList: ScheduleLock[],
  listActivities: any[],
  permissions: string[]
) {
  const featurePermissions: {
    [k: string]: AccessPermission;
  } = {};
  features.forEach((f) => {
    let allowed =
      permissions.filter(
        (perm) =>
          [PlanFeaturesPermissions.PermissionsAll, f].indexOf(perm as any) !==
          -1
      ).length > 0;
    let exceededLimit = true;
    switch (f) {
      case PlanFeaturesPermissions.UnlimitedAddresses:
        exceededLimit = LocList.length > 1;
        break;
      case PlanFeaturesPermissions.UnlimitedSchedule:
        const limitedSch = schList.filter(
          (sch) => sch.type !== ScheduleType.place
        );
        exceededLimit = limitedSch.length > 0;
        break;
      case PlanFeaturesPermissions.ScheduleLock:
        exceededLimit = locksList.length > 0;
        break;
      case PlanFeaturesPermissions.PrescriptionCustomization:
        exceededLimit = pltList.length > 0;
        break;
      case PlanFeaturesPermissions.UnlimitedChat:
        exceededLimit = listActivities.length > 5;
        break;
    }
    featurePermissions[f] = {
      allowed,
      exceededLimit,
    };
  });

  return featurePermissions;
}
