import {
  UserProfile,
  Location,
  Schedule,
  ScheduleType,
} from "@udok/lib/api/models";
import { StepItem, StepStatus } from "@udok/lib/components/Stepper";

export enum OnboardingStepKey {
  profile = "profile",
  address = "address",
  schedule = "schedule",
  successAlert = "successAlert",
}

export function calculateOnboardingSteps(
  locations: Location[],
  schedules: Schedule[],
  currentUser?: UserProfile
) {
  const doctor = currentUser?.doctor;
  const curriculum = doctor?.info?.curriculum;
  const addProfile =
    (doctor?.avatar ?? "").trim().length === 0 ||
    (doctor?.info?.about ?? "").trim().length === 0 ||
    (doctor?.info?.signature ?? "").trim().length === 0 ||
    (curriculum?.academicFormation ?? []).length === 0 ||
    (curriculum?.experiences ?? []).length === 0 ||
    (curriculum?.professionalExperience ?? []).length === 0 ||
    (curriculum?.languages ?? []).length === 0;
  const addLocation = locations.length === 0;
  const addSchedule =
    schedules.length === 0 ||
    (schedules.length === 1 && schedules?.[0]?.type === ScheduleType.virtual);

  const listSteps = [
    ...(addProfile ? [OnboardingStepKey.profile] : []),
    ...(addLocation ? [OnboardingStepKey.address] : []),
    ...(addSchedule ? [OnboardingStepKey.schedule] : []),
    ...(addProfile || addLocation || addSchedule
      ? [OnboardingStepKey.successAlert]
      : []),
  ];

  let steps: StepItem[] = [];
  const stepMap = {
    [OnboardingStepKey.profile]: {
      title: "Atualizar perfil",
      state: StepStatus.default,
      disabled: true,
    },
    [OnboardingStepKey.address]: {
      title: "Adicionar endereço",
      state: StepStatus.default,
      disabled: true,
    },
    [OnboardingStepKey.schedule]: {
      title: "Gerenciar horários",
      state: StepStatus.default,
      disabled: true,
    },
    [OnboardingStepKey.successAlert]: {
      title: "Tudo pronto!",
      state: StepStatus.default,
      disabled: true,
    },
  };
  listSteps.forEach((s) => {
    steps = [...steps, stepMap[s]];
  });

  if (
    steps.length > 0 &&
    steps.findIndex((s) => s.state === StepStatus.active) === -1
  ) {
    let activeIndex = steps.findIndex((s) => s.state !== StepStatus.completed);
    if (activeIndex === -1) {
      activeIndex = 0;
    }
    steps[activeIndex].state = StepStatus.active;
    steps[activeIndex].disabled = false;
  }

  return {
    listSteps,
    steps,
  };
}
