import React from "react";
import {
  MenuItem,
  LinearProgress,
  Button,
  TextField,
  TextFieldProps,
} from "@material-ui/core";

export type SelectorProps = {
  onChange?: (value?: any) => void;
  list?: Array<{ label: string; value: React.ReactText }>;
  errorMessage?: string;
  value?: Array<React.ReactText> | React.ReactText;
  placeholder?: string | React.ReactNode;
  clearable?: boolean;
  loading?: boolean;
  multiple?: boolean;
} & Omit<
  TextFieldProps,
  | "onChange"
  | "value"
  | "helperText"
  | "InputLabelProps"
  | "select"
  | "children"
  | "placeholder"
>;

type CustomPickerProps<C extends React.ElementType> = {
  clearComponent?: C;
  clearComponentProps?: Omit<React.ComponentProps<C>, "options">;
} & SelectorProps;

const CustomPicker = React.forwardRef(
  <C extends React.ElementType>(
    {
      multiple,
      list,
      errorMessage,
      value,
      label,
      placeholder,
      clearable,
      SelectProps,
      loading = false,
      onChange,
      clearComponent,
      clearComponentProps,
      variant,
      ...others
    }: CustomPickerProps<C>,
    ref: React.Ref<any>
  ) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 300,
        },
      },
    };
    const existValue =
      !!value &&
      (Array.isArray(value) || typeof value === "string"
        ? value.length > 0
        : true);

    const shrinker = (v: any): object => {
      return (
        (Boolean(v) &&
          (Array.isArray(v) ? v.length > 0 : true) && { shrink: Boolean(v) }) ||
        {}
      );
    };

    const placeView = (opValue: typeof value) => {
      if (Array.isArray(opValue)) {
        const valueList: Array<string> = [];
        opValue.forEach((v) => {
          const vLabel = (list ?? []).filter((o) => o.value === v);
          valueList.push(vLabel.length > 0 ? vLabel[0].label : "");
        });
        return valueList.join(", ");
      }
      const opLabel = (list ?? []).filter((o) => o.value === opValue);
      return opLabel.length > 0 ? opLabel[0].label : "";
    };

    const renderPlace = () => {
      if (label && !existValue) {
        return undefined;
      }
      if (!existValue) {
        return (
          <option
            style={{
              fontFamily: "Roboto",
              color: "rgba(0, 0, 0, 0.54)",
            }}
          >
            {placeholder}
          </option>
        );
      }
      return placeView(value);
    };
    const Btn = clearComponent ?? Button;
    return (
      <>
        <TextField
          ref={ref}
          label={label}
          select
          value={value ?? (multiple ? [] : "")}
          onChange={(event) => {
            onChange?.(event.target.value);
          }}
          variant={variant ?? ("outlined" as any)}
          InputLabelProps={{
            ...shrinker(value),
          }}
          SelectProps={{
            displayEmpty: true,
            multiple,
            ...SelectProps,
            MenuProps,
            renderValue: renderPlace,
          }}
          helperText={loading ? <LinearProgress /> : errorMessage}
          {...others}
        >
          {placeholder ? (
            <MenuItem
              value="placeholder"
              disabled={typeof placeholder === "string"}
            >
              {placeholder}
            </MenuItem>
          ) : null}
          {list?.map?.((item) => {
            return (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </TextField>
        {existValue && clearable && (
          <Btn {...clearComponentProps} onClick={() => onChange?.(undefined)}>
            {clearComponentProps?.children ?? "Limpar"}
          </Btn>
        )}
      </>
    );
  }
);

export default CustomPicker;
