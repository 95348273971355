import React from "react";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from "@material-ui/core";
import Icons from "@udok/lib/components/Icon";

export type CheckboxFieldPorps = Omit<CheckboxProps, "checked" | "onChange"> & {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  value?: boolean;
  label?: React.ReactNode;
  formControlLabelProps?: Omit<FormControlLabelProps, "control" | "label">;
};

const CheckboxField = React.forwardRef(
  (props: CheckboxFieldPorps, ref: React.Ref<any>) => {
    return (
      <FormControlLabel
        ref={ref}
        control={
          <Checkbox
            checkedIcon={<Icons.CheckBoxOutlined />}
            checked={props.value}
            onChange={props?.onChange}
            {...props}
          />
        }
        label={props.label}
        {...props?.formControlLabelProps}
      />
    );
  }
);

export default CheckboxField;
