import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "ducks/state";
import {
  loadOwnPatients,
  loadHealthplanCards,
  getPatientHealthplanCards,
  patientListSearch,
  loadPatientHistory,
  getPatientHistory,
  loadOnePatient,
  getPatientProfile,
  loadCachedPatientContactInformation,
  loadCachedPatientCRMInformation,
  getOneRecordFlag,
  listRecordFlag,
  loadRecordFlags,
  loadCachedRecordFlag,
  getPatientRecordFlags,
  loadPatientRecordFlags,
  loadCachedPatient,
  getOnePatient,
  loadPrescriptionPatients,
  prescriptionPatientList,
} from "ducks/patient";
import {
  FilterPatientHealthplanCard,
  FilterPatientHistory,
} from "@udok/lib/api/models";
import { PatientFilter } from "@udok/lib/api/patient";
import { debounce } from "@udok/lib/internal/util";

export const useSearchPatient = (patiID?: string) => {
  const [loaders, setLoaders] = React.useState(
    new Map<Promise<any>, boolean>()
  );
  const dispatch: AppDispatch = useDispatch();

  const search = React.useCallback(
    (value: string, f?: Omit<PatientFilter, "keyword">) => {
      const load = dispatch(
        loadOwnPatients({ keyword: [value], ...f })
      ).finally(() => {
        setLoaders((l) => {
          l.delete(load);
          return new Map(l);
        });
      });
      setLoaders((l) => new Map(l.set(load, true)));
    },
    [dispatch]
  );
  const getPatientList = React.useCallback(patientListSearch({ patiID }), [
    patiID,
  ]);

  const { list } = useSelector(getPatientList);
  const loading = Array.from(loaders.values()).reduce((a, b) => a || b, false);

  return [loading, search, list] as [
    typeof loading,
    typeof search,
    typeof list
  ];
};

export const useGetPatientHealthplanCards = (
  patiID?: string,
  filter?: FilterPatientHealthplanCard
) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (patiID) {
      setLoading(true);
      dispatch(loadHealthplanCards(patiID)).finally(() => setLoading(false));
    }
  }, [patiID, filter, dispatch]);

  const getPlanCard = React.useCallback(
    getPatientHealthplanCards({ patiID: patiID ?? "" }),
    [patiID]
  );
  const { healthplanCards } = useSelector(getPlanCard);
  let filteredList = healthplanCards;
  if (filter?.heplID) {
    filteredList = healthplanCards.filter((hc) => hc.heplID === filter?.heplID);
  }

  return [loading, filteredList] as [typeof loading, typeof filteredList];
};

export const useGetPatientHistory = (patiID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  const searchHistory = React.useCallback(
    (filter?: FilterPatientHistory) => {
      setLoading(true);
      dispatch(loadPatientHistory(patiID, filter)).finally(() => {
        setLoading(false);
      });
    },
    [patiID, dispatch]
  );

  const getHistory = React.useCallback(getPatientHistory({ patiID }), [patiID]);
  const { patientHistory } = useSelector(getHistory);

  return [loading, searchHistory, patientHistory] as [
    typeof loading,
    typeof searchHistory,
    typeof patientHistory
  ];
};

export const useGetPatientProfile = (patiID?: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (patiID) {
      setLoading(true);
      Promise.all([
        dispatch(loadOnePatient(patiID)),
        dispatch(loadCachedPatientContactInformation(patiID)),
        dispatch(loadCachedPatientCRMInformation(patiID)),
      ]).finally(() => setLoading(false));
    }
  }, [dispatch, patiID]);

  const getProfile = React.useCallback(getPatientProfile({ patiID }), [patiID]);
  const {
    contactInformation,
    lastPlan,
    patient,
    personalInformation,
    crmInformation,
  } = useSelector(getProfile);

  return [
    loading,
    contactInformation,
    lastPlan,
    patient,
    personalInformation,
    crmInformation,
  ] as [
    typeof loading,
    typeof contactInformation,
    typeof lastPlan,
    typeof patient,
    typeof personalInformation,
    typeof crmInformation
  ];
};

export const useListRecordFlag = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(loadRecordFlags()).finally(() => setLoading(false));
  }, [dispatch]);

  const { recordFlags } = useSelector(listRecordFlag);

  return [loading, recordFlags] as [typeof loading, typeof recordFlags];
};

export const useGetOneRecordFlag = (reflID?: number) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (reflID) {
      setLoading(true);
      dispatch(loadCachedRecordFlag(reflID)).finally(() => setLoading(false));
    }
  }, [reflID, dispatch]);

  const getRecordFlag = React.useCallback(getOneRecordFlag({ reflID }), [
    reflID,
  ]);
  const { recordFlag } = useSelector(getRecordFlag);

  return [loading, recordFlag] as [typeof loading, typeof recordFlag];
};

export const useGetPatintRecordFlag = (patiID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(loadPatientRecordFlags(patiID)).finally(() => setLoading(false));
  }, [patiID, dispatch]);

  const getPatientFlags = React.useCallback(getPatientRecordFlags({ patiID }), [
    patiID,
  ]);
  const { patientRecordFlags } = useSelector(getPatientFlags);
  const list = (patientRecordFlags ?? []).filter(
    (item, i, list) =>
      list.findIndex((flag) => flag.reflID === item.reflID) === i
  );
  return [loading, list] as [typeof loading, typeof list];
};

export const useGetOnePatient = (patiID?: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (patiID) {
      setLoading(true);
      dispatch(loadCachedPatient(patiID)).finally(() => setLoading(false));
    }
  }, [patiID, dispatch]);

  const getPatient = React.useCallback(getOnePatient({ patiID }), [patiID]);
  const { patient } = useSelector(getPatient);
  return [loading, patient] as [typeof loading, typeof patient];
};

export const useSearchPrescriptionPatients = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(loadOwnPatients({ limit: 100 })),
      dispatch(loadPrescriptionPatients({ cutOffDate: true })),
    ]).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);

  const search = React.useCallback(
    debounce(
      (s: string) => {
        setLoading(true);
        Promise.all([
          dispatch(
            loadOwnPatients({ keyword: [s], limit: 100 } as PatientFilter)
          ),
          dispatch(loadPrescriptionPatients({ name: s, cutOffDate: true })),
        ]).finally(() => setLoading(false));
      },
      500,
      false
    ),
    [dispatch]
  );
  const { list } = useSelector(prescriptionPatientList);

  return [loading, list, search] as [
    typeof loading,
    typeof list,
    typeof search
  ];
};
