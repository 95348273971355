import React from "react";
import Navigator, { NavigatorProps } from "components/UI/Navigator";
import { connect } from "react-redux";
import { getSidebarMenu } from "ducks/menu";

export type MenuProps = NavigatorProps & ReturnType<typeof getSidebarMenu>;
const Menu = (p: MenuProps) => {
  return (
    <Navigator
      {...p}
      categories={p.menu}
      navigationPrefix={``}
      headerTitle="Udok Pro"
    />
  );
};

export default connect(getSidebarMenu, () => ({}))(Menu);
