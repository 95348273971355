import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  MedicalHistoryTemplate,
  MedicalHistoryTemplateForm,
  MedicalHistoryDocumentView,
  MedicalHistoryDocumentForm,
  FilterMedicalHistoryTemplate,
  FilterMedicalHistoryDocument,
  FilterMedicalHistoryTimeline,
} from "@udok/lib/api/models";
import {
  medicalHistoryTemplates,
  createMedicalHistoryTemplate,
  updateMedicalHistoryTemplate,
  createMedicalHistoryDocument,
  getMedicalHistoryDocument,
  getMedicalHistoryTemplate,
  deleteMedicalHistoryTemplate,
  getMedicalHistoryDocumentTimeline,
} from "@udok/lib/api/medicalHistory";
import { getToken, UNAUTHORIZED } from "./auth";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  medicalHistoryTemplatesByID: {
    [doteID: string]: MedicalHistoryTemplate | undefined;
  };
  myMedicalHistoryTemplates: string[];
  clinicMedicalHistoryTemplate: { [clinID: string]: string[] | undefined };
  medicalHistoryDocument: {
    [patiID: string]: MedicalHistoryDocumentView[] | undefined;
  };
  medicalHistoryDocumentTimeline: {
    [patiID: string]: {
      [doteID: string]: MedicalHistoryDocumentView[] | undefined;
    };
  };
};

// Reducers
const initialState: InitialState = {
  medicalHistoryTemplatesByID: {},
  myMedicalHistoryTemplates: [],
  clinicMedicalHistoryTemplate: {},
  medicalHistoryDocument: {},
  medicalHistoryDocumentTimeline: {},
};

class MedicalHistory extends Hen<InitialState> {
  medicalHistoryTemplateLoaded(temp: MedicalHistoryTemplate) {
    this.state.medicalHistoryTemplatesByID[temp?.doteID ?? ""] = temp;
    if (temp.clinID) {
      const current =
        this.state.clinicMedicalHistoryTemplate[temp.clinID] ?? [];
      this.state.clinicMedicalHistoryTemplate[temp.clinID] = [
        ...current,
        temp?.doteID ?? "",
      ].filter((v, i, arr) => arr.indexOf(v) === i);
    } else {
      const current = this.state.myMedicalHistoryTemplates;
      this.state.myMedicalHistoryTemplates = [
        ...current,
        temp?.doteID ?? "",
      ].filter((v, i, arr) => arr.indexOf(v) === i);
    }
  }
  medicalHistoryTemplatesLoaded(temps: MedicalHistoryTemplate[]) {
    temps.forEach((temp) => {
      this.state.medicalHistoryTemplatesByID[temp?.doteID ?? ""] = temp;
      if (temp.clinID) {
        const current =
          this.state.clinicMedicalHistoryTemplate[temp.clinID] ?? [];
        this.state.clinicMedicalHistoryTemplate[temp.clinID] = [
          ...current,
          temp?.doteID ?? "",
        ].filter((v, i, arr) => arr.indexOf(v) === i);
      } else {
        const current = this.state.myMedicalHistoryTemplates;
        this.state.myMedicalHistoryTemplates = [
          ...current,
          temp?.doteID ?? "",
        ].filter((v, i, arr) => arr.indexOf(v) === i);
      }
    });
  }
  medicalHistoryDocumentsLoaded(docs: MedicalHistoryDocumentView[]) {
    docs.forEach((doc) => {
      const current = [
        ...(this.state.medicalHistoryDocument?.[doc.patiID] ?? []),
      ];
      this.state.medicalHistoryDocument[doc.patiID] = [...current, doc];
    });
  }
  medicalHistoryDocumentLoaded(doc: MedicalHistoryDocumentView) {
    const current = [
      ...(this.state.medicalHistoryDocument?.[doc.patiID] ?? []),
    ];
    this.state.medicalHistoryDocument[doc.patiID] = [...current, doc];
  }
  scheduleLockRemoved(temp: MedicalHistoryTemplate) {
    delete this.state.medicalHistoryTemplatesByID[temp?.doteID ?? ""];
  }
  medicalHistoryDocumentTimeline(docs: MedicalHistoryDocumentView[]) {
    docs.forEach((doc) => {
      const patiID = doc.patiID;
      const doteID = doc.document.doteID;
      const current =
        this.state.medicalHistoryDocumentTimeline?.[patiID]?.[doteID] ?? [];
      const newList = [...current, doc];

      this.state.medicalHistoryDocumentTimeline[patiID] = {
        ...this.state.medicalHistoryDocumentTimeline?.[patiID],
        [doteID]: newList.filter(
          (d, i) =>
            i ===
            newList.findIndex((n) => n.document.docuID === d.document.docuID)
        ),
      };
    });
  }
}

export const [Reducer, actions] = hen(new MedicalHistory(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.medicalHistory;
const medicalHistoryDocumentTimelineSelector = (state: RootState) =>
  state.medicalHistory.medicalHistoryDocumentTimeline;

export const getClinicMedicalHistoryTemplates = (props: { clinID: string }) =>
  createSelector(
    mainSelector,
    ({ clinicMedicalHistoryTemplate, medicalHistoryTemplatesByID }) => {
      return {
        list: (clinicMedicalHistoryTemplate?.[props.clinID] ?? [])
          .map((doteID) => medicalHistoryTemplatesByID[doteID])
          .filter((t) => !!t) as MedicalHistoryTemplate[],
      };
    }
  );

export const getMyMedicalHistoryTemplates = createSelector(
  mainSelector,
  ({ medicalHistoryTemplatesByID, myMedicalHistoryTemplates }) => {
    return {
      list: myMedicalHistoryTemplates
        .map((doteID) => medicalHistoryTemplatesByID[doteID])
        .filter((t) => !!t) as MedicalHistoryTemplate[],
    };
  }
);

export const getAllMedicalHistoryTemplate = createSelector(
  mainSelector,
  (state) => {
    let listAll: MedicalHistoryTemplate[] = [];

    state.myMedicalHistoryTemplates.forEach((doteID) => {
      const temp = state.medicalHistoryTemplatesByID[doteID];
      if (temp) {
        listAll = [...listAll, temp];
      }
    });

    Object.keys(state.clinicMedicalHistoryTemplate).forEach((clinID) => {
      (state.clinicMedicalHistoryTemplate?.[clinID] ?? []).forEach((doteID) => {
        const temp = state.medicalHistoryTemplatesByID[doteID];
        if (temp) {
          listAll = [...listAll, temp];
        }
      });
    });

    return { listAll };
  }
);

export const getMedicalHistoryTemplateByID = (props: { doteID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      template: state.medicalHistoryTemplatesByID?.[props.doteID],
    };
  });

export const getPatientMedicalHistoryDocument = (props: { patiID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      medicalHistoryDocument: state.medicalHistoryDocument?.[props.patiID],
    };
  });

export const getMedicalHistoryTimeline = (props: {
  patiID: string;
  doteID: string;
}) =>
  createSelector(
    medicalHistoryDocumentTimelineSelector,
    (medicalHistoryDocumentTimeline) => {
      return {
        documents:
          medicalHistoryDocumentTimeline?.[props.patiID]?.[props?.doteID] ?? [],
      };
    }
  );

// Actions
export function createOneMedicalHistoryTemplate(
  form: MedicalHistoryTemplateForm
): AppThunk<Promise<MedicalHistoryTemplate>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createMedicalHistoryTemplate(apiToken, form)
      .then((r) => {
        dispatch(actions.medicalHistoryTemplateLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Criado com sucesso",
          })
        );
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadMedicalHistoryTemplates(
  filter?: FilterMedicalHistoryTemplate
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return medicalHistoryTemplates(apiToken, filter)
      .then((r) => {
        dispatch(actions.medicalHistoryTemplatesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateOneMedicalHistoryTemplate(
  doteID: string,
  template: MedicalHistoryTemplateForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return updateMedicalHistoryTemplate(apiToken, doteID, template)
      .then((r) => {
        dispatch(actions.medicalHistoryTemplateLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Atualizado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createOneMedicalHistoryDocument(
  form: MedicalHistoryDocumentForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createMedicalHistoryDocument(apiToken, form)
      .then((r) => {
        dispatch(actions.medicalHistoryDocumentLoaded(r));
        dispatch(actions.medicalHistoryDocumentTimeline([r]));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function getOneMedicalHistoryDocument(
  patiID: string,
  filter?: FilterMedicalHistoryDocument
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return getMedicalHistoryDocument(apiToken, patiID, filter)
      .then((r) => {
        dispatch(actions.medicalHistoryDocumentsLoaded(r));
        dispatch(actions.medicalHistoryDocumentTimeline(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function getOneMedicalHistoryTemplate(
  doteID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return getMedicalHistoryTemplate(apiToken, doteID)
      .then((r) => {
        dispatch(actions.medicalHistoryTemplateLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedMedicalHistoryTemplate(
  doteID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const exit = state.medicalHistory.medicalHistoryTemplatesByID[doteID];
    if (exit) {
      return Promise.resolve();
    }
    return dispatch(getOneMedicalHistoryTemplate(doteID));
  };
}

export function removeMedicalHistoryTemplate(
  doteID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteMedicalHistoryTemplate(apiToken, doteID)
      .then((r) => {
        dispatch(actions.scheduleLockRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function getOneMedicalHistoryDocumentTimeline(
  patiID: string,
  doteID: string,
  f?: FilterMedicalHistoryTimeline
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return getMedicalHistoryDocumentTimeline(apiToken, patiID, doteID, f)
      .then((r) => {
        dispatch(actions.medicalHistoryDocumentTimeline(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
