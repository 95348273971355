import * as React from "react";
import { WithStyles, withStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@udok/lib/components/Dialog/ResponsiveDialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { CircularProgress } from "@material-ui/core";
import AvatarEditor from "react-avatar-editor";
import { Grid, Slider } from "@material-ui/core";
import { ZoomOutMap } from "@material-ui/icons";

const styles = (theme: Theme) => ({
  root: {},
  editorCanvas: {
    boxShadow: "0 1px 3px 0 rgba(1, 1, 1, 0.30)",
    width: "100% !important",
    height: "auto !important",
    maxHeight: 500,
  },
  input: {
    maxWidth: 42,
  },
  zoonContainer: {
    marginTop: theme.spacing(2),
  },
});

export interface DoctorDialogProps extends WithStyles<typeof styles> {
  onConfirm?: (x: File | File[]) => void;
  image: string;
  nameImage: string;
  type: string;
  width?: number;
  height?: number;
  open?: boolean;
  close?: (x?: File | File[]) => void;
  imageBackgroundColor?: string;
  otherDimensions?: { width: number; height: number }[];
}
export interface DoctorDialogState {
  loading: boolean;
  allowZoomOut: boolean;
  position: { x: number; y: number };
  scale: number;
  rotate: number;
  borderRadius: number;
  preview: null;
  value: number;
}

export const DoctorDialog = withStyles(styles)(
  class extends React.PureComponent<DoctorDialogProps, DoctorDialogState> {
    [x: string]: any;
    state = {
      loading: false,
      allowZoomOut: true,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      preview: null,
      value: 1,
    };

    handleSliderChange = (_event: React.ChangeEvent<{}>, newValue: any) => {
      this.setState({ scale: Number(newValue), value: newValue });
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const scale = parseFloat(event.target.value);
      this.setState({
        value: event.target.value === "" ? 0 : Number(event.target.value),
        scale: scale,
      });
    };

    handleBlur = () => {
      if (this.state.value < 0) {
        this.setState({ value: 0 });
      } else if (this.state.value > 100) {
        this.setState({ value: 100 });
      }
    };

    getStyledCanvas = () => {
      const { imageBackgroundColor } = this.props;
      const canvasScaled: HTMLCanvasElement =
        this.editor.getImageScaledToCanvas();
      if (!imageBackgroundColor) {
        return canvasScaled;
      }
      var tempCanvas = document.createElement("canvas"),
        tCtx = tempCanvas.getContext("2d");
      if (tCtx) {
        tempCanvas.width = canvasScaled.width;
        tempCanvas.height = canvasScaled.height;

        tCtx.fillStyle = imageBackgroundColor;
        tCtx.fillRect(0, 0, canvasScaled.width, canvasScaled.height);

        tCtx.drawImage(canvasScaled, 0, 0);
      }
      return tempCanvas;
    };

    generateFiles = () => {
      const canvas = this.getStyledCanvas();
      const name = this.props.nameImage ?? "";
      const file = this.dataURLtoFile(
        canvas.toDataURL("image/png"),
        name.split(/(\\|\/)/g)[4].replace(/\.(jpg|jpeg|gif)$/, ".png")
      );
      if ((this.props.otherDimensions ?? []).length > 0) {
        const ediWidth = this.editor.props.width;
        const ediHeight = this.editor.props.height;
        const imgWidth = this.editor.state.image.width;
        const imgHeight = this.editor.state.image.height;
        const files = (this.props.otherDimensions ?? []).map((d) => {
          this.editor.props = {
            ...this.editor.props,
            width: d.width,
            height: d.height,
          };
          const wp = (d.width * 100) / ediWidth;
          const hp = (d.height * 100) / ediHeight;
          this.editor.state.image = {
            ...this.editor.state.image,
            width: (imgWidth * wp) / 100,
            height: (imgHeight * hp) / 100,
          };
          const canvas = this.getStyledCanvas();
          const resizeFile = this.dataURLtoFile(
            canvas.toDataURL("image/png"),
            name.split(/(\\|\/)/g)[4].replace(/\.(jpg|jpeg|gif)$/, ".png")
          );
          return resizeFile;
        });
        return [file, ...files];
      }
      return file;
    };

    handleOk = () => {
      if (this.editor) {
        this.setState({ loading: true });
        const resp = this.generateFiles();
        this.setState({ loading: false });
        this.props.onConfirm?.(resp);
        this.props?.close?.(resp);
      }
    };

    dataURLtoFile = (dataurl: string, filename: string) => {
      var arr = dataurl.split(","),
        mime = arr?.[0]?.match(/:(.*?);/)?.[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    };

    handlePositionChange = (position: { x: number; y: number }) => {
      this.setState({ position });
    };

    setEditorRef = (editor: any) => (this.editor = editor);

    render() {
      const {
        classes,
        image,
        open = true,
        type,
        width = 349,
        height = 349,
      } = this.props;

      const resizeWidth = type === "banner" ? 1100 : width;
      const resizeHeight = type === "banner" ? 349 : height;

      return (
        <div className={classes.root}>
          <Dialog
            open={open}
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
          >
            <DialogTitle id="confirmation-dialog-title">
              Redimensionar imagem
            </DialogTitle>
            <DialogContent style={{ overflowY: "hidden" }}>
              <AvatarEditor
                ref={this.setEditorRef}
                image={image}
                width={resizeWidth}
                height={resizeHeight}
                onPositionChange={this.handlePositionChange}
                color={[0, 0, 0, 0.6]}
                scale={this.state.scale}
                rotate={this.state.rotate}
                className={classes.editorCanvas}
              />
              <div className={classes.zoonContainer}>
                <Typography id="input-slider" gutterBottom>
                  Zoom
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <ZoomOutMap />
                  </Grid>
                  <Grid item xs>
                    <Slider
                      value={
                        typeof this.state.value === "number"
                          ? this.state.value
                          : 0
                      }
                      step={0.01}
                      min={this.state.allowZoomOut ? 0.1 : 1}
                      max={2}
                      marks
                      defaultValue={1}
                      onChange={this.handleSliderChange}
                      aria-labelledby="input-slider"
                    />
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.props.close?.()} color="primary">
                Cancelar
              </Button>
              <Button
                onClick={this.handleOk}
                color="primary"
                disabled={this.state.loading}
              >
                Confirmar
                {this.state.loading && <CircularProgress size={25} />}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
);

export default DoctorDialog;
