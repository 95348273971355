import React, { Ref } from "react";
import {
  Stage,
  Layer,
  Image,
  Line,
  Rect,
  Transformer,
  Text,
} from "react-konva/lib/ReactKonvaCore";
import "konva/lib/shapes/Rect";
import "konva/lib/shapes/Transformer";
import "konva/lib/shapes/Line";
import "konva/lib/shapes/Text";
import "konva/lib/shapes/Image";
import Konva from "konva";
import useImage from "use-image";
import { Options, Line as LineD } from "./Types";

// the first very simple and recommended way:
const LionImage = (props: {
  src?: string | null;
  x: number;
  y: number;
  keepAspectRatio?: boolean;
  square: number;
}) => {
  const [image] = useImage(props.src ?? "");
  let width = props.square;
  let height = props.square;
  let calcX = props.x;
  let calcY = props.y;
  if (image && props.keepAspectRatio) {
    if (image.height > image.width) {
      width = (image.width * height) / image.height || 100;
      calcX = calcX + (height / 2 - width / 2);
    } else {
      height = (image.height * width) / image.width || 100;
      calcY = calcY + (width / 2 - height / 2);
    }
  }
  return (
    <Image image={image} width={width} height={height} x={calcX} y={calcY} />
  );
};
export type EditorRef = { toImage: (o: imgOptions) => void };
type imgOptions = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  pixelRatio?: number;
  mimeType?: string;
  quality?: number;
  callback?: (img: HTMLImageElement) => void;
};
const Editor = React.forwardRef(
  (
    {
      width,
      height,
      options,
      onChange,
    }: {
      width: number;
      height: number;
      options: Options;
      onChange: (o: Options) => void;
    },
    ref: Ref<EditorRef>
  ) => {
    const [selected, selectShape] = React.useState<boolean>(false);
    const [scale, setScale] = React.useState(1);

    const konvaref = React.useRef<Konva.Stage>(null);
    const viewref = React.useRef<Konva.Stage>(null);
    const textref = React.useRef<Konva.Text>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);

    React.useImperativeHandle(ref, () => ({
      toImage: (o: imgOptions) => {
        if (!konvaref.current) {
          return;
        }
        selectShape(false);
        setTimeout(() => konvaref?.current?.toImage?.(o), 100);
      },
    }));

    const checkDeselect = (e: any) => {
      // deselect when clicked on empty area
      const clickedOnEmpty = e.target === e.target.getStage();
      if (clickedOnEmpty) {
        selectShape(false);
      }
    };

    const resizeLines = (lines: LineD[]) => {
      return lines.map((line) => {
        const points = line.points.map((p) => p / scale);
        return {
          ...line,
          points,
        };
      });
    };

    const fitStageIntoParentContainer = React.useCallback(() => {
      const containerWidth = containerRef?.current?.offsetWidth ?? 0;
      const containerScale = containerWidth / width;
      const windowScale = (window.innerWidth - 35) / width;
      const scale = windowScale < containerScale ? windowScale : containerScale;

      const newWidth = width * scale;
      const newHeight = height * scale;

      viewref.current?.width(newWidth);
      viewref.current?.height(newHeight);

      textref.current?.x(0 + (newWidth * 0.2) / 2);
      textref.current?.y(newHeight * 0.3);
      textref.current?.scale({ x: scale, y: scale });
      setScale(scale);
    }, [width, height, containerRef]);

    React.useEffect(() => {
      if (containerRef) {
        fitStageIntoParentContainer();
      }
    }, [width, height, containerRef, fitStageIntoParentContainer]);
    window.addEventListener("resize", fitStageIntoParentContainer);

    return (
      <div
        ref={containerRef}
        style={{
          width: "100%",
          height: "auto",
          border: "1px solid #acacac",
          position: "relative",
          maxWidth: width,
          maxHeight: height,
        }}
      >
        <Stage
          width={width}
          height={height}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
          ref={viewref}
        >
          {options?.backgroundColor?.visible ? (
            <Layer>
              <Rect
                fill={options?.backgroundColor?.color}
                width={(width * 76.5) / 100}
                height={height}
                listening={false}
              />
            </Layer>
          ) : null}
          {options.background.visible ? (
            <Layer>
              <Rectangle
                selected={selected}
                onSelect={() => selectShape(true)}
                onChange={(s: any) =>
                  onChange({
                    ...options,
                    background: {
                      ...options.background,
                      shape: s,
                    },
                  })
                }
                src={options.background.image}
                shape={options.background.shape}
                layout={{ width, height }}
              />
            </Layer>
          ) : null}
          {options.lateral.visible ? (
            <Layer>
              <LionImage
                keepAspectRatio
                src={options.lateral.image}
                y={0}
                x={options.lateral.alignLeft ? 0 : width - height}
                square={height}
              />
            </Layer>
          ) : null}
          {options.text.visible ? (
            <Layer>
              <Text
                ref={textref}
                text={options.text.value}
                x={0 + (width * 0.2) / 2}
                y={height * 0.3}
                height={height}
                width={width * 0.8}
                fill={options.text.color ?? "#000"}
                fontSize={16}
                align="center"
                verticalAlign="middle"
              />
            </Layer>
          ) : null}
          {options.signature.visible ? (
            <SignatureDrawpad
              width={width}
              height={height}
              lines={options.signature.lines}
              onChange={(lines) =>
                onChange({
                  ...options,
                  signature: {
                    ...options.signature,
                    lines: lines,
                  },
                })
              }
              color={options.signature.color}
            />
          ) : null}
        </Stage>
        <Stage
          width={width}
          height={height}
          ref={konvaref}
          style={{ display: "none" }}
        >
          {options?.backgroundColor?.visible ? (
            <Layer>
              <Rect
                fill={options?.backgroundColor?.color}
                width={width}
                height={height}
                listening={false}
              />
            </Layer>
          ) : null}
          {options.background.visible ? (
            <Layer>
              <Rectangle
                src={options.background.image}
                shape={options.background.shape}
                layout={{ width, height }}
              />
            </Layer>
          ) : null}
          {options.lateral.visible ? (
            <Layer>
              <LionImage
                keepAspectRatio
                src={options.lateral.image}
                y={0}
                x={options.lateral.alignLeft ? 0 : width - height}
                square={height}
              />
            </Layer>
          ) : null}
          {options.text.visible ? (
            <Layer>
              <Text
                text={options.text.value}
                x={0 + (width * 0.2) / 2}
                y={height * 0.3}
                height={height}
                width={width * 0.8}
                fill={options.text.color ?? "#000"}
                fontSize={16}
                align="center"
                verticalAlign="middle"
              />
            </Layer>
          ) : null}
          {options.signature.visible ? (
            <SignatureDrawpad
              width={width}
              height={height}
              lines={resizeLines(options.signature.lines)}
              color={options.signature.color}
            />
          ) : null}
        </Stage>
      </div>
    );
  }
);

const Rectangle = ({
  shape: shapeProps,
  selected: isSelected,
  onSelect,
  onChange,
  src,
  layout,
}: any) => {
  const shapeRef = React.useRef();
  const trRef = React.useRef<any>();

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);
  const [image] = useImage(src ?? "");
  React.useEffect(() => {
    if (image) {
      let width = layout?.width ?? 100;
      let height = layout?.height ?? 100;
      let x = shapeProps.x;
      let y = shapeProps.y;
      if (image.height > image.width) {
        width = (image.width * height) / image.height || 100;
      } else {
        height = (image.height * width) / image.width || 100;
      }
      onChange?.({
        ...shapeProps,
        width,
        height,
        x,
        y,
      });
    }
    // eslint-disable-next-line
  }, [image]);

  const centerdY = shapeProps.y - shapeProps.height / 2;
  const centerdX = shapeProps.x - shapeProps.width / 2;

  return (
    <React.Fragment>
      <Image
        image={image}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...{ ...shapeProps, x: centerdX, y: centerdY }}
        draggable
        onDragEnd={(e) => {
          onChange?.({
            ...shapeProps,
            x: e.target.x() + shapeProps.width / 2,
            y: e.target.y() + shapeProps.height / 2,
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node: any = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);

          const width = Math.max(5, node.width() * scaleX);
          const height = Math.max(node.height() * scaleY);
          onChange?.({
            ...shapeProps,
            x: node.x() + width / 2,
            y: node.y() + height / 2,
            // set minimal value
            width,
            height,
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

const SignatureDrawpad = ({
  width,
  height,
  onChange,
  lines,
  color,
}: {
  width: number;
  height: number;
  onChange?: (l: LineD[]) => void;
  lines: LineD[];
  color: string;
}) => {
  const tool = "pen";
  const isDrawing = React.useRef(false);

  const handleMouseDown = (e: any) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    onChange?.([...lines, { tool, points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e: any) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine: any = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    onChange?.(lines.concat());
  };

  const handleStopDrawing = () => {
    isDrawing.current = false;
  };

  return (
    <Layer>
      {lines.map((line, i) => (
        <Line
          key={i}
          points={line.points}
          stroke={color}
          strokeWidth={3}
          tension={0.5}
          lineCap="round"
          globalCompositeOperation={
            line.tool === "eraser" ? "destination-out" : "source-over"
          }
        />
      ))}
      <Rect
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleStopDrawing}
        onMouseOut={handleStopDrawing}
        onTouchEnd={handleStopDrawing}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        width={width}
        height={height}
      />
    </Layer>
  );
};

export default Editor;
