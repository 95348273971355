import * as React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
// @ts-ignore
import createDOMForm from "rc-form/lib/createDOMForm";
// @ts-ignore
import { formShape } from "rc-form";
import {
  InputLabel,
  MenuItem,
  Checkbox,
  CircularProgress,
  Button,
  TextField,
  InputAdornment,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import Selector from "@udok/lib/components/Selector";
import LocationPicker from "containers/Location/Select";
import SelectHealthplans from "containers/Healthplan/Search";
import SelectSharedResources from "containers/SharedResource/Select";
import {
  Week,
  DefaultAptyID,
  DefaultPlan,
  TimeZonesInSeconds,
} from "@udok/lib/internal/constants";
import {
  shrinker,
  normalizeMoney,
  format,
  formatMoneyInFloat,
} from "@udok/lib/internal/util";
import {
  ScheduleType,
  Schedule,
  Location,
  PlanFeaturesPermissions,
  AppointmentStatus,
  getStatusLabel,
} from "@udok/lib/api/models";
import SelectAppointmentType from "containers/AppointmentType/Select";
import ProcedureSelect from "containers/Procedure/Search";
import ScheduleAppoOptions from "containers/Schedule/ScheduleAppoOptions";
import WarnNoRecipientRegistered from "containers/Finances/Recipient/WarnNoRecipientRegistered";
import HelpPopover from "@udok/lib/components/HelpPopover";
import CheckboxField, {
  CheckboxFieldPorps,
} from "@udok/lib/components/CheckboxField";
import TextFieldTypeNumber from "@udok/lib/components/Input/TextFieldTypeNumber";
import InputScheduleType from "containers/Schedule/InputScheduleType";
import ResourceAlertPopover from "containers/LimitedResourceAlert/Popover";
import ResourceAlertDialog from "containers/LimitedResourceAlert/Dialog";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import {
  TypeHour,
  validateTime,
  validateDate,
  validateWeekdays,
  calcDuration,
  validateSimultaneousNumber,
} from "containers/Schedule/FormUtil";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export enum FormDisabledType {
  update = "update",
  clinic = "clinic",
}

export type InitialValues = Partial<Schedule> & {
  startDate?: string;
  endDate?: string;
  addPeriod?: boolean;
  blockUnpaidAppointment?: boolean;
};

export interface ScheduleFormProps {
  onSubmit: (err: any, values: any, form: formShape) => void;
  initialValues?: InitialValues;
  form?: formShape;
  loading: boolean;
  disabledType?: FormDisabledType;
  autoCompletMarketplace?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      padding: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
      },
    },
    rangeContainer: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      "&> *": {
        minWidth: "25%",
        [theme.breakpoints.down("md")]: {
          width: `calc(100vw - ${theme.spacing(10)})`,
        },
      },
      "& > *:nth-child(n+2)": {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
        },
      },
    },
    popoverCard: {
      maxWidth: 350,
    },
    popoverTitle: {
      fontSize: 14,
    },
    labelEndDate: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    checkboxContainer: {
      marginTop: theme.spacing(1),
      width: "100%",
      "& > :nth-child(2)": {
        width: "100%",
      },
    },
    InputDate: {
      "& input::-webkit-calendar-picker-indicator": {
        display: "none",
        "-webkit-appearance": "none",
      },
    },
  })
);

const [rendererResourceAlert, promiseResourceAlert] =
  createModal(ResourceAlertPopover);

function openResourceAlert(err: any, values: any, anchorEl: any) {
  const invalidPlan =
    (values.healthPlans as string[]).filter((heplID) => heplID !== DefaultPlan)
      .length > 0;
  const alert =
    values.type !== ScheduleType.place ||
    values.marketplaceOffer ||
    invalidPlan;
  if (!err && alert) {
    return promiseResourceAlert({
      anchorEl,
      feature: [
        PlanFeaturesPermissions.UnlimitedSchedule,
        PlanFeaturesPermissions.HealthPlans,
        PlanFeaturesPermissions.BankAccounts,
      ],
    });
  }
  return Promise.resolve(false);
}

export const Form = (props: ScheduleFormProps) => {
  const {
    form,
    loading,
    initialValues,
    disabledType,
    autoCompletMarketplace,
    onSubmit,
  } = props;
  const { getFieldProps, getFieldError, getFieldValue, setFieldsValue } = form;
  const theme = useTheme();
  const classes = useStyles();

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      form.validateFieldsAndScroll(
        { scroll: { offsetTop: theme.spacing(12) } },
        (err: any, values: any) => {
          openResourceAlert(err, values, (e.nativeEvent as any).submitter).then(
            (openOffer) => {
              if (openOffer) {
                return;
              }
              values = {
                ...values,
                weekdayTimezoneOffset: parseInt(values.weekdayTimezoneOffset),
                simultaneousAppointments: parseInt(
                  values.simultaneousAppointments
                ),
              };
              onSubmit(err, values, form);
            }
          );
        }
      );
    },
    [theme, form, onSubmit]
  );

  const listAppoType = initialValues?.appointmentOptions?.map((a) => a.aptyID);
  const dfaultAppoType = initialValues?.appointmentOptions?.filter?.(
    (a) => a.default === true
  )?.[0];
  const isClinc = disabledType === FormDisabledType.clinic;

  const startAt = moment(getFieldValue("startAt"), format.TIME24H);
  const endAt = moment(getFieldValue("endAt"), format.TIME24H);
  const openAlert =
    endAt.isValid() && startAt.isValid() && endAt.isBefore(startAt);
  const eventDuration = parseInt(getFieldValue("eventDuration"));
  const appointmentDuration = parseInt(getFieldValue("appointmentDuration"));

  const numberOfHours =
    !isNaN(eventDuration) &&
    eventDuration > 0 &&
    !isNaN(appointmentDuration) &&
    appointmentDuration > 0
      ? Math.floor(eventDuration / appointmentDuration)
      : 0;
  const price = formatMoneyInFloat(
    getFieldValue("price") ??
      initialValues?.price?.toString()?.replace?.(".", ",")
  );
  const disableMarketplace =
    isClinc || getFieldValue("noRecipientRegistered") || price === 0;

  const statusList = [
    AppointmentStatus.scheduled,
    AppointmentStatus.confirmed,
  ].map((value) => ({ value, label: getStatusLabel(value) }));

  return (
    <form className={classes.table} onSubmit={handleSubmit}>
      <InputLabel htmlFor="sort" style={{ fontSize: 12, color: "#7B8087" }}>
        Tipo de agenda
      </InputLabel>
      <InputScheduleType
        validatePermission
        disabled={isClinc || disabledType === FormDisabledType.update}
        {...getFieldProps("type", {
          rules: [{ type: "string", required: true }],
          initialValue: initialValues?.type ?? "virtual",
        })}
      />

      {getFieldValue("type") === ScheduleType.examProcedure ? (
        <ProcedureSelect
          fullWidth
          margin="normal"
          label="Selecionar exame/procedimento"
          disabled={isClinc}
          error={Boolean(getFieldError("exprID"))}
          errorMessage={getFieldError("exprID")}
          {...getFieldProps("exprID", {
            rules: [{ required: true, message: "Campo requerido" }],
            initialValue: initialValues?.procedures?.[0],
          })}
        />
      ) : null}

      {getFieldValue("type") !== ScheduleType.virtual ? (
        <>
          <LocationPicker
            label="Escolha a localização"
            error={getFieldError("location")}
            disabled={isClinc}
            listAll={isClinc}
            returnType="location"
            variant="outlined"
            margin="normal"
            {...getFieldProps("location", {
              validateFirst: true,
              rules: [{ required: true, message: "Campo requerido" }],
              initialValue: initialValues?.locaID,
              normalize: (value: any) => {
                if (typeof value !== "string") {
                  return value?.locaID ?? "";
                }
                return value;
              },
              onChange: (value: Location) => {
                if (value?.price) {
                  setFieldsValue({
                    price: value.price.toString().replace(".", ","),
                  });
                }
                if ((value?.healthPlans ?? []).length > 0) {
                  setFieldsValue({
                    healthPlans: value.healthPlans,
                    locationPlans: value.healthPlans,
                  });
                }
              },
            })}
          />

          <input
            type="hidden"
            {...getFieldProps("locationPlans", {
              initialValue: [],
            })}
          />
        </>
      ) : null}

      <Selector
        fullWidth
        disabled={isClinc}
        label="Tipo de horário"
        variant="outlined"
        margin="normal"
        list={[
          { label: "Horário recorrente", value: TypeHour.weekly },
          { label: "Horário único", value: TypeHour.date },
        ]}
        {...getFieldProps("typeHour", {
          rules: [
            {
              type: "string",
              required: true,
              message: "O tipo de horário é obrigatório",
            },
          ],
          initialValue:
            initialValues?.weekDay && initialValues.weekDay.length === 0
              ? TypeHour.date
              : TypeHour.weekly,
        })}
      />

      {getFieldValue("typeHour") === TypeHour.date ? (
        <>
          <TextField
            disabled={
              (getFieldValue("typeHour") !== undefined &&
                getFieldValue("typeHour") === TypeHour.weekly) ||
              isClinc
            }
            label="Selecione a data"
            type="date"
            variant="outlined"
            fullWidth
            error={Boolean(getFieldError("startDate"))}
            helperText={getFieldError("startDate")}
            margin="normal"
            InputProps={{
              classes: {
                root: classes.InputDate,
              },
            }}
            InputLabelProps={{
              ...shrinker(getFieldValue("startDate")),
            }}
            {...getFieldProps("startDate", {
              rules: [{ validator: validateDate(getFieldValue) }],
              initialValue:
                initialValues?.startDate ?? moment().format(format.DASHUN),
            })}
          />
        </>
      ) : null}

      {getFieldValue("typeHour") === TypeHour.weekly ? (
        <>
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Dias da semana"
            SelectProps={{
              displayEmpty: true,
              multiple: true,
              renderValue: (selected: any) => {
                return selected
                  .map((item: any) => {
                    return (Week as any)[item];
                  })
                  .join(", ") as any;
              },
            }}
            disabled={
              (getFieldValue("typeHour") !== undefined &&
                getFieldValue("typeHour") === TypeHour.date) ||
              isClinc
            }
            InputLabelProps={{
              ...shrinker(getFieldValue("weekDay")),
            }}
            margin="normal"
            error={Boolean(getFieldError("weekDay"))}
            helperText={getFieldError("weekDay")}
            {...getFieldProps("weekDay", {
              rules: [{ validator: validateWeekdays(getFieldValue) }],
              initialValue: initialValues?.weekDay ?? [],
            })}
          >
            {Object.keys(Week).map((key, i) => (
              <MenuItem value={key} key={i}>
                <Checkbox
                  checked={getFieldValue("weekDay")?.indexOf(key) > -1}
                />
                {Week[key as keyof typeof Week]}
              </MenuItem>
            ))}
          </TextField>
          <CheckboxField
            name="addPeriod"
            color="primary"
            disabled={isClinc}
            label={
              <span className={classes.labelEndDate}>
                <Typography>Definir período de recorrência.</Typography>
                <HelpPopover title="O que é isso?">
                  <Card className={classes.popoverCard}>
                    <CardContent>
                      <Typography
                        color="textSecondary"
                        className={classes.popoverTitle}
                        gutterBottom
                      >
                        Udok Ajuda
                      </Typography>
                      <Typography variant="body2">
                        Este campo não é obrigatório.
                        <br />
                        Caso não selecione este campo o horário ficara em sua
                        agenda ate ser editado ou removido.
                      </Typography>
                    </CardContent>
                  </Card>
                </HelpPopover>
              </span>
            }
            formControlLabelProps={{
              className: classes.checkboxContainer,
            }}
            {...getFieldProps("addPeriod", {
              initialValue: initialValues?.addPeriod ?? false,
            })}
          />

          {getFieldValue("addPeriod") ? (
            <div className={classes.rangeContainer}>
              <TextField
                type="date"
                margin="normal"
                variant="outlined"
                disabled={isClinc}
                error={Boolean(getFieldError("startDate"))}
                helperText={getFieldError("startDate")}
                InputLabelProps={{
                  ...shrinker(getFieldValue("startDate")),
                }}
                InputProps={{
                  classes: { root: classes.InputDate },
                  startAdornment: (
                    <InputAdornment position="start">
                      Repetir horário a partir de
                    </InputAdornment>
                  ),
                }}
                {...getFieldProps("startDate", {
                  rules: [
                    {
                      validator: (val: any, src: any, cb: any) => {
                        const endDate = getFieldValue("endDate");
                        const date = moment(src, format.DASHUN);
                        if (
                          date.isAfter(moment(endDate, format.DASHUN), "day")
                        ) {
                          cb("Data inválida");
                          return;
                        }
                        cb();
                      },
                    },
                  ],
                  initialValue:
                    initialValues?.startDate ?? moment().format(format.DASHUN),
                })}
              />
              <TextField
                type="date"
                error={Boolean(getFieldError("endDate"))}
                helperText={getFieldError("endDate")}
                margin="normal"
                variant="outlined"
                disabled={isClinc}
                InputLabelProps={{
                  ...shrinker(getFieldError("endDate")),
                }}
                InputProps={{
                  classes: {
                    root: classes.InputDate,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      Repetir horário até
                    </InputAdornment>
                  ),
                }}
                {...getFieldProps("endDate", {
                  rules: [{ validator: validateDate(getFieldValue) }],
                  initialValue:
                    initialValues?.endDate ??
                    moment().add(30, "days").format(format.DASHUN),
                })}
              />
            </div>
          ) : null}
        </>
      ) : null}

      <div className={classes.rangeContainer}>
        <TextField
          type="time"
          label="Horário de início"
          error={Boolean(getFieldError("startAt"))}
          helperText={getFieldError("startAt") || ""}
          margin="normal"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          disabled={isClinc}
          {...getFieldProps("startAt", {
            rules: [
              {
                type: "string",
                required: true,
                message: "A Horário de início é obrigatório",
              },
            ],
            initialValue: initialValues?.startAt,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              const startAt = e.target.value;
              const endAt = getFieldValue("endAt");
              if (startAt && endAt) {
                const eventDuration = calcDuration(startAt, endAt);
                setFieldsValue({
                  eventDuration,
                });
              }
              setFieldsValue({
                appointmentDuration: getFieldValue("appointmentDuration"),
              });
            },
          })}
        />
        <TextField
          type="time"
          variant="outlined"
          label="Horário de fim"
          error={Boolean(getFieldError("endAt"))}
          helperText={getFieldError("endAt") || ""}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isClinc}
          {...getFieldProps("endAt", {
            rules: [
              {
                type: "string",
                required: true,
                message: "A Horário de fim é obrigatório",
              },
            ],
            initialValue: initialValues?.endAt,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              const startAt = getFieldValue("startAt");
              const endAt = e.target.value;
              if (startAt && endAt) {
                const eventDuration = calcDuration(startAt, endAt);
                setFieldsValue({
                  eventDuration,
                });
              }
              setFieldsValue({
                appointmentDuration: getFieldValue("appointmentDuration"),
              });
            },
          })}
        />
        <Selector
          disabled={isClinc}
          label="Fuso horário"
          variant="outlined"
          margin="normal"
          list={TimeZonesInSeconds.map((item) => ({
            ...item,
            value: item.value.toString(),
          }))}
          error={getFieldError("weekdayTimezoneOffset")}
          errorMessage={getFieldError("weekdayTimezoneOffset")}
          {...getFieldProps("weekdayTimezoneOffset", {
            rules: [
              {
                required: true,
                message: "O Fuso horário é obrigatorio",
              },
            ],
            initialValue:
              initialValues?.weekdayTimezoneOffset?.toString?.() ??
              (moment().utcOffset() * 60).toString(),
          })}
        />
      </div>

      {openAlert ? (
        <Typography variant="body2" color="textSecondary">
          Este horário encerra no dia seguinte as {endAt.format(format.TIME24H)}
        </Typography>
      ) : null}
      <input
        type="hidden"
        {...getFieldProps("eventDuration", {
          initialValue: initialValues?.eventDuration ?? 0,
        })}
      />
      <div className={classes.rangeContainer}>
        <TextFieldTypeNumber
          variant="outlined"
          error={Boolean(getFieldError("appointmentDuration"))}
          helperText={getFieldError("appointmentDuration") || ""}
          margin="normal"
          label="Duração em minutos"
          disabled={isClinc}
          InputLabelProps={{
            ...shrinker(initialValues?.appointmentDuration),
          }}
          InputProps={{
            inputProps: {
              min: "1",
            },
            endAdornment:
              numberOfHours > 0 ? (
                <InputAdornment position="end">{`Número de atendimentos: ${numberOfHours}`}</InputAdornment>
              ) : null,
          }}
          {...getFieldProps("appointmentDuration", {
            rules: [
              { required: true, message: "A duração é obrigatório" },
              { validator: validateTime(getFieldValue) },
            ],
            initialValue: initialValues?.appointmentDuration,
          })}
        />
        <TextFieldTypeNumber
          variant="outlined"
          error={Boolean(getFieldError("simultaneousAppointments"))}
          helperText={getFieldError("simultaneousAppointments") || ""}
          margin="normal"
          label="Atendimentos simultâneos"
          disabled={isClinc}
          InputLabelProps={{
            ...shrinker(initialValues?.simultaneousAppointments),
          }}
          InputProps={{ inputProps: { min: "1" } }}
          {...getFieldProps("simultaneousAppointments", {
            rules: [{ validator: validateSimultaneousNumber }],
            initialValue: initialValues?.simultaneousAppointments ?? 1,
          })}
        />
        <Selector
          disabled={isClinc}
          label="Status inicial do agendamento"
          variant="outlined"
          margin="normal"
          list={statusList}
          {...getFieldProps("defaultStatus", {
            initialValue:
              initialValues?.defaultStatus ?? AppointmentStatus.scheduled,
          })}
        />
      </div>

      <Typography variant="h6" style={{ marginTop: 10, marginBottom: 10 }}>
        Valor do agendamento
      </Typography>
      <TextField
        type="text"
        label="Valor do agendamento"
        error={Boolean(getFieldError("price"))}
        margin="normal"
        variant="outlined"
        disabled={isClinc}
        placeholder="0,00"
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
        {...getFieldProps("price", {
          initialValue: initialValues?.price?.toString()?.replace?.(".", ","),
          normalize: normalizeMoney,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            const noRecipient = getFieldValue("noRecipientRegistered");
            if (
              parseFloat(normalizeMoney(e.target.value).replace(",", ".")) >
                0 &&
              !noRecipient
            ) {
              setFieldsValue({
                marketplaceOffer: autoCompletMarketplace,
                blockUnpaidAppointment: autoCompletMarketplace,
              });
            }
          },
        })}
      />

      <Typography variant="h6" style={{ marginTop: 10, marginBottom: 10 }}>
        Udok Marketplace
      </Typography>
      <div style={{ color: "#7B8087", marginTop: 5 }}>
        Consultas podem ser cobradas através do Udok Marketplace. Pagamento com
        cartão de crédito, PIX ou boleto bancário. Taxas aplicàveis:
        <a href="https://udok.digital/terms/termos-e-condicoes.html">
          Termos de Uso
        </a>
      </div>
      <WarnNoRecipientRegistered
        style={{ marginTop: 12, marginBottom: 12 }}
        {...getFieldProps("noRecipientRegistered")}
      />
      <CheckboxEnablePayment
        name="Marketplace"
        color="primary"
        label="Habilitar pagamento de consultas"
        disabled={disableMarketplace}
        {...getFieldProps("marketplaceOffer", {
          initialValue: initialValues?.marketplaceOffer ?? false,
          onChange: (checked: boolean) => {
            if (getFieldValue("type") === ScheduleType.virtual) {
              setFieldsValue({
                blockUnpaidAppointment: checked,
              });
            }
          },
        })}
      />

      <div style={{ color: "#7B8087", marginTop: 5 }}>
        Pacientes só terão acesso à consulta apos efetuar o pagamento
      </div>
      <CheckboxField
        disabled={disableMarketplace || !getFieldValue("marketplaceOffer")}
        name="Visibilidade"
        color="primary"
        formControlLabelProps={{
          className: classes.checkboxContainer,
        }}
        label={
          <span className={classes.labelEndDate}>
            <Typography>
              Bloquear consultas com pagamento incompleto.
            </Typography>
            <HelpPopover title="O que é isso?">
              <Card className={classes.popoverCard}>
                <CardContent>
                  <Typography
                    color="textSecondary"
                    className={classes.popoverTitle}
                    gutterBottom
                  >
                    Udok Ajuda
                  </Typography>
                  <Typography variant="body2">
                    Este campo não é obrigatório.
                    <br />
                    Ao selecionar esse campo o paciente só vai ter acesso à
                    consulta após efetuar o pagamento, se o pagamento não for
                    realizado a consulta será cancelada automaticamente.
                  </Typography>
                </CardContent>
              </Card>
            </HelpPopover>
          </span>
        }
        {...getFieldProps("blockUnpaidAppointment", {
          initialValue: initialValues?.blockUnpaidAppointment ?? false,
        })}
      />
      <Typography variant="body2" color="textSecondary">
        Cancelar agendamentos não pagos
      </Typography>
      <TextFieldTypeNumber
        label="Cancelar agendamento após"
        variant="outlined"
        margin="normal"
        disabled={disableMarketplace || !getFieldValue("marketplaceOffer")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography color="textSecondary">Minutos</Typography>
              <HelpPopover title="O que é isso?">
                <Card className={classes.popoverCard}>
                  <CardContent>
                    <Typography
                      color="textSecondary"
                      className={classes.popoverTitle}
                      gutterBottom
                    >
                      Udok Ajuda
                    </Typography>
                    <Typography variant="body2">
                      Este campo não é obrigatório.
                      <br />
                      Os agendamentos criados para esse horário serão cancelados
                      automaticamente caso o pagamento não seja efetuado no
                      tempo definido neste campo.
                      <br />
                      Agendamentos pagos por boleto não serão cancelados
                      automaticamente.
                    </Typography>
                  </CardContent>
                </Card>
              </HelpPopover>
            </InputAdornment>
          ),
        }}
        {...getFieldProps("paymentCancelAfterMinutes", {
          initialValue: initialValues?.cancelAfterMinutes?.payment,
        })}
      />
      <Typography variant="h6" style={{ marginTop: 10, marginBottom: 10 }}>
        Planos de saúde
      </Typography>
      <SelectHealthplans
        multiple
        fullWidth
        validatePermission
        label="Selecione os planos"
        margin="normal"
        variant="outlined"
        error={Boolean(getFieldError("healthPlans"))}
        errorMessage={getFieldError("healthPlans")}
        disabled={isClinc}
        {...getFieldProps("healthPlans", {
          rules: [
            {
              type: "array",
              required: true,
              message: "Plano de saúde é obrigatório",
            },
          ],
          initialValue:
            (initialValues?.healthPlans?.length || 0) > 0
              ? initialValues?.healthPlans
              : [DefaultPlan],
        })}
      />

      <Typography variant="h6" style={{ marginTop: 10, marginBottom: 10 }}>
        Recurso
      </Typography>
      <div style={{ color: "#7B8087", marginBottom: 15 }}>
        Uso simultâneo: Quantidade de usuários que podem usar um recurso ao
        mesmo tempo
      </div>
      <SelectSharedResources
        label="Selecione o recurso"
        multiple
        fullWidth
        margin="normal"
        variant="outlined"
        error={Boolean(getFieldError("sharedResources"))}
        errorMessage={getFieldError("sharedResources")}
        {...getFieldProps("sharedResources", {
          initialValue:
            (initialValues?.sharedResources?.length || 0) > 0
              ? initialValues?.sharedResources
              : [],
        })}
        disabled={isClinc}
      />

      <Typography variant="h6" style={{ marginTop: 10, marginBottom: 10 }}>
        Tipos de atendimento
      </Typography>
      {isClinc ? (
        <>
          <ScheduleAppoOptions
            Idlist={listAppoType ?? []}
            value={listAppoType}
            fullWidth
            multiple
            placeholder="Selecionar tipo de atendimento"
            label="Tipo de atendimento"
            variant="outlined"
            margin="normal"
            disabled={true}
          />
          <ScheduleAppoOptions
            label="Tipo de atendimento padrão"
            Idlist={listAppoType ?? []}
            value={dfaultAppoType?.aptyID}
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={true}
          />
        </>
      ) : (
        <>
          <SelectAppointmentType
            fullWidth
            multiple
            placeholder="Selecionar tipo de atendimento"
            label="Tipo de atendimento"
            variant="outlined"
            margin="normal"
            disabled={isClinc}
            error={Boolean(getFieldError("aptyIDList"))}
            errorMessage={getFieldError("aptyIDList")}
            {...getFieldProps("aptyIDList", {
              rules: [
                (
                  a: any,
                  value: string[] | undefined,
                  cb: (e?: string) => void
                ) => {
                  if ((value ?? []).length === 0) {
                    cb("Campo obrigatorio");
                    return;
                  }
                  cb();
                },
              ],
              initialValue: listAppoType ?? [DefaultAptyID],
            })}
          />

          <SelectAppointmentType
            label="Tipo de atendimento padrão"
            fullWidth
            variant="outlined"
            margin="normal"
            filter={getFieldValue("aptyIDList")}
            disabled={
              ((getFieldValue("aptyIDList") as string[]) ?? []).length === 0 ||
              isClinc
            }
            {...getFieldProps("defaultOption", {
              initialValue:
                dfaultAppoType?.aptyID ?? getFieldValue("aptyIDList")?.[0],
            })}
          />
        </>
      )}

      <Typography variant="body2" color="textSecondary">
        Cancelar agendamentos com formulários e documentos pendentes
      </Typography>
      <TextFieldTypeNumber
        label="Cancelar consulta após"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography color="textSecondary">Minutos</Typography>
              <HelpPopover title="O que é isso?">
                <Card className={classes.popoverCard}>
                  <CardContent>
                    <Typography
                      color="textSecondary"
                      className={classes.popoverTitle}
                      gutterBottom
                    >
                      Udok Ajuda
                    </Typography>
                    <Typography variant="body2">
                      Este campo não é obrigatório.
                      <br />
                      Os agendamentos criados para esse horário serão canceladas
                      automaticamente caso os formulários e documentos
                      requeridos não sejam preenchidos no tempo definido neste
                      campo.
                    </Typography>
                  </CardContent>
                </Card>
              </HelpPopover>
            </InputAdornment>
          ),
        }}
        {...getFieldProps("documentCancelAfterMinutes", {
          initialValue: initialValues?.cancelAfterMinutes?.document,
        })}
      />

      <Typography variant="h6" style={{ marginTop: 10, marginBottom: 10 }}>
        Visibilidade
      </Typography>
      <div style={{ color: "#7B8087", marginTop: 5 }}>
        Pacientes podem visualizar e marcar consultas neste horário
      </div>
      <CheckboxField
        disabled={isClinc}
        name="Visibilidade"
        color="primary"
        label="Habilitar marcação pública"
        {...getFieldProps("selfService", {
          initialValue: initialValues?.selfService ?? true,
        })}
      />
      {isClinc ? (
        <>
          <div style={{ color: "#7B8087", marginTop: 5 }}>
            Este horário não será visível no perfil do médico. O agendamento
            poderá apenas ser realizado ao acessar o perifl da clínica
          </div>
          <CheckboxField
            disabled
            value={initialValues?.exclusiveListing ?? false}
            name="Visibilidade"
            color="primary"
            label="Visível apenas no perfil da clínica"
          />
        </>
      ) : null}

      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: 24,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading || isClinc}
        >
          <span>Salvar</span>
          {loading && <CircularProgress size={24} />}
        </Button>
      </div>
      {rendererResourceAlert}
    </form>
  );
};

const [rendererResourceDialog, promiseResourceDialog] =
  createModal(ResourceAlertDialog);

const CheckboxEnablePayment = ({
  onChange,
  ...others
}: Omit<CheckboxFieldPorps, "onChange"> & {
  onChange: (checked: boolean) => void;
}) => {
  const [activeResourceAlert, setActive] = React.useState(true);

  const handleChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (activeResourceAlert && checked) {
        await promiseResourceDialog({
          feature: PlanFeaturesPermissions.BankAccounts,
        }).then(() => setActive(false));
      }
      onChange?.(checked);
    },
    [activeResourceAlert, onChange]
  );

  return (
    <>
      <CheckboxField {...others} onChange={handleChange} />
      {rendererResourceDialog}
    </>
  );
};

export default createDOMForm()(Form) as typeof Form;
