import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  CareRecipient,
  ImmediateCareView,
  DoctorPublicProfile,
  FilterImmediateCare,
  ImmediateCareSession,
  ImmediateCareSessionForm,
} from "@udok/lib/api/models";
import {
  listImmediateCare,
  getImmediateCare,
} from "@udok/lib/api/immediateCare";
import {
  cancelCallCareRecipient,
  exitToImmediateCareSession,
  createImmediateCareSession,
  finishImmediateCareSession,
  entryToImmediateCareSession,
  createImmediateCareSessionView,
  completeCareToImmediateCareSession,
  callCareRecipientToImmediateCareSession,
} from "@udok/lib/api/immediateCareSession";
import { searchDoctorProfiles } from "@udok/lib/api/doctor";
import { getToken, UNAUTHORIZED } from "./auth";

export type InitialState = {
  immediateCareByID: { [imcaID: string]: ImmediateCareView | undefined };
  immediateCareByImcsID: { [imcsID: string]: string | undefined };
  doctorProfileByID: { [doctID: string]: DoctorPublicProfile | undefined };
};

// Reducers
const initialState: InitialState = {
  immediateCareByID: {},
  immediateCareByImcsID: {},
  doctorProfileByID: {},
};

class ImmediateCares extends Hen<InitialState> {
  immediateCareLoaded(ic: ImmediateCareView) {
    this.state.immediateCareByID[ic.imcaID] = ic;
    const imcsID = ic.currentSession?.imcsID;
    if (imcsID) {
      this.state.immediateCareByImcsID[imcsID] = ic.imcaID;
    }
  }
  immediateCaresLoaded(ics: ImmediateCareView[]) {
    ics.forEach((ic) => {
      this.state.immediateCareByID[ic.imcaID] = ic;
      const imcsID = ic.currentSession?.imcsID;
      if (imcsID) {
        this.state.immediateCareByImcsID[imcsID] = ic.imcaID;
      }
    });
  }
  immediateCareSessionLoaded(ics: ImmediateCareSession) {
    const current = this.state.immediateCareByID?.[ics.imcaID];
    if (current) {
      this.state.immediateCareByID[ics.imcaID] = {
        ...current,
        currentSession: ics,
      };
      this.state.immediateCareByImcsID[ics.imcsID] = ics.imcaID;
    }
  }
  doctorProfileLoaded(docs: DoctorPublicProfile[]) {
    docs.forEach((doc) => {
      this.state.doctorProfileByID[doc.doctID] = doc;
    });
  }
}

export const [Reducer, actions] = hen(new ImmediateCares(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.immediateCare;

export const getListImmediateCare = createSelector([mainSelector], (state) => {
  return {
    list: Object.keys(state.immediateCareByID)
      .map((imcaID) => state.immediateCareByID[imcaID])
      .filter((ic) => !!ic) as ImmediateCareView[],
  };
});

export const getOneImmediateCare = (props: { imcaID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      immediateCare: state.immediateCareByID[props.imcaID],
    };
  });

export const getOneImmediateCareSession = (props: { imcsID: string }) =>
  createSelector(mainSelector, (state) => {
    const imcaID = state.immediateCareByImcsID?.[props.imcsID] ?? "";
    return {
      immediateCare: state.immediateCareByID?.[imcaID],
    };
  });

export const getDoctorProfile = (props: { doctID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      doctor: state.doctorProfileByID[props.doctID],
    };
  });

// Actions
export function loadImmediateCare(
  filter?: FilterImmediateCare
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return listImmediateCare(apiToken, filter)
      .then((r) => {
        dispatch(actions.immediateCaresLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneImmediateCare(imcaID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return getImmediateCare(apiToken, imcaID)
      .then((r) => {
        dispatch(actions.immediateCareLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedImmediateCare(
  imcaID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const icExist = Boolean(state.immediateCare.immediateCareByID[imcaID]);
    if (icExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneImmediateCare(imcaID));
  };
}

export function loadCachedImmediateCareByImcsID(
  imcsID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const icExist = Boolean(state.immediateCare.immediateCareByImcsID[imcsID]);
    if (icExist) {
      return Promise.resolve();
    }
    return dispatch(loadImmediateCare({ imcsID }));
  };
}

export function startImmediateCareSession(
  form: ImmediateCareSessionForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createImmediateCareSession(apiToken, form)
      .then((r) => {
        dispatch(actions.immediateCareSessionLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Sessão iniciada",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function viewImmediateCareSession(
  imcsID: string
): AppThunk<Promise<void>> {
  return async (_dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createImmediateCareSessionView(apiToken, imcsID)
      .then(() => {})
      .catch((e) => {
        console.warn(e);
        throw e;
      });
  };
}

export function entryToImmediateCare(imcsID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return entryToImmediateCareSession(apiToken, imcsID)
      .then(() => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Plantão iniciado",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function exitToImmediateCare(imcsID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return exitToImmediateCareSession(apiToken, imcsID)
      .then(() => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Plantão finalizado",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function finishOneImmediateCareSession(
  imcsID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return finishImmediateCareSession(apiToken, imcsID)
      .then((r) => {
        dispatch(actions.immediateCareSessionLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Sessão finalizada",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadDoctorProfile(doctID: string): AppThunk<Promise<void>> {
  return async (dispatch, _getState) => {
    return searchDoctorProfiles({ doctID })
      .then((r) => {
        dispatch(actions.doctorProfileLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedDoctorProfile(
  doctID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const exist = Boolean(state.immediateCare.doctorProfileByID[doctID]);
    if (exist) {
      return Promise.resolve();
    }
    return dispatch(loadDoctorProfile(doctID));
  };
}

export function callCareRecipient(
  imcsID: string,
  data: CareRecipient
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return callCareRecipientToImmediateCareSession(apiToken, imcsID, data)
      .then(() => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Chamando paciente...",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function cancelCall(imcsID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return cancelCallCareRecipient(apiToken, imcsID)
      .then(() => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Chamada finalizada",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function completeCare(
  imcsID: string,
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return completeCareToImmediateCareSession(apiToken, imcsID, appoID)
      .then(() => {})
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
