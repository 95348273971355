import * as React from "react";
import { connect } from "react-redux";
import { AppDispatch } from "ducks/state";
import { loadUserMe, getUserMe } from "ducks/user";
import SignatureBuilder, {
  DefaultOptions,
  BuilderProps,
} from "@udok/lib/components/SignatureBuilder";

export type EditUserProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof getUserMe> &
  Omit<BuilderProps, "defaultOptions">;

const EditUser = (props: EditUserProps) => {
  const { loadUserMe, currentUser, ...others } = props;

  React.useEffect(() => {
    if (currentUser) {
      return;
    }
    loadUserMe();
  }, [loadUserMe, currentUser]);

  let opt = DefaultOptions({ width: others.width, height: others.height });
  opt.text.value = `${currentUser?.doctor?.name ?? ""}\n${
    currentUser?.doctor?.info?.about?.slice?.(0, 70) ?? ""
  }`;
  opt.backgroundColor = {
    visible: true,
    color: "#ffff",
  };

  return <SignatureBuilder defaultOptions={opt} {...others} />;
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loadUserMe: () => dispatch(loadUserMe()),
});

export default connect(getUserMe, mapDispatchToProps)(EditUser);
