import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  Patient,
  NonUdokPatient,
  PatientHistory,
  FilterPatientHistory,
  PatientControlledAuthentication,
  PatientContactInformation,
  PatientPlansHistory,
  PatientPersonalInformation,
  PatientWithStats,
  PatientHealthplanCard,
  FilterPatientHealthplanCard,
  DoctorNote,
  HistoryResourceType,
  PatientCRMInformation,
  PatientCRMInformationForm,
  RecordFlag,
  RecordFlagFilter,
  PatientRecordFlag,
  PatientGovernmentID,
  RecordFlagForm,
} from "@udok/lib/api/models";
import {
  fetchPatients,
  fetchPatient,
  PatientFilter,
  fetchPrescriptionPatients,
  fetchPatientHistory,
  fetchPatientControlledAuthentication,
  updateContactInformation,
  fetchContactInformation,
  fetchPatientPlansHistory,
  updatePersonalInformation,
  fetchPersonalInformation,
  createHealthplanCard,
  updateHealthplanCard,
  fetchHealthplanCards,
  deleteHealthplanCard,
  updateCRMInformation,
  fetchCRMInformation,
  fetchRecordFlags,
  fetchRecordFlag,
  deleteRecordFlag,
  createRecordFlag,
  updateRecordFlag,
  createPatientRecordFlag,
  fetchPatientRecordFlag,
  fetchPatientRecordFlags,
  deletePatientRecordFlag,
  searchPatients,
  patchContactInformation,
  patchPersonalInformation,
  updateGovernmentID,
} from "@udok/lib/api/patient";
import {
  createNewPatient,
  OnboardingPatientForm,
  OnboardingResponse,
} from "@udok/lib/api/auth";
import { format } from "@udok/lib/internal/util";
import { getToken, UNAUTHORIZED } from "./auth";
import { actions as DoctorNoteActions } from "ducks/doctorNote";
import { profileSelector } from "ducks/user";
import axios from "axios";
import moment from "moment";
import { Action } from "ducks";

export type InitialState = {
  patientByID: { [patiID: string]: Patient };
  patientContactInformationByID: {
    [patiID: string]: PatientContactInformation;
  };
  patientCRMInformationByID: {
    [patiID: string]: PatientCRMInformation;
  };
  patientByUserID: { [userID: string]: string };
  patientControlledAuthenticationByPatiID: {
    [patiID: string]: PatientControlledAuthentication;
  };
  patientWithStatsList: PatientWithStats[];
  filteredPatientList: string[];
  prescriptionPatients: NonUdokPatient[];
  patientHistoryByID: { [patiID: string]: PatientHistory[] };
  patientPlansHistoryByID: {
    [patiID: string]: PatientPlansHistory[] | undefined;
  };
  patientPersonalInformationByID: {
    [patiID: string]: PatientPersonalInformation;
  };
  healthplanCardByPatiID: {
    [patiID: string]: PatientHealthplanCard[] | undefined;
  };
  recordFlagByID: { [reflID: number]: RecordFlag | undefined };
  patientRecordFlagByPatiID: {
    [patiID: string]: PatientRecordFlag[] | undefined;
  };
};

// Reducers
const initialState: InitialState = {
  patientByID: {},
  patientContactInformationByID: {},
  patientCRMInformationByID: {},
  patientControlledAuthenticationByPatiID: {},
  patientByUserID: {},
  prescriptionPatients: [],
  filteredPatientList: [],
  patientHistoryByID: {},
  patientPlansHistoryByID: {},
  patientPersonalInformationByID: {},
  patientWithStatsList: [],
  healthplanCardByPatiID: {},
  recordFlagByID: {},
  patientRecordFlagByPatiID: {},
};

class PatientSlice extends Hen<InitialState> {
  patientLoaded(v: Patient) {
    this.state.patientByID[String(v.patiID)] = v;
    this.state.patientByUserID[String(v.userID)] = String(v.patiID);
  }
  patientsLoaded(v: PatientWithStats[], append?: boolean) {
    let filteredList: string[] = [];
    v.forEach((s) => {
      if (s.patiID) {
        filteredList = [...filteredList, s.patiID];
      }
      this.state.patientByID[String(s.patiID)] = s;
      this.state.patientByUserID[String(s.userID)] = String(s.patiID);
    });
    if (append) {
      this.state.filteredPatientList = Array.from(
        new Set([...this.state.filteredPatientList, ...filteredList])
      );
      this.state.patientWithStatsList = [
        ...this.state.patientWithStatsList.filter(
          (p) => !v.find((pp) => p.patiID === pp.patiID)
        ),
        ...v,
      ];
    } else {
      this.state.filteredPatientList = filteredList;
      this.state.patientWithStatsList = v;
    }
  }
  loadPatientAutocomplete(data: Array<NonUdokPatient>) {
    this.state.prescriptionPatients = data;
  }
  patientHistoryLoaded(id: string, pat: PatientHistory[]) {
    this.state.patientHistoryByID[id] = pat;
  }
  patientPlansHistoryLoaded(id: string, pat: PatientPlansHistory[]) {
    this.state.patientPlansHistoryByID[id] = pat;
  }
  patientControlledAuthenticationLoaded(
    patiID: string,
    pat: PatientControlledAuthentication
  ) {
    this.state.patientControlledAuthenticationByPatiID[patiID] = pat;
  }
  patientContactInformationLoaded(v: PatientContactInformation) {
    this.state.patientContactInformationByID[String(v.patiID)] = v;
  }
  patientPersonalInformationLoaded(v: PatientPersonalInformation) {
    this.state.patientPersonalInformationByID[String(v.patiID)] = v;
  }
  patientCRMInformationLoaded(v: PatientCRMInformation) {
    this.state.patientCRMInformationByID[v.patiID] = v;
  }
  healthplanCardLoaded(hpc: PatientHealthplanCard) {
    let cardList = this.state.healthplanCardByPatiID[hpc.patiID] ?? [];
    const index = cardList.findIndex((hc) => hc.heplID === hpc.heplID);
    if (index === -1) {
      cardList = [...cardList, hpc];
    } else {
      cardList[index] = hpc;
    }
    this.state.healthplanCardByPatiID[hpc.patiID] = [...cardList];
  }
  healthplanCardDelete(hpc: PatientHealthplanCard) {
    let cardList = this.state.healthplanCardByPatiID[hpc.patiID] ?? [];
    const index = cardList.findIndex((hc) => hc.heplID === hpc.heplID);
    if (index !== -1) {
      cardList.splice(index, 1);
    }
    this.state.healthplanCardByPatiID[hpc.patiID] = [...cardList];
  }
  healthplanCardsLoaded(patiID: string, hpc: PatientHealthplanCard[]) {
    this.state.healthplanCardByPatiID[patiID] = hpc;
  }
  recordFlagsLoaded(flags: RecordFlag[]) {
    flags.forEach((flag) => {
      this.state.recordFlagByID[flag.reflID] = flag;
    });
  }
  recordFlagLoaded(flag: RecordFlag) {
    this.state.recordFlagByID[flag.reflID] = flag;
  }
  recordFlagRemoved(flag: RecordFlag) {
    delete this.state.recordFlagByID[flag.reflID];
  }
  patientRecordFlagLoaded(flag: PatientRecordFlag) {
    let patFlags = [
      ...(this.state.patientRecordFlagByPatiID[flag.patiID] ?? []),
    ];
    const index = patFlags.findIndex((pf) => pf.parfID === flag.parfID);
    if (index === -1) {
      patFlags = [...patFlags, flag];
    } else {
      patFlags[index] = flag;
    }
    this.state.patientRecordFlagByPatiID[flag.patiID] = patFlags;
  }
  patientRecordFlagsLoaded(flags: PatientRecordFlag[]) {
    let flagByPatiID: { [patiID: string]: PatientRecordFlag[] } = {};
    flags.forEach((f) => {
      flagByPatiID[f.patiID] = [...(flagByPatiID[f.patiID] ?? []), f];
    });
    Object.keys(flagByPatiID).forEach((patiID) => {
      this.state.patientRecordFlagByPatiID[patiID] = flagByPatiID[patiID];
    });
  }
  patientRecordFlagDelete(flag: PatientRecordFlag) {
    let patFlags = [
      ...(this.state.patientRecordFlagByPatiID[flag.patiID] ?? []),
    ];
    const index = patFlags.findIndex((pf) => pf.parfID === flag.parfID);
    if (index !== -1) {
      patFlags.splice(index, 1);
    }
    this.state.patientRecordFlagByPatiID[flag.patiID] = [...patFlags];
  }
  governmentIDLoaded(gID: PatientGovernmentID) {
    let pat = { ...this.state.patientByID[gID.patiID] };
    if (!!pat && gID.documentType === "cpf") {
      pat.cpf = gID.documentNumber;
      this.state.patientByID[gID.patiID] = pat;
    }
  }
}

export const [Reducer, actions] = hen(new PatientSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
  [DoctorNoteActions.noteLoaded({} as any).type]: (
    state: InitialState,
    action: Action<string, DoctorNote[]>
  ) => {
    const note = action.payload[0];
    let list = state.patientHistoryByID[note.patiID] ?? [];
    const newNoteHistory: PatientHistory = {
      preview:
        note.text.length > 100
          ? `${note.text.substring(0, 100)}...`
          : note.text,
      resourceDate: note.createdAt,
      resourceType: HistoryResourceType.note,
      resourceID: note.donoID,
      resourceOwnerID: note.doctID,
      resourceOwnerName: "",
      accountID: note.patiID,
      accountName: "",
    };
    const replacementIdx = list.findIndex(
      (ph: any) => ph.resourceID === note.replaceDonoID
    );
    if (replacementIdx !== -1) {
      list[replacementIdx] = newNoteHistory;
    } else {
      const hasDonoID =
        list.findIndex((ph: any) => ph.resourceID === note.donoID) > -1;
      if (!hasDonoID) {
        list = [newNoteHistory, ...list];
      }
    }
    state.patientHistoryByID[note.patiID] = list;
    return state;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.patient;
export const patientRepository = (state: RootState) =>
  mainSelector(state).patientByID;
export const patientRepositoryByUserID = (state: RootState) =>
  mainSelector(state).patientByUserID;
export const filteredListRepository = (state: RootState) =>
  mainSelector(state).filteredPatientList;
export const contactInformationRepository = (state: RootState) =>
  mainSelector(state).patientContactInformationByID;
export const personalInformationRepository = (state: RootState) =>
  mainSelector(state).patientPersonalInformationByID;
export const patientWithStatsListRepository = (state: RootState) =>
  mainSelector(state).patientWithStatsList;
export const healthplanCardRepository = (state: RootState) =>
  mainSelector(state).healthplanCardByPatiID;
export const patientPlansHistoryRepository = (state: RootState) =>
  mainSelector(state).patientPlansHistoryByID;
export const patientHistoryRepository = (state: RootState) =>
  mainSelector(state).patientHistoryByID;
export const patientCRMInformationRepository = (state: RootState) =>
  mainSelector(state).patientCRMInformationByID;
export const recordFlagRepository = (state: RootState) =>
  mainSelector(state).recordFlagByID;
export const patientRecordFlagRepository = (state: RootState) =>
  mainSelector(state).patientRecordFlagByPatiID;
const prescriptionPatientsRepository = (state: RootState) =>
  mainSelector(state).prescriptionPatients;

export const myRecordFlagList = createSelector(
  [recordFlagRepository, profileSelector],
  (recordFlagByID, profile) => {
    let list = Object.keys(recordFlagByID)
      .map((key) => {
        const reflID = parseInt(key);
        return recordFlagByID[reflID];
      })
      .filter((rf) => rf?.doctID === profile?.doctor?.doctID) as RecordFlag[];
    return { list };
  }
);

export const patientListView = createSelector(
  [filteredListRepository, patientRepository],
  (filteredPatientList, patientByID) => {
    return {
      list: filteredPatientList.map((patiID) => patientByID[patiID]),
    };
  }
);

export const patientListSearch = (props: { patiID?: string }) =>
  createSelector(
    [filteredListRepository, patientRepository],
    (filteredPatientList, patientByID) => {
      let idList = filteredPatientList;
      if (props?.patiID && idList.indexOf(props?.patiID) === -1) {
        idList = [...idList, props.patiID];
      }
      return {
        list: idList.map((patiID) => patientByID[patiID])?.filter?.((p) => !!p),
      };
    }
  );

export const prescriptionPatientList = createSelector(
  [prescriptionPatientsRepository, filteredListRepository, patientRepository],
  (ppr, fpl, pat) => {
    const patients = ppr.map((p) => {
      const address = p?.address?.replace?.(/-/gm, "");
      const cep = address?.match?.(/[\d]{5}-?[\d]{3}/)?.[0]?.trim?.();
      const fullAdress = address?.split?.(/[\d]{5}-?[\d]{3}/);
      const cityAndState = fullAdress?.[1]?.split?.(",");
      const pati: Patient = {
        name: p.name,
        cpf: p?.cpf,
        info: {
          address: {
            cep,
            street: fullAdress?.[0]?.trim?.(),
            city: cityAndState?.[0]?.trim?.(),
            state: cityAndState?.[1]?.trim?.(),
          },
          phones: [{ phone: p?.phone ?? "", linkedApps: [] }],
          contactEmail: p.email,
        },
      };
      return pati;
    });
    return {
      list: fpl
        .map((patiID) => pat[patiID])
        .concat(patients)
        ?.filter?.((p) => !!p),
    };
  }
);

export const patientWithStatsListView = createSelector(
  [patientWithStatsListRepository, patientRecordFlagRepository],
  (patientWithStatsList, patientRecordFlagByPatiID) => {
    return {
      list: patientWithStatsList
        .map((pat) => {
          const dob = moment(pat.dateOfBirth, format.DASHUN);
          const age = dob.isValid() ? moment().diff(dob, "years") : undefined;
          const flags = patientRecordFlagByPatiID[pat?.patiID ?? ""];
          const phones = [...(pat.info?.phones ?? [])].sort((a, b) => {
            if (a.linkedApps.length > b.linkedApps.length) {
              return -1;
            }
            if (a.linkedApps.length === b.linkedApps.length) {
              return 0;
            }
            return 1;
          });
          return {
            ...pat,
            age,
            flags,
            info: {
              ...pat.info,
              phones,
            },
          } as PatientWithStats & { age?: number; flags?: PatientRecordFlag[] };
        })
        ?.filter?.((p) => !!p),
    };
  }
);

export const getPatients = createSelector(mainSelector, (state) => {
  const patients = Object.keys(state.patientByID).map((p) => {
    return state.patientByID[p];
  });
  return { patients, patientByUserID: state.patientByUserID };
});

export const getOnePatient = (props: { patiID?: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      patient: props?.patiID ? state.patientByID[props.patiID] : undefined,
    };
  });

export const getOnePatientControlledAuthentication = (
  state: RootState,
  props: { patiID?: string }
) =>
  createSelector(mainSelector, (state) => {
    return {
      authentication: props?.patiID
        ? state.patientControlledAuthenticationByPatiID[props.patiID]
        : undefined,
      patient: props?.patiID ? state.patientByID[props.patiID] : undefined,
      contactInformation: props?.patiID
        ? state.patientContactInformationByID[props.patiID]
        : undefined,
    };
  });

export const getPatientProfile = (props: { patiID?: string }) =>
  createSelector(
    [
      patientRepository,
      contactInformationRepository,
      personalInformationRepository,
      patientPlansHistoryRepository,
      patientCRMInformationRepository,
    ],
    (pr, pcr, ppr, pph, prr) => {
      const ci = props?.patiID ? pcr[props.patiID] : undefined;
      const pi = props?.patiID ? ppr[props.patiID] : undefined;
      const pcrm = props?.patiID ? prr[props.patiID] : undefined;
      const patient = props?.patiID ? pr[props.patiID] : undefined;
      const lastPlan = props?.patiID ? pph[props.patiID]?.[0] : undefined;
      return {
        personalInformation: {
          name: pi?.name || patient?.name,
          dateOfBirth: pi?.dateOfBirth || patient?.dateOfBirth,
          sex: pi?.sex || patient?.sex,
        },
        contactInformation: {
          email: ci?.email || patient?.info?.contactEmail,
          cpf: patient?.cpf,
          phones: ci?.phones || patient?.info?.phones,
          address: {
            street: ci?.address?.street || patient?.info?.address?.street,
            streetNumber:
              ci?.address?.streetNumber || patient?.info?.address?.streetNumber,
            cep: ci?.address?.cep || patient?.info?.address?.cep,
            city: ci?.address?.city || patient?.info?.address?.city,
            state: ci?.address?.state || patient?.info?.address?.state,
            neighborhood:
              ci?.address?.neighborhood || patient?.info?.address?.neighborhood,
            cityIdentifier:
              ci?.address?.cityIdentifier ||
              patient?.info?.address?.cityIdentifier,
          },
        },
        crmInformation: pcrm,
        patient,
        lastPlan,
      };
    }
  );

export const getPatientHistory = (props: { patiID: string }) =>
  createSelector([patientHistoryRepository], (patientHistoryByID) => {
    return {
      patientHistory:
        patientHistoryByID[props.patiID]?.filter?.((p) => !!p) ?? [],
    };
  });

export const getPatientPlansHistory = (
  state: RootState,
  props: { patiID: string }
) =>
  createSelector([patientPlansHistoryRepository], (patientPlansHistoryByID) => {
    return {
      plansHistory:
        patientPlansHistoryByID[props.patiID]?.filter?.((p) => !!p) ?? [],
    };
  });

export const getPatientHealthplanCards = (props: { patiID: string }) =>
  createSelector([healthplanCardRepository], (healthplanCardByPatiID) => {
    return {
      healthplanCards:
        healthplanCardByPatiID[props.patiID]?.filter?.((p) => !!p) ?? [],
    };
  });

export const getOneRecordFlag = (props: { reflID?: number }) =>
  createSelector(recordFlagRepository, (recordFlagByID) => {
    return {
      recordFlag: props?.reflID ? recordFlagByID[props.reflID] : undefined,
    };
  });

export const listRecordFlag = createSelector(
  recordFlagRepository,
  (recordFlagByID) => {
    const list = Object.keys(recordFlagByID)
      .map((reflID) => recordFlagByID[parseInt(reflID)])
      .filter((rf) => !!rf) as RecordFlag[];
    return {
      recordFlags: list,
    };
  }
);

export const getPatientRecordFlags = (props: { patiID?: string }) =>
  createSelector(patientRecordFlagRepository, (patientRecordFlagByPatiID) => {
    return {
      patientRecordFlags: patientRecordFlagByPatiID[props?.patiID ?? ""],
    };
  });

// Actions
export function loadOwnPatients(
  filter?: PatientFilter
): AppThunk<Promise<void | PatientWithStats[]>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const f = {
      ownPatient: true,
      ...filter,
    } as PatientFilter;
    return fetchPatients(apiToken, f)
      .then((r) => {
        dispatch(actions.patientsLoaded(r));
        return r;
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function searchPatientList(
  f: PatientFilter
): AppThunk<Promise<PatientWithStats[]>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return searchPatients(apiToken, f).then((r) => {
      dispatch(actions.patientsLoaded(r, true));
      return r;
    });
  };
}

export function loadOnePatient(patiID: string): AppThunk<Promise<Patient>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPatient(apiToken, patiID)
      .then((r) => {
        dispatch(actions.patientLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedPatient(patiID: string): AppThunk<Promise<Patient>> {
  return async (dispatch, getState) => {
    const state = getState();
    const patientExist: Patient | undefined = state.patient.patientByID[patiID];
    if (patientExist) {
      return Promise.resolve(patientExist);
    }
    return dispatch(loadOnePatient(patiID));
  };
}

export function loadPrescriptionPatients(filter?: {
  name?: string;
  cutOffDate?: boolean;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPrescriptionPatients(apiToken, filter)
      .then((r) => {
        dispatch(actions.loadPatientAutocomplete(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPatientHistory(
  patiID: string,
  filter?: FilterPatientHistory
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPatientHistory(apiToken, patiID, filter)
      .then((r) => {
        dispatch(actions.patientHistoryLoaded(patiID, r));
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPatientPlansHistory(
  patiID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPatientPlansHistory(apiToken, patiID)
      .then((r) => {
        dispatch(actions.patientPlansHistoryLoaded(patiID, r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createControlledPatient(
  p: OnboardingPatientForm
): AppThunk<Promise<OnboardingResponse | void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createNewPatient(p, apiToken)
      .then((r) => r)
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadControlledAuthentication(
  patiID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPatientControlledAuthentication(apiToken, patiID)
      .then((r) => {
        if (r) {
          dispatch(actions.patientControlledAuthenticationLoaded(patiID, r));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updatePatientContactInformation(
  p: PatientContactInformation
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateContactInformation(apiToken, p.patiID!, p)
      .then((r) => {
        dispatch(actions.patientContactInformationLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPatientContactInformation(
  patiID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchContactInformation(apiToken, patiID)
      .then((r) => {
        if (r) {
          dispatch(actions.patientContactInformationLoaded(r));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedPatientContactInformation(
  patiID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const patientExist = Boolean(
      state.patient.patientContactInformationByID[patiID]
    );
    if (patientExist) {
      return Promise.resolve();
    }
    return dispatch(loadPatientContactInformation(patiID));
  };
}

export function updatePatientCRMInformation(
  p: PatientCRMInformationForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateCRMInformation(apiToken, p.patiID!, p)
      .then((r) => {
        dispatch(actions.patientCRMInformationLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPatientCRMInformation(
  patiID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCRMInformation(apiToken, patiID)
      .then((r) => {
        if (r) {
          dispatch(actions.patientCRMInformationLoaded(r));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedPatientCRMInformation(
  patiID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const patientExist = Boolean(
      state.patient.patientCRMInformationByID[patiID]
    );
    if (patientExist) {
      return Promise.resolve();
    }
    return dispatch(loadPatientCRMInformation(patiID));
  };
}

export function updatePatientPersonalInformation(
  p: Partial<PatientPersonalInformation>
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updatePersonalInformation(apiToken, p)
      .then((r) => {
        dispatch(actions.patientPersonalInformationLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPatientPersonalInformation(
  patiID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPersonalInformation(apiToken, patiID)
      .then((r) => {
        if (r) {
          dispatch(actions.patientPersonalInformationLoaded(r));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedPatientPersonalInformation(
  patiID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const patientExist = Boolean(
      state.patient.patientPersonalInformationByID[patiID]
    );
    if (patientExist) {
      return Promise.resolve();
    }
    return dispatch(loadPatientPersonalInformation(patiID));
  };
}

export function createOneHealthplanCard(
  data: Partial<PatientHealthplanCard>
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createHealthplanCard(apiToken, data)
      .then((r) => {
        dispatch(actions.healthplanCardLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadHealthplanCards(
  patiID: string,
  filter?: FilterPatientHealthplanCard
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchHealthplanCards(apiToken, patiID, filter)
      .then((r) => {
        dispatch(actions.healthplanCardsLoaded(patiID, r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateOneHealthplanCard(
  data: Partial<PatientHealthplanCard>
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateHealthplanCard(apiToken, data)
      .then((r) => {
        dispatch(actions.healthplanCardLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function deleteOneHealthplanCard(
  patiID: string,
  phcaID: number
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteHealthplanCard(apiToken, patiID, phcaID)
      .then((r) => {
        dispatch(actions.healthplanCardDelete(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadRecordFlags(
  filter?: RecordFlagFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchRecordFlags(apiToken, filter)
      .then((r) => {
        dispatch(actions.recordFlagsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneRecordFlag(reflID: number): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchRecordFlag(apiToken, reflID)
      .then((r) => {
        dispatch(actions.recordFlagLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedRecordFlag(reflID: number): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const recordFlagExist = Boolean(state.patient.recordFlagByID[reflID]);
    if (recordFlagExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneRecordFlag(reflID));
  };
}

export function createOneRecordFlags(
  data: RecordFlagForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createRecordFlag(apiToken, data)
      .then((r) => {
        dispatch(actions.recordFlagLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateOneRecordFlags(
  reflID: number,
  data: RecordFlagForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateRecordFlag(apiToken, reflID, data)
      .then((r) => {
        dispatch(actions.recordFlagLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function deleteOneRecordFlag(reflID: number): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteRecordFlag(apiToken, reflID)
      .then((r) => {
        dispatch(actions.recordFlagRemoved(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createOnePatientRecordFlag(data: {
  patiID?: string;
  reflID: number;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createPatientRecordFlag(apiToken, data)
      .then((r) => {
        dispatch(actions.patientRecordFlagLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPatientRecordFlag(parfID: number): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPatientRecordFlag(apiToken, parfID)
      .then((r) => {
        dispatch(actions.patientRecordFlagLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPatientRecordFlags(
  patiID?: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchPatientRecordFlags(apiToken, { patiID })
      .then((r) => {
        dispatch(actions.patientRecordFlagsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function deleteOnePatientRecordFlag(
  parfID: number
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deletePatientRecordFlag(apiToken, parfID)
      .then((r) => {
        dispatch(actions.patientRecordFlagDelete(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function patchPatientPersonalInformation(formData: {
  patiID: string;
  name?: string;
  dateOfBirth?: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return patchPersonalInformation(apiToken, formData)
      .then((r) => {
        dispatch(actions.patientPersonalInformationLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function patchPatientContactInformation(
  formData: Partial<PatientContactInformation>
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return patchContactInformation(apiToken, formData)
      .then((r) => {
        dispatch(actions.patientContactInformationLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updatePatientGovernmentID(formData: {
  patiID: string;
  documentType: string;
  documentNumber: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateGovernmentID(apiToken, formData)
      .then((r) => {
        dispatch(actions.governmentIDLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
