import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { Specialty, SpecialtyFilter } from "@udok/lib/api/models";
import {
  fetchSpecialties,
  fetchSpecialty,
  removeSpecialty,
  createSpecialty,
  fetchDoctorSpecialtys,
} from "@udok/lib/api/specialty";
import { getToken, UNAUTHORIZED } from "./auth";

export type InitialState = {
  specialtyByID: { [specID: string]: Specialty };
  doctorSpecialty: string[];
  filteredSpecialties: string[];
  clinicSpecialty: { [clinID: string]: string[] | undefined };
};

//Reducers
const initialState: InitialState = {
  specialtyByID: {},
  filteredSpecialties: [],
  doctorSpecialty: [],
  clinicSpecialty: {},
};

class SpecialtySlice extends Hen<InitialState> {
  loadSpecialty(spec: Specialty) {
    const specID =
      typeof spec.specID === "number" ? spec.specID.toString() : spec.specID;
    this.state.specialtyByID[specID] = spec;
  }
  loadSpecialties(s: Specialty[], clinID?: string) {
    const specialtys = s.map((spec) => {
      const specID =
        typeof spec.specID === "number" ? spec.specID.toString() : spec.specID;
      this.state.specialtyByID[specID] = spec;
      return specID;
    });
    this.state.filteredSpecialties = specialtys;
    if (clinID) {
      this.state.clinicSpecialty[clinID] = specialtys;
    }
  }
  loadRemoveSpecialty(spec: Specialty) {
    const ind = this.state.doctorSpecialty.findIndex(
      (specID) => specID === String(spec.specID)
    );
    if (ind !== -1) {
      this.state.doctorSpecialty.splice(ind, 1);
    }
  }
  loadDoctorSpecialty(s: Specialty[]) {
    this.state.doctorSpecialty = s.map((spec) => {
      const specID =
        typeof spec.specID === "number" ? spec.specID.toString() : spec.specID;
      this.state.specialtyByID[specID] = spec;
      return specID;
    });
  }
}

export const [Reducer, actions] = hen(new SpecialtySlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.specialty;

function sortSpecialties(a: Specialty, b: Specialty) {
  if (a.order > b.order) {
    return 1;
  }
  if (a.order < b.order) {
    return -1;
  }
  return a.name > b.name ? 1 : -1;
}

export const specialtyListView = createSelector([mainSelector], (state) => {
  return {
    list: Object.keys(state.specialtyByID)
      .map((sescID) => state.specialtyByID[sescID]!)
      .sort(sortSpecialties),
    filteredList: state.filteredSpecialties
      .map((sescID) => state.specialtyByID[sescID]!)
      .sort(sortSpecialties),
  };
});

export const getSpecialty = (props: { specID?: string }) =>
  createSelector([mainSelector], (state) => {
    return {
      specialty: state.specialtyByID[props?.specID ?? ""],
    };
  });

export const doctorSpecialtyListView = createSelector(
  [mainSelector],
  (state) => {
    return {
      list: state.doctorSpecialty
        .map((sescID) => state.specialtyByID[sescID]!)
        .sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          return a.name > b.name ? 1 : -1;
        }),
    };
  }
);

export const clinicSpecialtyListView = (
  state: RootState,
  props: { clinID?: string }
) =>
  createSelector([mainSelector], (state) => {
    return {
      clinicSpecialtys: (state.clinicSpecialty[props?.clinID ?? ""] ?? [])
        .map((sescID) => state.specialtyByID[sescID]!)
        .sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }
          if (a.order < b.order) {
            return -1;
          }
          return a.name > b.name ? 1 : -1;
        }),
    };
  });

//Actions
export function fetchOneSpecialty(specID: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return fetchSpecialty(specID)
      .then((r) => {
        dispatch(actions.loadSpecialty(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e?.response?.data?.error || e).message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedSpecialty(specID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const specialtyExist = state.specialty.specialtyByID[specID];
    if (!!specialtyExist) {
      return Promise.resolve();
    }
    return dispatch(fetchOneSpecialty(specID));
  };
}

export function fetchAllSpecialties(
  filter?: SpecialtyFilter
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return fetchSpecialties(filter)
      .then((r) => {
        dispatch(actions.loadSpecialties(r, filter?.clinID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e?.response?.data?.error || e).message,
          })
        );
        throw e;
      });
  };
}

export function deleteDoctorSpecialtys(
  specID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const doctID = state.user?.myProfile?.doctor?.doctID ?? "";
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return removeSpecialty(apiToken, doctID, specID)
      .then((r) => {
        dispatch(actions.loadRemoveSpecialty(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e?.response?.data?.error || e).message,
          })
        );
        throw e;
      });
  };
}

export function createSpecialtiesDoctor(
  specID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const doctID = state.user?.myProfile?.doctor?.doctID ?? "";
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createSpecialty(apiToken, doctID, specID)
      .then((r) => {
        dispatch(actions.loadSpecialty(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e?.response?.data?.error || e).message,
          })
        );
        throw e;
      });
  };
}

export function fetchAllDoctorSpecialtys(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const doctID = state.user.myProfile?.doctor?.doctID ?? "";
    return fetchDoctorSpecialtys(apiToken, doctID)
      .then((r) => {
        dispatch(actions.loadDoctorSpecialty(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e?.response?.data?.error || e).message,
          })
        );
        throw e;
      });
  };
}
