import React from "react";
import Stepper, {
  StepperProps,
  MobileStepper,
  StepItem,
  StepStatus,
} from "@udok/lib/components/Stepper";
import Dialog from "@udok/lib/components/Dialog/ResponsiveDialog";
import DialogTitle from "@udok/lib/components/Dialog/Title";
import TabPanel from "@udok/lib/components/TabPanel";
import { OnboardingStepKey } from "@udok/lib/app/doctor";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import { Divider, DialogContent, useMediaQuery } from "@material-ui/core";
import ProfileStep from "containers/OnboardingStepper/ProfileStep";
import LocationStep from "containers/OnboardingStepper/LocationStep";
import ScheduleStep from "containers/OnboardingStepper/ScheduleStep";
import SuccessStep from "containers/OnboardingStepper/SuccessStep";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepRoot: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      position: "relative",
      marginTop: theme.spacing(0.5),
    },
    stepContainer: {
      position: "absolute",
      transform: "translateY(-4%)",
      right: 0,
      left: 0,
    },
    stapCardView: {
      marginTop: 24,
      minHeight: 435,
      display: "flex",
      flexDirection: "column",
      padding: "5.62rem 1.5rem 1.5rem 1.5rem",
    },
    dialogTitle: {
      alignItems: "center",
      flexWrap: "nowrap",
    },
    oneLineText: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      height: "fit-content",
    },
  })
);

const ResponsiveStepper = (props: StepperProps & { onClose?: () => void }) => {
  const { children, onClose, ...otherProps } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  if (isSmallerScreen) {
    return <MobileStepper {...props} showTitle={true} />;
  }

  return (
    <>
      <DialogTitle
        id="form-dialog-title"
        onClose={onClose}
        className={classes.dialogTitle}
        titleTypographyProps={{ className: classes.oneLineText }}
      >
        Atualizar cadastro
      </DialogTitle>
      <DialogContent>
        <div className={classes.stepRoot}>
          <div className={classes.stepContainer}>
            <Stepper
              {...otherProps}
              showCardInfo={false}
              showConnector={true}
            />
          </div>
          <div className={classes.stapCardView}>
            <Divider light variant="fullWidth" />
            <>{children}</>
          </div>
        </div>
      </DialogContent>
    </>
  );
};

export type OnboardingStepperProps = {
  open: boolean;
  close: (completedSteps: boolean) => void;
  listSteps: OnboardingStepKey[];
  steps: StepItem[];
  sescID?: string;
  locaID?: string;
};

const OnboardingStepper = (props: OnboardingStepperProps) => {
  const {
    open,
    close,
    listSteps = [],
    sescID,
    steps: propSteps,
    locaID: propsLocaID,
  } = props;
  const [activeStep, setActive] = React.useState(0);
  const [steps, setSteps] = React.useState(propSteps ?? []);
  const [locaID, setLocaID] = React.useState(propsLocaID);
  const step = React.useMemo(
    () => steps?.[activeStep] as StepItem | undefined,
    [steps, activeStep]
  );

  React.useEffect(() => {
    setSteps(propSteps ?? []);
  }, [propSteps]);

  React.useEffect(() => {
    setLocaID(propsLocaID);
  }, [propsLocaID]);

  const handleNext = React.useCallback(() => {
    setActive((active) => {
      let next = active + 1;
      setSteps((s) => {
        const newSteps = [...s];
        newSteps[active].state = StepStatus.completed;
        newSteps[active].disabled = false;
        if (next < newSteps.length) {
          if (newSteps[next].state !== StepStatus.completed) {
            newSteps[next].state =
              next === newSteps.length - 1
                ? StepStatus.completed
                : StepStatus.active;
          }
          newSteps[next].disabled = false;
        } else {
          next = active;
        }
        return newSteps;
      });
      return next;
    });
  }, []);

  const handleCompletStep = React.useCallback((step: number) => {
    setSteps((s) => {
      const newSteps = [...s];
      if (step < newSteps.length) {
        newSteps[step].state = StepStatus.completed;
      }
      return newSteps;
    });
  }, []);

  const handleCompletActiveStep = React.useCallback(() => {
    handleCompletStep(activeStep);
  }, [activeStep, handleCompletStep]);

  const handleClose = React.useCallback(() => {
    const completedSteps =
      steps.filter((s) => s.state !== StepStatus.completed).length === 0;
    close(completedSteps);
  }, [close, steps]);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <ResponsiveStepper
        value={activeStep}
        steps={steps}
        showCardInfo={false}
        showConnector={true}
        onClose={handleClose}
        handleClick={setActive}
      >
        <TabPanel
          value={listSteps[activeStep]}
          index={OnboardingStepKey.profile}
        >
          <ProfileStep
            step={step}
            handleNext={handleNext}
            handleComplet={handleCompletActiveStep}
          />
        </TabPanel>
        <TabPanel
          value={listSteps[activeStep]}
          index={OnboardingStepKey.address}
        >
          <LocationStep
            step={step}
            handleNext={handleNext}
            handleComplet={(locaID) => {
              setLocaID(locaID);
              handleCompletActiveStep();
            }}
          />
        </TabPanel>
        <TabPanel
          value={listSteps[activeStep]}
          index={OnboardingStepKey.schedule}
        >
          <ScheduleStep
            sescID={sescID}
            locaID={locaID}
            step={step}
            handleNext={handleNext}
            handleComplet={handleCompletActiveStep}
          />
        </TabPanel>
        <TabPanel
          value={listSteps[activeStep]}
          index={OnboardingStepKey.successAlert}
        >
          <SuccessStep onFinishing={handleClose} />
        </TabPanel>
      </ResponsiveStepper>
    </Dialog>
  );
};

export default OnboardingStepper;
