import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "ducks/state";
import {
  getNote,
  getPatientNote,
  loadCachedDoctorNote,
  getAppointmentNote,
  loadDoctorNotes,
} from "ducks/doctorNote";

export const useGetNote = (donoID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (donoID.trim()) {
      setLoading(true);
      dispatch(loadCachedDoctorNote(donoID)).finally(() => {
        setLoading(false);
      });
    }
  }, [donoID, dispatch]);

  const getHistory = React.useCallback(getNote({ donoID }), [donoID]);
  const { note } = useSelector(getHistory);

  return [loading, note] as [typeof loading, typeof note];
};

export const useGetAppointmentNote = (appoID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(loadDoctorNotes({ appoID }))
      .catch(console.warn)
      .finally(() => {
        setLoading(false);
      });
  }, [appoID, dispatch]);

  const getAppoNote = React.useCallback(getAppointmentNote({ appoID }), [
    appoID,
  ]);
  const { appointmentNote } = useSelector(getAppoNote);
  return [loading, appointmentNote] as [typeof loading, typeof appointmentNote];
};

export const useGetPatientNote = (patiID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(loadDoctorNotes({ patiID }))
      .catch(console.warn)
      .finally(() => {
        setLoading(false);
      });
  }, [patiID, dispatch]);

  const { note } = useSelector((state: RootState) =>
    getPatientNote(state, { patiID })(state)
  );

  return [loading, note] as [typeof loading, typeof note];
};
