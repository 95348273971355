import React from "react";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import {
  getClientsStatus,
  userStatusView,
  getMessages,
  fetchMessages,
  fetchActivities,
  getConversationsView,
  fetchActivitiesGroup,
  getActivitiesGroupByMegrID,
} from "ducks/socket";
import { loadclinicUsersByClinID, listclinicUsers } from "ducks/schedule";
import {
  userByUserIDViewCreator,
  fetchCachedProfileForUserID,
} from "ducks/user";
import {
  ActiveChatUserContext,
  ChatQueryPrams,
} from "@udok/lib/components/UI/UIContext";
import { ChatActivity } from "@udok/lib/api/models";

export const useGetClientsStatus = (userID: string) => {
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    const t = setInterval(() => {
      dispatch(getClientsStatus([userID]));
    }, 30000);
    dispatch(fetchCachedProfileForUserID(userID));
    return () => {
      clearInterval(t);
    };
  }, [userID, dispatch]);

  const getStatus = React.useCallback(userStatusView({ userID }), [userID]);
  const getUserByUserID = React.useCallback(
    userByUserIDViewCreator({ userID }),
    [userID]
  );
  const { status } = useSelector(getStatus);
  const { user } = useSelector(getUserByUserID);

  const memoStatus = React.useMemo(() => status, [status]);
  const memoSrc = React.useMemo(
    () => `${process.env.REACT_APP_BASE_PATH}/files/${user?.avatar}`,
    [user]
  );
  const memoName = React.useMemo(
    () =>
      user?.patient?.name ??
      user?.doctor?.name ??
      user?.clinic?.name ??
      user?.email ??
      "",
    [user]
  );

  return [memoStatus, memoSrc, memoName, user] as [
    typeof memoStatus,
    typeof memoSrc,
    typeof memoName,
    typeof user
  ];
};

export const useListMessages = (userID: string, megrID?: string) => {
  const dispatch: AppDispatch = useDispatch();
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  const loadMessages = React.useCallback(
    (count = 1) => {
      if (count > 100) {
        return;
      }
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        dispatch(fetchMessages(userID, -1, megrID)).then((success: boolean) => {
          if (!success) {
            loadMessages(count + 1);
          }
        });
      }, 1000 * (count * 0.03));
    },
    [dispatch, userID, megrID]
  );

  React.useEffect(() => {
    dispatch(fetchMessages(userID, -1, megrID)).then((success: boolean) => {
      if (!success) {
        loadMessages(1);
      }
    });
  }, [dispatch, loadMessages, userID, megrID]);

  const get = React.useCallback(getMessages({ userID }), [userID]);
  const { listMessages = [], unreadMessages } = useSelector(get);

  return [listMessages, unreadMessages] as [
    typeof listMessages,
    typeof unreadMessages
  ];
};

export const useOpenChatByQueryPrams = () => {
  const { setChat } = React.useContext(ActiveChatUserContext);
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const userID = query.get(ChatQueryPrams.openChatWithUserID) ?? undefined;

  React.useEffect(() => {
    if (userID) {
      setChat({ userID: userID });
    }
  }, [userID, setChat]);
};

export const useConversationsWithUsers = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();
  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(fetchActivities()),
      dispatch(loadclinicUsersByClinID()),
    ]).finally(() => setLoading(false));
  }, [dispatch]);

  const { listActivities } = useSelector(getConversationsView);
  const { clinicUsers } = useSelector(listclinicUsers);
  const clu = clinicUsers.map((c) => {
    return {
      activeAt: "",
      messagePreview: "",
      unreadCount: 0,
      username: c.name,
      withUserID: c.userID,
      applID: c.applID,
    } as ChatActivity;
  });

  const list: typeof clu = [
    ...listActivities,
    ...clu.filter(
      (c) =>
        listActivities.findIndex((ac) => ac.withUserID === c.withUserID) === -1
    ),
  ];

  return [loading, list] as [typeof loading, typeof list];
};

export const useGetActivitiesGroupByMegrID = (megrID?: string) => {
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchActivitiesGroup({ megrID }));
  }, [dispatch, megrID]);

  const getActivities = React.useCallback(
    getActivitiesGroupByMegrID({ megrID }),
    [megrID]
  );
  const { activeGroups } = useSelector(getActivities);

  return [activeGroups] as [typeof activeGroups];
};
