import * as React from "react";
import { Dialog, DialogProps } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { useDialogUrlHash } from "@udok/lib/hooks/layout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      inset: "0px 0px 0px calc(100% - 800px)",
      [theme.breakpoints.down("sm")]: {
        left: 0,
      },
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export type DrawerDialogProps = {
  key?: string;
  closeOnGoBack?: boolean;
} & DialogProps;

const DrawerDialog = (props: DrawerDialogProps) => {
  const { open, onClose, closeOnGoBack, ...others } = props;
  const classes = useStyles();

  useDialogUrlHash(
    open,
    React.useCallback(() => {
      onClose?.({}, "backdropClick");
    }, [onClose]),
    !closeOnGoBack
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        {...others}
        fullScreen
        TransitionComponent={Transition}
        classes={{
          root: classes.dialog,
        }}
        style={{
          inset: undefined,
        }}
      />
    </div>
  );
};

export default DrawerDialog;
