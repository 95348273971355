import React from "react";
import { Typography, IconButton, useMediaQuery } from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import CardOffer from "@udok/lib/components/OffersViewer/CardOffer";
import * as Types from "@udok/lib/components/OffersViewer/Types";
import Icons from "@udok/lib/components/Icon";
import { getScrollbarWidth } from "@udok/lib/internal/util";
import clsx from "clsx";

export type Offer = Types.Offer;
export type CardOfferProps = Types.CardOfferProps;
export type OffersViewerProps = Types.OffersViewerProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      display: "flex",
      minHeight: "70vh",
      overflow: "auto",
      width: "fit-content",
      maxWidth: "100%",
      padding: theme.spacing(1, 1, 2),
      "& > *:nth-child(n+2)": {
        marginLeft: theme.spacing(2),
      },
    },
    emptyListMessage: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        alignItems: "center",
      },
    },
    scrollButtonContainer: {
      height: "100%",
      padding: theme.spacing(3),
    },
    scrollButton: {
      borderRadius: "50%",
      border: `1px solid`,
      padding: theme.spacing(0.75),
      position: "absolute",
      top: "50%",
    },
    hidden: { display: "none" },
  })
);
const OffersViewer = (props: OffersViewerProps) => {
  const { offers, cardOfferProps, onSelect } = props;
  const classes = useStyles();
  const contRef = React.useRef<HTMLDivElement>(null);
  const cardRef = React.useRef<Array<HTMLDivElement | null>>([]);

  React.useEffect(() => {
    const index = offers.findIndex((o) => o.recommended);
    const card = cardRef?.current?.[index];
    const container = contRef?.current;
    if (!!card && !!container) {
      const cardWidth = card!.getBoundingClientRect().width;
      const cardMargin = parseFloat(window.getComputedStyle(card).marginLeft);
      container.scrollTo({
        left: (cardWidth + cardMargin) * index - cardMargin * 2,
      });
    }
  }, [contRef, cardRef, offers]);

  return (
    <div className={classes.root}>
      <ScrollButtons container={contRef} cards={cardRef}>
        <div ref={contRef} className={classes.container}>
          {offers.map((offer, i) => (
            <CardOffer
              key={i}
              ref={(el) => (cardRef.current[i] = el)}
              offer={offer}
              onSelect={onSelect}
              {...cardOfferProps}
            />
          ))}
          {offers.length === 0 ? (
            <div className={classes.emptyListMessage}>
              <Typography variant="body1" color="textSecondary">
                Oops! Não encontramos nenhuma oferta disponível para assinatura.
              </Typography>
            </div>
          ) : null}
        </div>
      </ScrollButtons>
    </div>
  );
};

const ScrollButtons = ({
  container,
  cards,
  children,
}: {
  container: React.RefObject<HTMLDivElement>;
  cards: React.MutableRefObject<(HTMLDivElement | null)[]>;
  children: React.ReactNode;
}) => {
  const classes = useStyles();
  const [scrollOpen, setScorllOpen] = React.useState(false);
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const scrollbarWidth = getScrollbarWidth();

  React.useEffect(() => {
    if (!isSmallerScreen) {
      setScorllOpen(
        (container?.current?.scrollWidth ?? 0) >
          (container?.current?.clientWidth ?? 0)
      );
    }
  }, [isSmallerScreen, container]);

  const handlePrevious = React.useCallback(() => {
    const el = cards.current[0];
    if (el) {
      if (!el.parentElement) {
        return;
      }
      el.parentElement.scrollLeft =
        el.parentElement.scrollLeft -
        (el.getBoundingClientRect().width +
          parseFloat(window.getComputedStyle(el).marginRight));
    }
  }, [cards]);

  const handleNext = React.useCallback(() => {
    const el = cards.current[0];
    if (el) {
      if (!el.parentElement) {
        return;
      }
      el.parentElement.scrollLeft =
        el.parentElement.scrollLeft +
        (el.getBoundingClientRect().width +
          parseFloat(window.getComputedStyle(el).marginRight));
    }
  }, [cards]);

  return (
    <>
      <div
        className={clsx(classes.scrollButtonContainer, {
          [classes.hidden]: !scrollOpen,
        })}
      >
        <IconButton
          className={classes.scrollButton}
          style={{ left: theme.spacing(1) }}
          onClick={handlePrevious}
        >
          <Icons.ChevronLeft />
        </IconButton>
      </div>
      {children}
      <div
        className={clsx(classes.scrollButtonContainer, {
          [classes.hidden]: !scrollOpen,
        })}
      >
        <IconButton
          className={classes.scrollButton}
          style={{ right: theme.spacing(1) + scrollbarWidth }}
          onClick={handleNext}
        >
          <Icons.ChevronRight />
        </IconButton>
      </div>
    </>
  );
};

export default OffersViewer;
