import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import {
  shrinker,
  cpfNormalize,
  cnpjNormalize,
  validateCPF,
  validateCNPJ,
  emailValidation,
  phoneNormalizer,
  nameNormalize,
} from "@udok/lib/internal/util";
import { BillingInformationForm } from "@udok/lib/api/models";
import DocumentTypePicker, {
  BankDocumentType,
} from "components/DocumentTypePicker";
// @ts-ignore
import { formShape } from "rc-form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallFieldContainer: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        "& > *:nth-child(n+2)": {
          marginLeft: theme.spacing(2),
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        "& > *": {
          width: "100%",
        },
      },
    },
  })
);

export type InitialValues = Partial<
  Omit<BillingInformationForm, "billingAddress">
> & {
  documentType?: string;
};

export type BillingInformationFormProps = {
  form: formShape;
  initialValues?: InitialValues;
};

const FormPersonalInformation = (props: BillingInformationFormProps) => {
  const { initialValues, form } = props;
  const { getFieldProps, getFieldError, getFieldValue } = form;
  const classes = useStyles();

  const validateString = React.useCallback(
    (a: any, value: string, cb: (e?: string) => void) => {
      if (!value || !value.trim()) {
        cb("Campo obrigatório");
        return;
      }
      cb();
    },
    []
  );

  const validateEmail = React.useCallback(
    (a: any, value: string, cb: (e?: string) => void) => {
      if (!value || !value.trim()) {
        cb("Campo obrigatório");
        return;
      }
      if (!emailValidation(value)) {
        cb("Email invalido");
        return;
      }
      cb();
    },
    []
  );

  const validateDocumentNumber = React.useCallback(
    (r: any, val: any, cb: any) => {
      if (!val || !val.trim()) {
        cb("Campo obrigatório");
        return;
      }
      const type = getFieldValue("documentType");
      if (type === BankDocumentType.cpf && !validateCPF(val)) {
        cb("CPF inválido");
      }
      if (type === BankDocumentType.cnpj && !validateCNPJ(val)) {
        cb("CNPJ inválido");
      }
      cb();
    },
    [getFieldValue]
  );

  return (
    <>
      <TextField
        required
        fullWidth
        label="Nome completo"
        type="string"
        size="small"
        margin="dense"
        variant="outlined"
        error={Boolean(getFieldError("name"))}
        helperText={getFieldError("name")}
        InputProps={{ required: false }}
        {...getFieldProps("name", {
          rules: [validateString],
          initialValue: nameNormalize(initialValues?.name ?? ""),
          normalize: nameNormalize,
        })}
      />
      <TextField
        required
        fullWidth
        type="email"
        label="Email"
        size="small"
        margin="dense"
        variant="outlined"
        error={Boolean(getFieldError("email"))}
        helperText={getFieldError("email")}
        InputProps={{ required: false }}
        {...getFieldProps("email", {
          rules: [validateEmail],
          initialValue: initialValues?.email,
        })}
      />
      <TextField
        required
        fullWidth
        label="Telefone"
        size="small"
        margin="dense"
        variant="outlined"
        placeholder="(__) _____-____"
        InputProps={{ required: false }}
        error={Boolean(getFieldError("phone"))}
        helperText={getFieldError("phone")}
        {...getFieldProps("phone", {
          rules: [validateString],
          initialValue:
            (initialValues?.billingPhone?.ddd ?? "") +
            (initialValues?.billingPhone?.number ?? ""),
          normalize: phoneNormalizer,
        })}
      />
      <div className={classes.smallFieldContainer}>
        <DocumentTypePicker
          label="Tipo de documento"
          size="small"
          margin="dense"
          error={getFieldError("documentType")}
          {...getFieldProps("documentType", {
            rules: [validateString],
            initialValue: initialValues?.documentType ?? BankDocumentType.cpf,
          })}
        />
        <TextField
          required
          type="text"
          label="Número do documento"
          size="small"
          margin="dense"
          variant="outlined"
          InputProps={{ required: false }}
          error={Boolean(getFieldError("documentNumber"))}
          helperText={getFieldError("documentNumber") as string}
          InputLabelProps={{
            ...shrinker(getFieldValue("documentNumber") as string),
          }}
          {...getFieldProps("documentNumber", {
            rules: [validateDocumentNumber],
            initialValue: initialValues?.documentNumber ?? "",
            normalize:
              getFieldValue("documentType") === BankDocumentType.cpf
                ? cpfNormalize
                : cnpjNormalize,
          })}
        />
      </div>
    </>
  );
};

export default FormPersonalInformation;
