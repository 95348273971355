import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  Schedule,
  Appointment,
  AppointmentStatus,
  Clinic,
  Patient,
  ResourceType,
  NewAppointmentStatus,
  DocumentData,
  NewAppointment,
  AppointmentReschedule,
  FilterAppointmentReschedule,
  Healthplan,
  InvoiceView,
  AppointmentCollectDocumentPresentation,
  ScheduleLock,
  FilterScheduleLock,
  FilterAppointment,
  ScheduleLockView,
  ScheduleLockOrigin,
  ClinicFilter,
  AppointmentStatusData,
  AppointmentStatusResponse,
  ScheduleFilter,
  CreateScheduleLockForm,
  FilterClinicUser,
  ClinicUser,
  FilterHealthPlan,
  AppointmentRescheduleCreate,
  AppointmentRescheduleType,
  AppointmentReturnPending,
  AppointmentCommunication,
  DisplayAppointmentReview,
  FilterAppointmentReview,
  AppointmentFile,
  AppointmentFileForm,
  FilterAppointmentFile,
  FilterAttachment,
  ScheduleSlot,
  FilterScheduleSlots,
  EventsPerDay,
  FilterCalendarEvents,
  AppointmentCheckIn,
  FilterAppointmentCheckIn,
} from "@udok/lib/api/models";
import {
  fetchSchedules,
  fetchSchedule,
  updateSchedule,
  deleteSchedule,
  createSchedule,
  fetchCanceableAppointments,
  fetchAppointment,
  sendEditStatusAppointment,
  fetchclinic,
  sendEditAppointment,
  fetchAttachmentsByappoID,
  createAppointment,
  AppointmentEmailRequest,
  sendControlledAppointmentEmail,
  fetchAppointmentCollectDocument,
  createAppointmentReschedule,
  fetchAppointmentReschedule,
  fetchAppointmentReschedules,
  fetchHealthplans,
  fetchHealthplan,
  createScheduleLock,
  fetchListScheduleLock,
  deleteScheduleLock,
  fetchAppointmentStatus,
  fetchAppointsReturnPending,
  sendControlledAppointmentPhone,
  fetchAppointmentCommunication,
  sendAppointmentReview,
  fetchListAppointmentReview,
  fetchScheduleSlots,
  fetchCanceableCalendarEvents,
  createAppointmentCheckIn,
  fetchAppointmentCheckIns,
  fetchAppointmentCheckIn,
  deleteAppointmentCheckIn,
} from "@udok/lib/api/schedule";
import { fetchInvoicesByAppoID } from "@udok/lib/api/billing";
import { fetchDocument } from "@udok/lib/api/document";
import {
  createAppointmentFile,
  fetchAppointmentFile,
  deleteAppointmentFile,
} from "@udok/lib/api/appointmentsFile";
import { OnboardingPatientForm, OnboardingResponse } from "@udok/lib/api/auth";
import { fetchClinics } from "@udok/lib/api/clinic";
import { fetchClinicUsers } from "@udok/lib/api/user";
import { formatCalendarEvents } from "@udok/lib/app/schedule";
import { getToken, UNAUTHORIZED } from "./auth";
import { cacheSelector } from "./local";
import {
  loadCachedPatient,
  createControlledPatient,
  actions as patientActions,
  loadCachedPatientContactInformation,
  patientRepository as patientSelector,
  contactInformationRepository as patientContactInfoSelector,
} from "./patient";
import { locationByLocaID, fetchCachedLocation } from "ducks/location";
import {
  invoicesByIDSelector,
  actions as actionsBilling,
  refundInvoicePayment,
} from "./billing";
import { format } from "@udok/lib/internal/util";
import { getUserMe } from "./user";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type CalendarFilter = {
  patiID?: string;
  specID?: string;
};

export type InitialState = {
  scheduleByID: { [sescID: string]: Schedule | undefined };
  appointmentByID: { [appoID: string]: Appointment | undefined };
  appointmentCheckInByID: { [appoID: string]: AppointmentCheckIn | undefined };
  appointmentsByDay: { [day: string]: string[] | undefined };
  appoIDtByMegrID: { [megrID: string]: string | undefined };
  clinByID: { [clinID: string]: Clinic | undefined };
  clinicUsersByClinID: { [clinID: string]: ClinicUser[] | undefined };
  documentByID: { [docuID: string]: DocumentData };
  docuIDByAppoID: { [appoID: string]: string[] };
  collectDocAttachByID: {
    [apcdID: string]: AppointmentCollectDocumentPresentation;
  };
  collectDocAttachByAppoID: { [appoID: string]: string[] };
  calendarFilter: CalendarFilter;
  appoRescheduleByID: { [appoID: string]: AppointmentReschedule };
  healthplanByID: { [heplID: string]: Healthplan };
  doctorPlans: string[];
  invoicesByAppoID: { [invoID: string]: string[] };
  scheduleLockByID: { [id: string]: ScheduleLockView | undefined };
  listedScheduleLock: string[];
  appointmentStatusHistoryByID: {
    [appoID: string]: AppointmentStatusData[] | undefined;
  };
  appointmentReturnPending: {
    [patiID: string]: AppointmentReturnPending | undefined;
  };
  appointmentCommunicationByID: {
    [appoID: string]: AppointmentCommunication | undefined;
  };
  reviews: DisplayAppointmentReview[];
  appointmentFileByID: { [appoID: string]: AppointmentFile[] | undefined };
  slotsByDay: { [day: string]: ScheduleSlot[] | undefined };
  calendarEvents: { [day: string]: EventsPerDay | undefined };
};

// Reducers
const initialState: InitialState = {
  scheduleByID: {},
  appointmentByID: {},
  appointmentCheckInByID: {},
  appointmentsByDay: {},
  appoIDtByMegrID: {},
  clinByID: {},
  documentByID: {},
  docuIDByAppoID: {},
  calendarFilter: {},
  collectDocAttachByID: {},
  collectDocAttachByAppoID: {},
  appoRescheduleByID: {},
  healthplanByID: {},
  doctorPlans: [],
  invoicesByAppoID: {},
  scheduleLockByID: {},
  listedScheduleLock: [],
  appointmentStatusHistoryByID: {},
  clinicUsersByClinID: {},
  appointmentReturnPending: {},
  appointmentCommunicationByID: {},
  reviews: [],
  appointmentFileByID: {},
  slotsByDay: {},
  calendarEvents: {},
};

class ScheduleSlice extends Hen<InitialState> {
  scheduleLoaded(v: Schedule) {
    this.state.scheduleByID[String(v.sescID)] = v;
  }

  schedulesLoaded(v: Schedule[]) {
    v.forEach((s) => {
      this.state.scheduleByID[String(s.sescID)] = s;
    });
  }

  scheduleRemoved(v: Schedule) {
    delete this.state.scheduleByID[String(v.sescID)];
  }

  appointmentLoaded(v: Appointment) {
    this.state.appointmentByID[String(v.appoID)] = v;
    if (v?.megrID) {
      this.state.appoIDtByMegrID[v.megrID] = v.appoID;
    }
    const markedDate = moment.utc(v.markedAt).local().format(format.DASHUN);
    const current = this.state.appointmentsByDay?.[markedDate] ?? [];
    this.state.appointmentsByDay[markedDate] = Array.from(
      new Set([...current, v.appoID])
    );
  }
  appointmentStatusChange(s: AppointmentStatusResponse) {
    const statusData: AppointmentStatusData = {
      info: s.info,
      status: s.status,
      appoID: s.appoID,
      apstID: s.apstID,
      createdAt: s.createdAt,
      createdBy: s.createdBy,
      nameCreatedBy: s.nameCreatedBy ?? "",
      reasonDescription: s.reasonDescription,
      reason: "doctor",
    };
    const history = this.state.appointmentStatusHistoryByID[s.appoID] ?? [];
    this.state.appointmentByID[s.appoID] = {
      ...this.state.appointmentByID[s.appoID]!,
      status: s.status,
      statusInfo: s.info,
    };
    this.state.appointmentStatusHistoryByID[s.appoID] = [
      statusData,
      ...history,
    ];
  }
  appointmentsLoaded(v: Appointment[]) {
    v.forEach((s) => {
      this.state.appointmentByID[String(s.appoID)] = s;
      if (s?.megrID) {
        this.state.appoIDtByMegrID[s.megrID] = s.appoID;
      }
      const markedDate = moment.utc(s.markedAt).local().format(format.DASHUN);
      const current = this.state.appointmentsByDay?.[markedDate] ?? [];
      this.state.appointmentsByDay[markedDate] = Array.from(
        new Set([...current, s.appoID])
      );
    });
  }
  loadClinic(clinic: Clinic) {
    this.state.clinByID[clinic.clinID] = clinic;
  }
  loadClinics(clinics: Clinic[]) {
    clinics.forEach((c: Clinic) => {
      if (!this.state.clinByID[c.clinID]) {
        this.state.clinByID[c.clinID] = c;
      }
    });
  }
  calendarFilterMerged(f: CalendarFilter) {
    this.state.calendarFilter = { ...this.state.calendarFilter, ...f };
  }

  loadClinicsUsers(users: ClinicUser[]) {
    const clinicUsers = this.state.clinicUsersByClinID;
    users.forEach((us) => {
      const list = clinicUsers[us.clinID] ?? [];
      const index = list.findIndex((clu) => clu.userID === us.userID);
      if (index === -1) {
        clinicUsers[us.clinID] = [...list, us];
      } else {
        list[index] = us;
        clinicUsers[us.clinID] = list;
      }
    });
    this.state.clinicUsersByClinID = clinicUsers;
  }

  documentsLoaded(v: DocumentData[], appoID: string) {
    const appolist = this.state.docuIDByAppoID[appoID] ?? [];
    v.forEach((s) => {
      if (s) {
        const index = appolist.findIndex((ID) => ID === s.docuID);
        if (index === -1) {
          appolist.push(s.docuID);
        }
        this.state.documentByID[String(s.docuID)] = s;
      }
    });
    this.state.docuIDByAppoID[appoID] = appolist;
  }
  collectAppoDocumentsLoaded(
    v: AppointmentCollectDocumentPresentation[],
    appoID: string
  ) {
    const appolist = this.state.collectDocAttachByAppoID[appoID] ?? [];
    v.forEach((s) => {
      if (s) {
        const index = appolist.findIndex((ID) => ID === s.apcdID);
        if (index === -1) {
          appolist.push(s.apcdID);
        }
        this.state.collectDocAttachByID[String(s.apcdID)] = s;
      }
    });
    this.state.collectDocAttachByAppoID[appoID] = appolist;
  }

  appoRescheduleLoaded(r: AppointmentReschedule, isControlled?: boolean) {
    this.state.appoRescheduleByID[r.appoID] = r;
    if (isControlled) {
      let appo = this.state.appointmentByID[r.appoID];
      if (appo) {
        appo = {
          ...appo,
          markedAt: r.markedAt,
          status: AppointmentStatus.confirmedAndReschedule,
        };
        this.state.appointmentByID[r.appoID] = appo;
      }
    }
  }
  AppoReschedulesLoaded(res: AppointmentReschedule[]) {
    res.forEach((r) => {
      this.state.appoRescheduleByID[r.appoID] = r;
    });
  }
  healthplansLoaded(help: Healthplan[], doctID?: string) {
    help.forEach((h) => {
      this.state.healthplanByID[h.heplID] = h;
    });
    if (doctID) {
      this.state.doctorPlans = help.map((hp) => hp.heplID);
    }
  }
  healthplanLoaded(hp: Healthplan) {
    this.state.healthplanByID[String(hp.heplID)] = hp;
  }
  invoicesLoaded(v: InvoiceView[], appoID: string) {
    this.state.invoicesByAppoID[appoID] = v.map((s) => {
      return s.invoID;
    });
  }

  scheduleLockLoad(lock: ScheduleLockView) {
    this.state.scheduleLockByID[lock.id] = lock;
  }
  scheduleLockListLoad(locks: ScheduleLockView[]) {
    const list = locks.map((lock) => {
      this.state.scheduleLockByID[lock.id] = lock;
      return lock.id;
    });
    this.state.listedScheduleLock = list;
  }
  scheduleLockRemoved(lock: ScheduleLock) {
    delete this.state.scheduleLockByID[lock.scloID];
  }

  appointmentStatusLoaded(appoID: string, d: AppointmentStatusData[]) {
    const listStatus = d.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );
    const latestStatus = listStatus[0];
    if (this.state.appointmentByID[appoID]) {
      this.state.appointmentByID[appoID] = {
        ...(this.state.appointmentByID[appoID] as any),
        status: latestStatus.status,
        statusInfo: latestStatus.info,
      };
    }
    this.state.appointmentStatusHistoryByID[appoID] = listStatus;
  }

  pendingReturnLoaded(returns: AppointmentReturnPending[]) {
    returns.forEach((r) => {
      this.state.appointmentReturnPending[r.patiID] = r;
    });
  }
  appointmentCommunicationLoaded(ac: AppointmentCommunication) {
    this.state.appointmentCommunicationByID[ac.appoID] = ac;
  }

  reviewsLoaded(r: DisplayAppointmentReview[]) {
    this.state.reviews = r;
  }

  loadAppointmentFiles(files: AppointmentFile[]) {
    const apfByID = this.state.appointmentFileByID;
    files.forEach((file) => {
      const list = [...(apfByID?.[file.appoID] ?? []), file];
      apfByID[file.appoID] = list.filter(
        (v, i, a) => a.findIndex((n) => n.fileID === v.fileID) === i
      );
    });
    this.state.appointmentFileByID = apfByID;
  }

  loadAppointmentFile(file: AppointmentFile) {
    const apfByID = this.state.appointmentFileByID;
    const list = [...(apfByID?.[file.appoID] ?? []), file];
    apfByID[file.appoID] = list.filter(
      (v, i, a) => a.findIndex((n) => n.fileID === v.fileID) === i
    );
    this.state.appointmentFileByID = apfByID;
  }

  appointmentFileRemoved(file: AppointmentFile) {
    const list = [...(this.state.appointmentFileByID?.[file.appoID] ?? [])];
    const index = list.findIndex((item) => item.fileID === file.fileID);
    list.splice(index, 1);
    this.state.appointmentFileByID[file.appoID] = list;
  }

  scheduleSlotLoaded(date: string, slots: ScheduleSlot[]) {
    this.state.slotsByDay[date] = slots;
  }

  calendarEventsLoaded(events: EventsPerDay[]) {
    events.forEach((ev) => {
      this.state.calendarEvents[ev.date] = ev;
    });
  }

  clearInvoicesLoaded() {
    this.state.invoicesByAppoID = {};
  }

  appointmentCalendarLoaded(app: Appointment) {
    const day = moment(app.markedAt).format(format.DASHUN);
    const current = this.state.calendarEvents[day];
    const endAt = moment(app.markedAt)
      .add(app.appointmentDuration, "minute")
      .format(format.DATEHOUR);
    let appointments = [...(current?.appointments ?? [])];
    const index = appointments.findIndex((a) => a.appoID === app.appoID);
    const newAppointment = {
      endAt,
      appoID: app.appoID,
      startAt: app.markedAt,
      status: app.status,
      title: app.patiName ?? "",
      type: app.type,
      confirmations: app.confirmations,
    };
    if (index === -1) {
      appointments = [...appointments, newAppointment];
    } else {
      appointments[index] = newAppointment;
    }

    this.state.calendarEvents[day] = {
      date: day,
      disabled: current?.disabled ?? 0,
      markeds: (current?.markeds ?? 0) + 1,
      availables: current?.availables ?? 0,
      appointments,
    };
  }

  appointmentCheckInRemoved(checkIn: AppointmentCheckIn) {
    delete this.state.appointmentCheckInByID[checkIn.appoID];
  }

  appointmentCheckInLoaded(checkIn: AppointmentCheckIn) {
    this.state.appointmentCheckInByID[checkIn.appoID] = checkIn;
  }

  appointmentCheckInsLoaded(checkIns: AppointmentCheckIn[]) {
    checkIns.forEach((checkIn) => {
      this.state.appointmentCheckInByID[checkIn.appoID] = checkIn;
    });
  }
}

export const [Reducer, actions] = hen(new ScheduleSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.schedule;
export const plansSelector = (state: RootState) =>
  state.schedule.healthplanByID;
export const doctorPlansSelector = (state: RootState) =>
  state.schedule.doctorPlans;
export const calendarFilterSelector = (state: RootState) =>
  state.schedule.calendarFilter;
export const appointmentSelector = (state: RootState) =>
  state.schedule.appointmentByID;
export const appointmentsByDaySelector = (state: RootState) =>
  state.schedule.appointmentsByDay;
export const appoIDtByMegrIDSelector = (state: RootState) =>
  state.schedule.appoIDtByMegrID;
export const scheduleSelector = (state: RootState) =>
  state.schedule.scheduleByID;
const appointmentInvoiceSelector = (state: RootState) =>
  state.schedule.invoicesByAppoID;
const appoRescheduleSelector = (state: RootState) =>
  state.schedule.appoRescheduleByID;
export const clinicSelector = (state: RootState) => state.schedule.clinByID;
export const scheduleLockSelector = (state: RootState) =>
  state.schedule.scheduleLockByID;
export const listedScheduleLockSelector = (state: RootState) =>
  state.schedule.listedScheduleLock;
export const statusHistorySelector = (state: RootState) =>
  state.schedule.appointmentStatusHistoryByID;
export const clinicUsersByClinIDSelector = (state: RootState) =>
  state.schedule.clinicUsersByClinID;
const pendingReturnSelector = (state: RootState) =>
  state.schedule.appointmentReturnPending;
const appointmentCommunicationRepository = (state: RootState) =>
  mainSelector(state).appointmentCommunicationByID;
const appointmentReviewsRepository = (state: RootState) =>
  mainSelector(state).reviews;
export const appointmentFileSelector = (state: RootState) =>
  state.schedule.appointmentFileByID;
const slotsByDayRepository = (state: RootState) =>
  mainSelector(state).slotsByDay;
const calendarEventsRepository = (state: RootState) =>
  mainSelector(state).calendarEvents;
const invoicesByAppoIDRepository = (state: RootState) =>
  mainSelector(state).invoicesByAppoID;
const appointmentCheckInByIDRepository = (state: RootState) =>
  mainSelector(state).appointmentCheckInByID;

export const getSchedulesList = (props: { sescIDs: string[] }) =>
  createSelector([scheduleSelector], (scheduleByID) => {
    return {
      schedules: (props?.sescIDs ?? [])
        .map((sescID) => scheduleByID[sescID])
        .filter((s) => !!s) as Schedule[],
    };
  });

export const oneAppointmentCheckIn = (props: { appoID: string }) =>
  createSelector(
    [appointmentCheckInByIDRepository],
    (appointmentCheckInByID) => {
      return {
        CheckIn: appointmentCheckInByID[props.appoID],
      };
    }
  );

export const scheduleSlotByDay = (props: {
  //the date must be in the DASHUN format
  selectedDay: string;
}) =>
  createSelector([slotsByDayRepository], (slotsByDay) => {
    return {
      scheduleSlots: slotsByDay?.[props.selectedDay] ?? [],
    };
  });

export const getCalendarFilter = createSelector(
  [calendarFilterSelector],
  (calendarFilter) => {
    return { calendarFilter };
  }
);

export const getClinicSelector = createSelector(
  [clinicSelector],
  (clinByID) => ({
    clinByID,
  })
);

export const getAllAppointmentReview = createSelector(
  [appointmentReviewsRepository],
  (reviews) => {
    return { reviews };
  }
);

export const appointmentInvoicesView = (
  state: RootState,
  props: { appoID: string }
) =>
  createSelector(
    [mainSelector, invoicesByIDSelector],
    (state, invoicesByID) => {
      return {
        list:
          state.invoicesByAppoID[props.appoID]?.map?.(
            (invoID) => invoicesByID[invoID]
          ) ?? [],
      };
    }
  );

export const appointmentPaymentView = (props: { appoID: string }) =>
  createSelector(
    [appointmentSelector, appointmentInvoiceSelector, invoicesByIDSelector],
    (appointmentByID, invoicesByAppoID, invoicesByID) => {
      const { appoID } = props;
      const appointment = appointmentByID[appoID];
      const invoices =
        invoicesByAppoID[props.appoID]?.map?.(
          (invoID) => invoicesByID[invoID]
        ) ?? [];

      return {
        appointment,
        invoices,
      };
    }
  );

export const patientIDFilter = createSelector(
  [calendarFilterSelector],
  (state) => {
    return {
      value: state.patiID,
    };
  }
);

export const oneScheduleView = (props: { sescID: string }) =>
  createSelector([mainSelector, locationByLocaID], (state, locationByID) => {
    const schedule = state.scheduleByID[props.sescID];

    const clinic = schedule?.clinID
      ? state.clinByID[schedule?.clinID] ?? undefined
      : undefined;
    const location = schedule?.locaID
      ? locationByID[schedule.locaID]
      : undefined;

    return {
      schedule,
      clinic,
      location,
    };
  });

export const getClinic = (state: RootState, props: { clinID: string }) =>
  createSelector([clinicSelector], (clinByID) => {
    return {
      clinic: clinByID[props.clinID],
    };
  });

export const getAppointmentFile = (props: { appoID: string }) =>
  createSelector([appointmentFileSelector], (apfByID) => {
    return {
      files: apfByID[props.appoID],
    };
  });

export const appointmentCalendarView = (props: { daySelected: string }) =>
  createSelector([calendarEventsRepository], (events) => {
    const date = moment(props.daySelected, format.YEAMON);
    return {
      calendarEvents: formatCalendarEvents(date.format(format.DASHUN), events),
    };
  });

export const getSuggestView = (props: { appoID: string }) =>
  createSelector(
    [mainSelector, locationByLocaID, scheduleLockSelector],
    (state, locationByID, scheduleLockByID) => {
      const { appointmentByID } = state;
      const appointment = appointmentByID[props.appoID];

      const filterList = (item: Schedule) => {
        const clinID = appointment?.clinID;
        const type = appointment?.type;
        if (item.type !== type) {
          return false;
        }
        if (clinID) {
          return item.clinID === clinID ? true : false;
        } else if (item.clinID) {
          return false;
        }

        return !item.deletedAt;
      };

      const schedules = Object.keys(state.scheduleByID)
        .map((s) => {
          const shedule = state.scheduleByID[s];
          let clinicInfo = shedule?.clinID
            ? state.clinByID[shedule?.clinID]
            : undefined;
          let localeInfo = shedule?.locaID
            ? locationByID[shedule?.locaID]
            : undefined;
          return {
            ...shedule,
            clinicInfo,
            localeInfo,
          } as Schedule;
        })
        .filter((i) => filterList(i));

      const blockList = Object.keys(scheduleLockByID)
        .map((id) => scheduleLockByID[id])
        .filter((b) => !!b) as ScheduleLockView[];

      return {
        appointment,
        schedules,
        blockList,
      };
    }
  );

export const createControlledAppointmentView = createSelector(
  [scheduleSelector, locationByLocaID, scheduleLockSelector, clinicSelector],
  (scheduleByID, locationByID, scheduleLockByID, clinicByID) => {
    const schedules = Object.keys(scheduleByID)
      .map((s) => {
        const schedule = scheduleByID[s];
        let clinicInfo = schedule?.clinID
          ? clinicByID[schedule?.clinID]
          : undefined;
        const localeInfo = schedule?.locaID
          ? locationByID[schedule?.locaID]
          : undefined;
        return {
          ...schedule,
          clinicInfo,
          localeInfo,
        } as Schedule;
      })
      .filter((s) => {
        const isClinicBlock =
          !!s?.clinID &&
          !s?.clinicInfo?.displayPreferences?.enableDoctorScheduleBooking;
        return !s?.deletedAt && !isClinicBlock;
      });

    const blockList = Object.keys(scheduleLockByID)
      .map((id) => scheduleLockByID[id])
      .filter((b) => !!b) as ScheduleLockView[];

    return {
      schedules,
      blockList,
    };
  }
);

export const appointmentListView = createSelector(
  [appointmentSelector],
  (appointmentByID) => {
    return {
      list: Object.keys(appointmentByID)
        .map((appoID) => appointmentByID[appoID])
        .sort((a, b) =>
          moment(b?.markedAt ?? "").diff(moment(a?.markedAt ?? ""))
        )
        .filter((a) => !!a && !a?.readonly) as Appointment[],
    };
  }
);

export const oneAppointmentView = (
  state: RootState,
  props: { appoID: string }
) =>
  createSelector(
    [
      appointmentSelector,
      scheduleSelector,
      appointmentInvoiceSelector,
      appoRescheduleSelector,
      patientSelector,
      invoicesByIDSelector,
      patientContactInfoSelector,
    ],
    (
      appointmentByID,
      scheduleByID,
      invoicesByAppoID,
      appoRescheduleByID,
      patientByID,
      invoicesByID,
      patientContactInformation
    ) => {
      const appointment = appointmentByID[props.appoID];
      const schedule = appointment?.sescID
        ? (scheduleByID[appointment.sescID] as Schedule)
        : undefined;
      const patient = appointment?.patiID
        ? (patientByID[appointment?.patiID] as Patient)
        : null;
      let reschedule = props?.appoID
        ? appoRescheduleByID[props.appoID]
        : undefined;
      if (
        reschedule &&
        reschedule?.type !== AppointmentRescheduleType.anticipation
      ) {
        reschedule = undefined;
      }
      const invoices =
        invoicesByAppoID[props.appoID]?.map?.(
          (invoID) => invoicesByID[invoID]
        ) ?? [];
      const contactInformation = appointment?.patiID
        ? patientContactInformation[appointment?.patiID]
        : undefined;
      return {
        appointment,
        schedule,
        patient,
        anticipated: reschedule,
        invoices,
        contactInformation,
      };
    }
  );

export const oneAppointmentStatusHistoryView = (
  state: RootState,
  props: { appoID: string }
) =>
  createSelector([statusHistorySelector], (state) => {
    return {
      history: state[props.appoID],
    };
  });

export const getAppointmentByMegrID = (
  state: RootState,
  props: { megrID: string }
) =>
  createSelector(
    [appoIDtByMegrIDSelector, appointmentSelector],
    (appoIDtByMegrID, appointmentByID) => {
      const appoID = appoIDtByMegrID[props.megrID];
      const appointment = appoID ? appointmentByID[appoID] : undefined;
      return {
        appointment,
      };
    }
  );

export const getAllAppoAttachments = (
  state: RootState,
  props: { appoID?: string }
) =>
  createSelector([mainSelector], (state) => {
    const {
      docuIDByAppoID,
      documentByID,
      collectDocAttachByAppoID,
      collectDocAttachByID,
    } = state;
    const { appoID } = props;

    const listForms = appoID
      ? docuIDByAppoID?.[appoID]?.map((id) => documentByID[id]) ?? []
      : [];

    const listCollectDocu = appoID
      ? collectDocAttachByAppoID?.[appoID]?.map(
          (id) => collectDocAttachByID[id]
        ) ?? []
      : [];

    return {
      listForms,
      listCollectDocu,
    };
  });

export const getAppointment = (state: RootState, props: { appoID?: string }) =>
  createSelector([mainSelector], (state) => {
    const appointment = props?.appoID
      ? state.appointmentByID[props.appoID]
      : undefined;
    const anticipated = Boolean(
      props?.appoID
        ? state.appoRescheduleByID[props.appoID]?.type ===
            AppointmentRescheduleType.anticipation
        : undefined
    );
    return {
      appointment,
      anticipated,
    };
  });

export const getAppointmentVideoMeet = (
  state: RootState,
  props: { appoID?: string }
) =>
  createSelector(
    [getAppointment(state, props), getUserMe],
    (appointment, usr) => {
      return {
        ...appointment,
        currentUser: usr.currentUser,
      };
    }
  );

export const healthplanListView = createSelector(
  [plansSelector, doctorPlansSelector],
  (healthplanByID, doctorPlans) => {
    const allPlans = Object.keys(healthplanByID)
      .map((heplID) => healthplanByID[heplID])
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const docPlans = doctorPlans
      .map((heplID) => healthplanByID[heplID])
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    return {
      list: allPlans,
      doctorPlans: docPlans,
    };
  }
);

export const healthplanView = (state: RootState, props: { heplID: string }) =>
  createSelector(plansSelector, (healthplanByID) => {
    return {
      healthplan: healthplanByID[props.heplID],
    };
  });

export const getHealthplanByID = createSelector(
  plansSelector,
  (healthplanByID) => {
    return { healthplanByID };
  }
);

export const videoComposerView = (
  state: RootState,
  props: { appoID?: string; userID: string }
) =>
  createSelector(
    [appointmentCommunicationRepository, getToken, cacheSelector],
    (communicationByID, token, vcache) => {
      const communication = props?.appoID
        ? communicationByID[props.appoID]
        : undefined;
      return {
        viseID: communication?.viseID,
        ...token,
        availableViseoSession: vcache[props.userID],
      };
    }
  );

export const listSchedulesLock = createSelector(
  [scheduleLockSelector],
  (scheduleLockByID) => {
    const list = Object.keys(scheduleLockByID)
      .map((id) => {
        const scheduleLock = scheduleLockByID[id];
        if (!scheduleLock || scheduleLock?.origin !== ScheduleLockOrigin.lock) {
          return undefined;
        }
        return {
          ...scheduleLock,
          scloID: id,
        } as ScheduleLock;
      })
      .filter((b) => !!b)
      .sort((a, b) =>
        moment(b?.endAt).diff(moment(a?.endAt))
      ) as ScheduleLock[];

    return {
      list,
    };
  }
);

export const listDoctorSchedulesLocks = createSelector(
  [scheduleLockSelector],
  (scheduleLockByID) => {
    const list = Object.keys(scheduleLockByID)
      .map((id) => {
        const scheduleLock = scheduleLockByID[id];
        if (!scheduleLock || scheduleLock?.origin !== ScheduleLockOrigin.lock) {
          return undefined;
        }
        return {
          ...scheduleLock,
          scloID: id,
        } as ScheduleLock;
      })
      .filter((b) => !!b && !b?.clinID) as ScheduleLock[];

    return {
      list,
    };
  }
);

export const getSchedulesLock = (state: RootState, props: { id: string }) =>
  createSelector([scheduleLockSelector], (scheduleLockByID) => {
    return {
      scheduleLock: scheduleLockByID[props.id],
    };
  });

export const getAppointmentPreview = (props: { appoID: string }) =>
  createSelector(
    [appointmentSelector, scheduleSelector],
    (appointmentByID, scheduleByID) => {
      const appointment = appointmentByID[props.appoID];
      const schedule = appointment?.sescID
        ? (scheduleByID[appointment.sescID] as Schedule)
        : undefined;
      return {
        appointment,
        schedule,
      };
    }
  );

export const listclinicUsersByClinID = (
  state: RootState,
  props: { clinID: string }
) =>
  createSelector([clinicUsersByClinIDSelector], (clinicUsersByClinID) => {
    const clinicUsers = clinicUsersByClinID[props.clinID];
    return {
      clinicUsers,
    };
  });

export const listclinicUsers = createSelector(
  [clinicUsersByClinIDSelector],
  (clinicUsersByClinID) => {
    var clinicUsers: ClinicUser[] = [];
    Object.keys(clinicUsersByClinID).forEach((clinID) => {
      const clu = clinicUsersByClinID[clinID];
      clinicUsers = [...clinicUsers, ...(clu ?? [])];
    });
    return {
      clinicUsers,
    };
  }
);

export const appointmentPendingReturn = (props: { patiID: string }) =>
  createSelector([pendingReturnSelector], (pendingReturn) => {
    const patientPendingReturn = pendingReturn[props.patiID];
    return {
      patientPendingReturn,
    };
  });

export const getOneAppointmentView = (props: { appoID: string }) =>
  createSelector([appointmentSelector], (appointmentByID) => {
    const appointment = appointmentByID[props.appoID];
    return {
      appointment,
    };
  });

export const getOneAppointmentCommunication = (props: { appoID: string }) =>
  createSelector([appointmentCommunicationRepository], (communicationByID) => {
    return {
      communication: communicationByID[props.appoID],
    };
  });

export const getDoctorSchedules = createSelector(
  [scheduleSelector],
  (scheduleByID) => {
    return {
      list: (
        Object.keys(scheduleByID)
          .map((sescID) => scheduleByID[sescID])
          .filter((s) => !!s && !s.deletedAt && !s.clinID) as Schedule[]
      ).sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
    };
  }
);

export const getListedSchedulesLock = createSelector(
  [scheduleLockSelector, listedScheduleLockSelector],
  (scheduleLockByID, list) => {
    return {
      locks: list
        .map((scloID) => scheduleLockByID[scloID])
        .filter((b) => !!b) as ScheduleLockView[],
    };
  }
);

export const getAppointsByDay = (props: {
  //the date must be in the DASHUN format
  day: string;
}) =>
  createSelector(
    [appointmentsByDaySelector, appointmentSelector],
    (byDay, appointmentByID) => {
      return {
        appointments: (byDay?.[props.day] ?? [])
          .map((appoID) => appointmentByID[appoID])
          .filter((a) => !!a) as Appointment[],
      };
    }
  );

export const getInvoicesByAppoID = (props: { appoID: string }) =>
  createSelector(
    [invoicesByAppoIDRepository, invoicesByIDSelector],
    (invoicesByAppoID, invoicesByID) => {
      const { appoID } = props;
      return {
        invoices: (invoicesByAppoID?.[appoID] ?? [])
          .map((invoID) => invoicesByID[invoID])
          .filter((inv) => !!inv) as InvoiceView[],
      };
    }
  );

// Actions
export function loadAllSchedules(f?: ScheduleFilter): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchSchedules(apiToken, f)
      .then(async (r) => {
        dispatch(actions.schedulesLoaded(r));
        let clinics: string[] = [];
        let locations: string[] = [];
        r.forEach((sch) => {
          if (sch.clinID) {
            clinics = Array.from(new Set([...clinics, sch.clinID]));
          }
          if (sch.locaID) {
            locations = Array.from(new Set([...locations, sch.locaID]));
          }
        });
        await Promise.all([
          ...clinics.map((clinID) => dispatch(fetchCachedClinic(clinID))),
          ...locations.map((locaID) => dispatch(fetchCachedLocation(locaID))),
        ]);
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadSchedulesPresentation(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const minEndAt = moment().subtract(2, "day");
    return dispatch(loadAllSchedules({ "endAt[gte]": minEndAt.format() }));
  };
}

export function loadOneSchedule(sescID: string): AppThunk<Promise<Schedule>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchSchedule(apiToken, sescID)
      .then(async (r) => {
        dispatch(actions.scheduleLoaded(r));
        if (r?.clinID) {
          await dispatch(fetchCachedClinic(r.clinID));
        }
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createOneSchedule(
  schedule: Schedule,
  sn?: boolean
): AppThunk<Promise<Schedule>> {
  const successNotification = sn ?? true;
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createSchedule(apiToken, schedule)
      .then((r) => {
        dispatch(actions.scheduleLoaded(r));
        if (successNotification) {
          dispatch(
            newNotification("general", {
              status: "success",
              message: "Realizado com sucesso!",
            })
          );
        }
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateOneSchedule(schedule: Schedule): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateSchedule(apiToken, schedule)
      .then((r) => {
        dispatch(actions.scheduleLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeOneSchedule(
  sescID: string,
  sn?: boolean
): AppThunk<Promise<void>> {
  const successNotification = sn ?? true;
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteSchedule(apiToken, sescID)
      .then((r) => {
        dispatch(actions.scheduleRemoved(r));
        if (successNotification) {
          dispatch(
            newNotification("general", {
              status: "success",
              message: "Realizado com sucesso!",
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointments(
  f?: FilterAppointment
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCanceableAppointments(apiToken, f)
      .then((r) => {
        if (r) {
          dispatch(actions.appointmentsLoaded(r));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedAppointmentByMegrID(
  megrID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const appoIDtByMegrIDExist = Boolean(
      state.schedule.appoIDtByMegrID[megrID]
    );
    if (appoIDtByMegrIDExist) {
      return Promise.resolve();
    }
    return dispatch(loadAppointments({ megrID }));
  };
}

export function loadAppointment(appoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointment(apiToken, appoID)
      .then(async (r) => {
        dispatch(actions.appointmentLoaded(r));
        dispatch(actions.appointmentCalendarLoaded(r));
        dispatch(loadCachedPatientContactInformation(r.patiID));
        dispatch(loadCachedPatient(r.patiID));
        dispatch(fetchCachedSchedule(r.sescID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedAppointment(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const appointmentExist = Boolean(state.schedule.appointmentByID[appoID]);
    if (appointmentExist) {
      return Promise.resolve();
    }
    return dispatch(loadAppointment(appoID));
  };
}

//the date must be in the DASHUN format
export function fetchCachedAppointmentByDay(
  date: string,
  f?: FilterAppointment | undefined
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const list = state.schedule.appointmentsByDay?.[date] ?? [];
    if (list.length > 0) {
      return Promise.resolve();
    }
    const d = moment(date, format.DASHUN).local();
    return dispatch(
      loadAppointments({
        ...f,
        markedAtGte: moment.utc(d.startOf("day")).format(format.RFC3349),
        markedAtLte: moment.utc(d.endOf("day")).format(format.RFC3349),
      })
    );
  };
}

export function clearInvoicesByAppoID(): AppThunk<void> {
  return (dispatch) => {
    dispatch(actions.clearInvoicesLoaded());
  };
}

export function editStatusAppointment(
  appoID: string,
  n: NewAppointmentStatus
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    if (
      n.status === AppointmentStatus.cancel ||
      n.status === AppointmentStatus.canceledForReschedule
    ) {
      return Promise.reject();
    }
    return sendEditStatusAppointment(apiToken, appoID, n)
      .then((r) => {
        const resp = {
          ...r,
          nameCreatedBy: state.user?.myProfile?.doctor?.name,
        };
        dispatch(actions.appointmentStatusChange(resp));
        const msg = r?.info?.message
          ? "Mensagem postada"
          : r.status === AppointmentStatus.done
          ? "Agendamento concluido"
          : "Status alterado com sucesso!";

        dispatch(
          newNotification("general", {
            status: "success",
            message: msg,
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function copyNotification(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(
      newNotification("general", {
        status: "success",
        message: "Copiado com sucesso.",
      })
    );
  };
}

export function updateReturnConsultations(
  obj: {
    returnVisitEnabled: boolean;
  },
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    let newAppo = state.schedule.appointmentByID[appoID];
    if (newAppo) {
      newAppo = {
        ...newAppo,
        returnVisitEnabled: obj.returnVisitEnabled,
      };
      dispatch(actions.appointmentLoaded(newAppo));
    }
    return sendEditAppointment(apiToken, appoID, obj)
      .then((r) => {
        dispatch(actions.appointmentLoaded(r));
      })
      .catch((e) => {
        if (newAppo) {
          newAppo = {
            ...newAppo,
            returnVisitEnabled: !obj.returnVisitEnabled,
          };
          dispatch(actions.appointmentLoaded(newAppo));
        }
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e.response?.data?.error || e).message,
          })
        );
      });
  };
}

export function appointmentCancelAndRefund(
  appoID: string,
  status: {
    status: AppointmentStatus.cancel | AppointmentStatus.canceledForReschedule;
  } & Omit<NewAppointmentStatus, "status">,
  paymID?: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return sendEditStatusAppointment(apiToken, appoID, status)
      .then(async (s) => {
        const resp = {
          ...s,
          nameCreatedBy: state.user?.myProfile?.doctor?.name,
        };
        dispatch(actions.appointmentStatusChange(resp));
        if (paymID) {
          await dispatch(refundInvoicePayment(paymID));
        }
        const msgByStatus =
          s.status === AppointmentStatus.canceledForReschedule
            ? "Sugestão enviada"
            : "Agendamento cancelado";
        dispatch(
          newNotification("general", {
            status: "success",
            message: msgByStatus,
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAttachmentsByAppoID(
  appoID: string,
  filter?: FilterAttachment
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAttachmentsByappoID(apiToken, appoID, filter)
      .then(async (r) => {
        const atchs = r;
        const documents = await Promise.all(
          atchs
            .filter((att) => att.resource === ResourceType.document)
            .map((a) => {
              return fetchDocument(apiToken, a.resourceID);
            })
        );
        dispatch(actions.documentsLoaded(documents, appoID));

        const collectDocuments = await Promise.all(
          atchs
            .filter((att) => att.resource === ResourceType.collectDocument)
            .map((a) => {
              return fetchAppointmentCollectDocument(apiToken, a.resourceID);
            })
        );
        dispatch(actions.collectAppoDocumentsLoaded(collectDocuments, appoID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createPatientForControlledAppointment(
  patient: OnboardingPatientForm,
  section: string = "general"
): AppThunk<Promise<OnboardingResponse>> {
  return async (dispatch) => {
    return dispatch(createControlledPatient(patient))
      .then((p) => {
        if (!p) {
          throw new Error("Falha ao criar paciente");
        }
        const {
          user: { patient },
        } = p;
        dispatch(patientActions.patientLoaded(patient!));
        return p;
      })
      .catch((e) => {
        dispatch(
          newNotification(section, {
            status: "error",
            message: "Não foi possível criar a conta do paciente",
            temporary: true,
          })
        );
        return Promise.reject("Não foi possível criar a conta do paciente");
      });
  };
}

export function createControlledAppointment(
  newAppo: NewAppointment,
  section: string = "general",
  notifyByPhone?: boolean
): AppThunk<Promise<Appointment>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createAppointment(apiToken, newAppo)
      .then((r) => {
        dispatch(actions.appointmentLoaded(r));
        dispatch(actions.appointmentCalendarLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
        dispatch(
          loadAllScheduleLocks({
            startDate: moment(newAppo.markedAt).utc().format(format.DASHUN),
            endDate: moment(newAppo.markedAt)
              .add(1, "day")
              .utc()
              .format(format.DASHUN),
          })
        );
        if (r.appoID) {
          dispatch(
            sendControlledAppoEmail({
              appoID: r.appoID,
              sendTarget: "patient",
            })
          );
          if (!!r?.clinID && notifyByPhone) {
            dispatch(sendControlledAppoPhone(r.appoID));
          }
        }
        return r;
      })
      .catch((e) => {
        let message = e.message;
        let temporary = true;
        if (e.message.indexOf("schedule unavailable") > -1) {
          temporary = false;
          message = "Este horário não está disponível.";
          dispatch(
            loadAllScheduleLocks({
              startDate: moment(newAppo.markedAt).utc().format(format.DASHUN),
              endDate: moment(newAppo.markedAt)
                .add(1, "day")
                .utc()
                .format(format.DASHUN),
            })
          );
        }
        dispatch(
          newNotification(section, {
            status: "error",
            message,
            temporary,
          })
        );
        throw e;
      });
  };
}

export function sendControlledAppoEmail(
  mail: AppointmentEmailRequest
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return sendControlledAppointmentEmail(apiToken, mail)
      .then((r) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Email com instruções enviado ao paciente!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function sendControlledAppoPhone(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return sendControlledAppointmentPhone(apiToken, {
      appoID,
      sendTarget: "patient",
    })
      .then((r) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Mensagem com instruções enviado ao paciente!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message:
              "Não foi possível enviar uma mensagem ao telefone do paciente",
          })
        );
      });
  };
}

export function createOneAppointmentReschedule(
  newSchedule: AppointmentRescheduleCreate
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createAppointmentReschedule(apiToken, newSchedule)
      .then((r) => {
        dispatch(actions.appoRescheduleLoaded(r, newSchedule?.isControlled));
        if (!newSchedule?.isControlled) {
          dispatch(
            newNotification("general", {
              status: "success",
              message: "Solicitação enviada com sucesso!",
            })
          );
        } else {
          dispatch(
            newNotification("general", {
              status: "success",
              message: "Agendamento remarcado com sucesso!",
            })
          );
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneAppointmentReschedule(
  apscID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchAppointmentReschedule(apiToken, apscID)
      .then((r) => {
        dispatch(actions.appoRescheduleLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadAppointmentReschedules(
  f?: FilterAppointmentReschedule
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchAppointmentReschedules(apiToken, f)
      .then((r) => {
        dispatch(actions.AppoReschedulesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadHealthplans(f?: FilterHealthPlan): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return fetchHealthplans(f)
      .then((r) => {
        dispatch(actions.healthplansLoaded(r, f?.doctID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadInvoicesByAppoID(appoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchInvoicesByAppoID(apiToken, appoID)
      .then(async (r) => {
        dispatch(actions.invoicesLoaded(r, appoID));
        dispatch(actionsBilling.invoicesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedSchedule(
  sescID: string
): AppThunk<Promise<Schedule>> {
  return async (dispatch, getState) => {
    const state = getState();
    const schedule = state.schedule.scheduleByID[sescID];
    if (!!schedule) {
      return Promise.resolve(schedule);
    }
    return dispatch(loadOneSchedule(sescID));
  };
}

export function fetchScheduleLocation(sescID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    let schedule = state.schedule.scheduleByID[sescID];
    try {
      if (!schedule) {
        await fetchSchedule(apiToken, sescID).then((r) => {
          schedule = r;
          dispatch(actions.scheduleLoaded(r));
        });
      }
      if (schedule?.locaID) {
        await dispatch(fetchCachedLocation(schedule?.locaID));
      }
      return Promise.resolve();
    } catch (e) {
      dispatch(
        newNotification("general", {
          status: "error",
          message: (e as any)?.message,
        })
      );
      throw e;
    }
  };
}

export function loadClinicProfile(clinID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchclinic(apiToken, clinID)
      .then((r) => {
        dispatch(actions.loadClinic(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedClinic(clinID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const clinicExist = Boolean(state.schedule.clinByID[clinID]);
    if (clinicExist) {
      return Promise.resolve();
    }
    return dispatch(loadClinicProfile(clinID));
  };
}

export function createOneScheduleLock(
  lock: CreateScheduleLockForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createScheduleLock(apiToken, lock)
      .then((r) => {
        dispatch(actions.scheduleLockLoad(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAllScheduleLocks(
  f?: FilterScheduleLock
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchListScheduleLock(apiToken, f)
      .then(async (r) => {
        dispatch(actions.scheduleLockListLoad(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeOneScheduleLock(scloID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteScheduleLock(apiToken, scloID)
      .then((r) => {
        dispatch(actions.scheduleLockRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAllClinics(f?: ClinicFilter): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const doctID = state?.user?.myProfile?.doctor?.doctID;

    const filter: ClinicFilter = {
      doctID,
      ...f,
    };
    return fetchClinics(apiToken, filter)
      .then((r) => {
        dispatch(actions.loadClinics(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppoinmentStatusHistory(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    return fetchAppointmentStatus(apiToken, appoID)
      .then((r) => {
        dispatch(actions.appointmentStatusLoaded(appoID, r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadclinicUsersByClinID(
  f?: FilterClinicUser
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchClinicUsers(apiToken, f)
      .then((r) => {
        dispatch(actions.loadClinicsUsers(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadHealthplan(heplID: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return fetchHealthplan(heplID)
      .then((r) => {
        dispatch(actions.healthplanLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchCachedHealthplan(heplID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const healthplanExist = Boolean(state.schedule.healthplanByID[heplID]);
    if (healthplanExist) {
      return Promise.resolve();
    }
    return dispatch(loadHealthplan(heplID));
  };
}

export function loadPendingReturns(patiID?: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    return fetchAppointsReturnPending(apiToken, { patiID })
      .then((r) => {
        dispatch(actions.pendingReturnLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function getAppointmentCommunication(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointmentCommunication(apiToken, appoID)
      .then((r) => {
        dispatch(actions.appointmentCommunicationLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchCachedAppointmentCommunication(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const communicationExist = Boolean(
      state.schedule.appointmentCommunicationByID[appoID]
    );
    if (communicationExist) {
      return Promise.resolve();
    }
    return dispatch(getAppointmentCommunication(appoID));
  };
}

export function sendAppointmentReviewNotification(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return sendAppointmentReview(apiToken, appoID)
      .then((r) => {
        newNotification("general", {
          status: "success",
          message: "Pesquisa de satisfação enviada!",
        });
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: "Não foi possível enviar a pesquisa de satisfação",
          })
        );
      });
  };
}

export function loadAppointmentReviews(
  f?: FilterAppointmentReview
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchListAppointmentReview(apiToken, f)
      .then((r) => {
        dispatch(actions.reviewsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function createOneAppointmentFile(
  form: AppointmentFileForm
): AppThunk<Promise<AppointmentFile>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createAppointmentFile(apiToken, form)
      .then((r) => {
        dispatch(actions.loadAppointmentFile(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointmentFile(
  appoID: string,
  f?: FilterAppointmentFile
): AppThunk<Promise<AppointmentFile[]>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointmentFile(apiToken, appoID, f)
      .then((r) => {
        dispatch(actions.loadAppointmentFiles(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function removeOneAppointmentFile(
  appoID: string,
  fileID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteAppointmentFile(apiToken, appoID, fileID)
      .then((r) => {
        dispatch(actions.appointmentFileRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function listScheduleSlots(
  f: FilterScheduleSlots
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchScheduleSlots(apiToken, f)
      .then(async (r) => {
        dispatch(actions.scheduleSlotLoaded(f.date, r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function listCalendarEvents(
  filter: FilterCalendarEvents
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchCanceableCalendarEvents(apiToken, filter)
      .then(async (r) => {
        if (r) {
          dispatch(actions.calendarEventsLoaded(r));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedInvoicesByAppoID(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const list = state.schedule.invoicesByAppoID?.[appoID] ?? [];
    if (list.length > 0) {
      return Promise.resolve();
    }
    return dispatch(loadInvoicesByAppoID(appoID));
  };
}

export function newAppointmentCheckIn(appoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createAppointmentCheckIn(apiToken, appoID)
      .then((r) => {
        dispatch(actions.appointmentCheckInLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Check-In realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeAppointmentCheckIn(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteAppointmentCheckIn(apiToken, appoID)
      .then((r) => {
        dispatch(actions.appointmentCheckInRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointmentCheckIn(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointmentCheckIn(apiToken, appoID)
      .then(async (r) => {
        dispatch(actions.appointmentCheckInLoaded(r));
      })
      .catch((e) => {
        throw e;
      });
  };
}

export function listAppointmentCheckIn(
  filter: FilterAppointmentCheckIn
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointmentCheckIns(apiToken, filter)
      .then(async (r) => {
        if (r) {
          dispatch(actions.appointmentCheckInsLoaded(r));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedAppointmentCheckIn(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const exist = Boolean(state.schedule.appointmentCheckInByID?.[appoID]);
    if (exist) {
      return Promise.resolve();
    }
    return dispatch(loadAppointmentCheckIn(appoID));
  };
}
