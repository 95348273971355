import React from "react";

import { connect } from "react-redux";
import { AppDispatch } from "ducks/state";

import { oneAppointmentView } from "ducks/schedule";
import { Typography, Link } from "@material-ui/core";
import { format } from "@udok/lib/internal/util";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
moment.locale("pt-br");

export type Props = { appoID: string } & ReturnType<
  ReturnType<typeof oneAppointmentView>
> &
  ReturnType<typeof mapDispatchToProps>;

const TitleBrief = (props: Props) => {
  const { appointment } = props;

  return (
    <Typography variant="h6">
      <Link
        component={RouterLink}
        to={`/admin/patients/${appointment?.patiID}`}
      >
        {appointment?.patiName}
      </Link>
      <br />
      <Typography component="small" variant="caption">
        Consulta em {moment(appointment?.markedAt).format(format.DAYMON)} às{" "}
        {moment(appointment?.markedAt).format(format.TIME24H)}
      </Typography>
    </Typography>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(oneAppointmentView, mapDispatchToProps)(TitleBrief);
