import React from "react";
import { useHistory } from "react-router";
import {
  Grid,
  IconButton,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useActiveImmediateCareSession } from "hooks/immediateCare";
import Alert from "@material-ui/lab/Alert";
import PatientName from "containers/Patient/PatientName";
import Icons from "@udok/lib/components/Icon";
import clsx from "clsx";
import { toQueryString } from "@udok/lib/internal/util";

import moment from "moment";
moment.locale("pt-br");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      top: 0,
      zIndex: 1201,
      paddingTop: 0,
      width: "403px",
      paddingBottom: 0,
      color: "#025C00",
      maxWidth: "100vw",
      cursor: "pointer",
      position: "fixed",
      borderColor: "#62D905",
      backgroundColor: "#E7F4E2",
      right: "calc(50% - (403px / 2) )",
      "& > div": {
        width: "100%",
      },
    },
    oneLineText: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    hidden: { display: "none" },
    actionContainer: {
      display: "flex",
      justifyContent: "end",
      marginRight: theme.spacing(-1),
      "& > *": {
        marginRight: theme.spacing(),
      },
    },
  })
);

let timeCounter: NodeJS.Timeout;
const TimeCounter = ({
  startTime,
  ...others
}: { startTime: string } & TypographyProps) => {
  const [time, setTime] = React.useState(0);

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  const [hourLeft, hourRight] = String(hours).padStart(2, "0").split("");
  const [minLeft, minRight] = String(minutes).padStart(2, "0").split("");
  const [secLeft, secRight] = String(Math.floor(seconds))
    .padStart(2, "0")
    .split("");

  React.useEffect(() => {
    let diff = moment().diff(moment(startTime), "milliseconds") / 1000;
    setTime(diff < 0 ? 0 : diff);
  }, [startTime]);

  React.useEffect(() => {
    timeCounter = setInterval(() => {
      setTime((time) => time + 1);
    }, 1000);
    return () => {
      clearInterval(timeCounter);
    };
  }, []);

  return (
    <Typography {...others}>
      <b>{`${hourLeft}${hourRight}:${minLeft}${minRight}:${secLeft}${secRight}`}</b>
    </Typography>
  );
};

const AttendingActions = () => {
  const classes = useStyles();
  const history = useHistory();
  const [navigateID, setNavigateID] = React.useState<string>();
  const [, activeSession, currentDoctor] = useActiveImmediateCareSession();
  const currentPath = history.location.pathname;
  const attendings = activeSession?.attending ?? [];
  const attending = attendings.find((att) => {
    return att.caregiver.doctID === currentDoctor?.doctID;
  });
  const appoID = attending?.careRecipient?.appoID;
  const path = `/admin/appointments/${appoID}`;

  const navigateToAttending = React.useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      history.push(path);
    },
    [history, path]
  );

  React.useEffect(() => {
    if (appoID && appoID !== navigateID) {
      setNavigateID(appoID);
      history.push(
        `${path}${toQueryString({
          autoPlay: true,
        })}`
      );
    }
  }, [history, path, appoID, navigateID]);

  return (
    <Grid container className={clsx({ [classes.hidden]: !attending })}>
      <Grid item xl={8} lg={7} xs={6}>
        <Typography color="inherit" className={classes.oneLineText}>
          <PatientName patiID={attending?.careRecipient?.patiID} />
        </Typography>
      </Grid>
      <Grid item xl={4} lg={5} xs={6}>
        <div className={classes.actionContainer}>
          {currentPath !== path ? (
            <IconButton
              color="inherit"
              title="Ir para o atendimento"
              onClick={navigateToAttending}
              style={{ padding: 0 }}
            >
              <Icons.PlayCircleOutlineRounded color="inherit" width={25} />
            </IconButton>
          ) : null}
          {attending ? (
            <TimeCounter startTime={attending?.startedAt ?? ""} />
          ) : null}
        </div>
      </Grid>
    </Grid>
  );
};

const SessionCard = () => {
  const classes = useStyles();
  const [immediateCare, activeSession, currentDoctor] =
    useActiveImmediateCareSession();
  const history = useHistory();
  const open = Boolean(activeSession?.imcsID);

  const navigateToRoom = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (activeSession?.imcsID) {
        history.push(`/admin/immediate-care-session/${activeSession?.imcsID}`);
      }
    },
    [history, activeSession]
  );

  return (
    <Alert
      icon={false}
      variant="outlined"
      severity="success"
      onClick={navigateToRoom}
      className={clsx(classes.root, {
        [classes.hidden]: !open,
      })}
    >
      <Grid container spacing={1}>
        <Grid item xl={8} lg={7} xs={6}>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.oneLineText}
          >
            {immediateCare?.name}
          </Typography>
        </Grid>
        <Grid
          item
          xl={4}
          lg={5}
          xs={6}
          alignItems="flex-end"
          justify="flex-end"
        >
          <TimeCounter
            variant="h4"
            color="inherit"
            align="right"
            startTime={currentDoctor?.startedAt ?? ""}
          />
        </Grid>
      </Grid>
      <AttendingActions />
    </Alert>
  );
};

export default SessionCard;
