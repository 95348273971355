import React from "react";
import {
  Button,
  CardMedia,
  Typography,
  TextField,
  TextFieldProps,
  CircularProgress,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Illustrations from "@udok/lib/components/Illustrations";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import ImageDialog from "@udok/lib/components/Dialog/ImageDialog";
import { loadImage } from "@udok/lib/internal/util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileInputButton: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    inputImageContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 66,
      height: 66,
      backgroundColor: theme.palette.neutral.ultralight,
      fill: theme.palette.secondary.lightest,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
    },
    fileInputLabel: {
      marginLeft: theme.spacing(2),
      color: theme.palette.neutral.dark,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "60%",
    },
  })
);

type FileAsDataURLInputProps = Omit<
  TextFieldProps,
  "onChange" | "variant" | "type"
> & {
  htmlFor: string;
  onChange: (val: {
    src: string | null;
    width: number;
    height: number;
  }) => void;
  value?: string | null | undefined;
  resizeImage?: boolean;
  resizeHeight?: number;
  resizeWidth?: number;
  imageBackgroundColor?: string;
};

const [rendererDialog, promiseDialog] = createModal(ImageDialog);

const FileAsDataURLInput = ({
  onChange,
  htmlFor,
  value,
  resizeImage,
  resizeHeight,
  resizeWidth,
  imageBackgroundColor,
  ...textFieldProps
}: FileAsDataURLInputProps) => {
  const [fileName, setFileName] = React.useState("");
  const [loading, setL] = React.useState(false);
  const ref = React.useRef<any>(null);
  const classes = useStyles();

  const submitImage = (file?: File) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      async () => {
        setL(false);
        const img = await loadImage((reader.result as string) || "");
        onChange({
          src: reader.result as string | null,
          width: img.width,
          height: img.height,
        });
      },
      false
    );

    if (file) {
      if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
        alert("Formato de arquivo inválido");
        return;
      }
      setL(true);

      reader.readAsDataURL(file);
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    const fileName = e.target.value ?? "";
    if (!file) {
      return;
    }
    if (resizeImage) {
      const url = window.URL.createObjectURL(file);
      const image = await loadImage(url);
      await promiseDialog({
        image: url,
        nameImage: fileName ?? "",
        type: "signature",
        width: resizeWidth ?? image?.width,
        height: resizeHeight ?? image?.height,
        imageBackgroundColor,
      }).then((file?: File) => {
        setFileName(file?.name ?? "");
        submitImage(file);
      });
      return;
    }

    setFileName(file?.name ?? "");
    submitImage(file);
  };

  return (
    <>
      <label htmlFor={htmlFor}>
        <TextField
          ref={ref}
          style={{ display: "none" }}
          id={htmlFor}
          name={htmlFor}
          type="file"
          onChange={handleChange}
          inputProps={{
            accept: ".jpg,.jpeg,.png,.gif",
            ...textFieldProps?.inputProps,
          }}
          {...textFieldProps}
        />
        <Button className={classes.fileInputButton} component="span">
          <div className={classes.inputImageContainer}>
            {value ? (
              <CardMedia
                component="img"
                image={value}
                style={{
                  width: "90%",
                  height: "90%",
                }}
              />
            ) : (
              <Illustrations.ImageExample width="50%" height="50%" />
            )}
          </div>
          <Typography variant="body2" className={classes.fileInputLabel}>
            <b>{fileName || "Escolher imagem..."}</b>
          </Typography>

          {loading && (
            <div style={{ marginLeft: 5, marginBottom: 5 }}>
              <CircularProgress size={12} />
            </div>
          )}
        </Button>
      </label>
      {rendererDialog}
    </>
  );
};

export default FileAsDataURLInput;
