import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { AppDispatch } from "ducks/state";
import { readEventAlert, readEventAlertList } from "ducks/events";
import { useAppointmentEvents } from "hooks/events";
import { Button, IconButton } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import EventAlerts, { Event } from "@udok/lib/components/RenderEventAlerts";
import Icons from "@udok/lib/components/Icon";
import {
  EventAlert,
  EventAlertType,
  getStatusLabel,
} from "@udok/lib/api/models";
import { format } from "@udok/lib/internal/util";
import moment from "moment";
moment.locale("pt-br");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    readAllButton: {
      color: theme.palette.primary.main,
      [theme.breakpoints.down("sm")]: {
        color: theme.palette.neutral.lightest,
      },
    },
    button: {
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    },
  })
);

const getTextEvent = (eve: EventAlert) => {
  let eventText: Event["text"] = {};
  switch (eve.path) {
    case EventAlertType.newAppointment:
      eventText = {
        primary: `Novo agendamento com ${eve.data?.patiName}`,
      };
      break;
    case EventAlertType.appointmentStatusChange:
      eventText = {
        primary: `O status do agendamento do paciente ${
          eve.data?.patiName
        } foi alterado para ${getStatusLabel(eve.data?.appoStatus)}`,
      };
      break;
    case EventAlertType.appointmentReschedule:
      const markedAt = moment.utc(eve.data?.markedAt);
      const sufix = markedAt.isValid()
        ? ` para ${markedAt
            .local()
            .format(`${format.SHRTPTBR} [às] ${format.TIME24H}`)}.`
        : ".";
      eventText = {
        primary: `O agendamento do paciente ${eve.data?.patiName} foi remarcado${sufix}`,
      };
      break;
    case EventAlertType.appointmentDoctorTransfer:
      eventText = {
        primary: `O agendamento do paciente ${eve.data?.patientName} foi transferido para ${eve.data?.nameDoctorTo}`,
      };
      break;
    default:
      eventText = {
        primary: "Erro ao carregar dados da notificação!",
      };
  }
  return eventText;
};

const EventsAlertView = () => {
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [alerts] = useAppointmentEvents();
  const history = useHistory();

  const unread = React.useMemo(
    () => alerts.filter((a) => !a?.readAt),
    [alerts]
  );

  const list = React.useMemo(
    () =>
      alerts.map((al) => {
        const eve: Event = {
          ...al,
          text: getTextEvent(al),
        };
        return eve;
      }),
    [alerts]
  );

  const handleRead = React.useCallback(
    (eve: Event) => {
      if (!eve?.readAt) {
        dispatch(readEventAlert(eve?.notiID));
      }
    },
    [dispatch]
  );

  const handlePress = React.useCallback(
    (eve: Event) => {
      handleRead(eve);
      if (eve?.data?.appoID) {
        const p = `/admin/appointments/${eve.data.appoID}`;
        history.push(p);
      }
    },
    [history, handleRead]
  );

  const handleReadAll = React.useCallback(() => {
    setLoading(true);
    const notiIDs = unread.map((ur) => ur.notiID);
    dispatch(readEventAlertList(notiIDs)).finally(() => setLoading(false));
  }, [unread, dispatch]);

  return (
    <EventAlerts
      component={IconButton}
      unreadCount={unread.length}
      list={list}
      listTitle="Notificações"
      onPressItem={handlePress}
      onRead={handleRead}
      emptyText="Nenhuma notificação!"
      className={classes.button}
      loading={loading}
      actionItems={
        <Button
          color="primary"
          variant="text"
          className={classes.readAllButton}
          onClick={handleReadAll}
        >
          Marcar todos como lido
        </Button>
      }
    >
      <Icons.Notifications color="primary" width={40} height={40} />
    </EventAlerts>
  );
};

export default EventsAlertView;
