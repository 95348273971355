import React from "react";
import { connect } from "react-redux";
import { uploadOneFile } from "ducks/collectDocument";

export interface UploaderProps {
  uploadFile: (c: File) => Promise<string>;
  onChange?: (f: string) => void;
  value?: string;
  children: React.ReactNode;
}

class Uploader extends React.PureComponent<UploaderProps> {
  state = { loading: false };
  handleChange = (e: any) => {
    const { onChange } = this.props;
    this.setState({ loading: true });
    let file = {} as File;

    if (e?.target) {
      const files = e.target.files;
      file = files[0];
    } else {
      file = e;
    }

    this.props
      .uploadFile(file)
      .then((filename) => {
        onChange?.(filename);
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { children, onChange, value, ...props } = this.props;
    const loading = this.state.loading;

    return React.cloneElement(children as any, {
      ...props,
      loading,
      onChange: this.handleChange,
      value,
    });
  }
}

export default connect(
  () => ({}),
  (dispatch: any) => ({
    uploadFile: (c: File) => dispatch(uploadOneFile(c)),
  })
)(Uploader);
