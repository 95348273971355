import React from "react";
import CCAmex from "@udok/lib/components/Illustrations/CCAmex";
import CCDiners from "@udok/lib/components/Illustrations/CCDiners";
import CCDiscover from "@udok/lib/components/Illustrations/CCDiscover";
import CCElo from "@udok/lib/components/Illustrations/CCElo";
import CCGeneric from "@udok/lib/components/Illustrations/CCGeneric";
import CCHiperCard from "@udok/lib/components/Illustrations/CCHiperCard";
import CCJCB from "@udok/lib/components/Illustrations/CCJCB";
import CCMaestro from "@udok/lib/components/Illustrations/CCMaestro";
import CCMasterCard from "@udok/lib/components/Illustrations/CCMasterCard";
import CCUnionPay from "@udok/lib/components/Illustrations/CCUnionPay";
import CCVisa from "@udok/lib/components/Illustrations/CCVisa";

export default ({
  creditcard,
  height = 75,
  width = 100,
}: {
  creditcard: string;
  height?: number | string;
  width?: number | string;
}) => {
  const ccsIcons: { [k: string]: React.ReactElement } = {
    visa: <CCVisa width={width} height={height} />,
    mastercard: <CCMasterCard width={width} height={height} />,
    discover: <CCDiscover width={width} height={height} />,
    amex: <CCAmex width={width} height={height} />,
    jcb: <CCJCB width={width} height={height} />,
    dinersclub: <CCDiners width={width} height={height} />,
    maestro: <CCMaestro width={width} height={height} />,
    laser: <CCGeneric width={width} height={height} />,
    unionpay: <CCUnionPay width={width} height={height} />,
    elo: <CCElo width={width} height={height} />,
    hipercard: <CCHiperCard width={width} height={height} />,
  };
  return ccsIcons[creditcard] ?? CCGeneric;
};
