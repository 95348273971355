import React from "react";
import { useDispatch } from "react-redux";
import { useGetDoctorInformation } from "hooks/user";
import {
  UserProfile,
  Location,
  Schedule,
  Preferences,
} from "@udok/lib/api/models";
import { calculateOnboardingSteps } from "@udok/lib/app/doctor";
import Dialog from "@udok/lib/components/Dialog/ResponsiveDialog";
import { CircularProgress, DialogTitle, IconButton } from "@material-ui/core";
import { useGetOneDoctorPreference } from "hooks/doctorPreferences";
import { createOneDoctorPreference } from "ducks/doctorPreferences";
import { AppDispatch } from "ducks/state";
import OnboardingStepper from "containers/OnboardingStepper/Stepper";
import Icons from "@udok/lib/components/Icon";

export type OnboardingStepperViewProps = {
  open: boolean;
  close: (completedSteps: boolean) => void;
  currentUser?: UserProfile;
  locations: Location[];
  schedules: Schedule[];
};

export const ConnectedStepperView = (props: OnboardingStepperViewProps) => {
  const { currentUser, locations, schedules, open, close } = props;
  const dispatch: AppDispatch = useDispatch();
  const [currentUserState] = React.useState(currentUser);
  const [locationsState] = React.useState(locations);
  const [schedulesState] = React.useState(schedules);
  const { listSteps, steps } = React.useMemo(
    () =>
      calculateOnboardingSteps(
        locationsState,
        schedulesState,
        currentUserState
      ),
    [schedulesState, locationsState, currentUserState]
  );
  const firstSescID = schedulesState?.[0]?.sescID;
  const firstLocaID = locationsState?.[0]?.locaID;

  const handleDispense = React.useCallback(() => {
    dispatch(
      createOneDoctorPreference({
        prefID: Preferences.doctorSkipTutorial,
        options: { initialTutorial: true },
      })
    );
  }, [dispatch]);

  const handleClose = React.useCallback(
    (completedSteps: boolean) => {
      if (completedSteps) {
        handleDispense();
      }
      close(completedSteps);
    },
    [close, handleDispense]
  );

  return (
    <OnboardingStepper
      open={open}
      close={handleClose}
      steps={steps}
      listSteps={listSteps}
      sescID={firstSescID}
      locaID={firstLocaID}
    />
  );
};

type FetchWizardProps = Omit<
  OnboardingStepperViewProps,
  "currentUser" | "locations" | "schedules"
>;

const FetchWizard = (props: FetchWizardProps) => {
  const [loadInfo, currentUser, LocList, schList] = useGetDoctorInformation();
  const [loadPref, pref] = useGetOneDoctorPreference(
    Preferences.doctorSkipTutorial
  );

  if (Boolean(pref?.options?.initialTutorial)) {
    return null;
  }

  if (loadInfo || loadPref) {
    return (
      <Dialog
        aria-labelledby="loading-dialog-title"
        fullWidth
        maxWidth="md"
        open={props.open}
        onClose={props.close}
      >
        <DialogTitle
          disableTypography
          id="loading-dialog-title"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <IconButton onClick={() => props.close(false)}>
            <Icons.Close fontSize="inherit" />
          </IconButton>
        </DialogTitle>
        <div
          style={{
            height: 500,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={50} />
        </div>
      </Dialog>
    );
  }

  return (
    <ConnectedStepperView
      currentUser={currentUser}
      locations={LocList}
      schedules={schList}
      {...props}
    />
  );
};

export default FetchWizard;
