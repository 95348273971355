import * as React from "react";
import { Link } from "react-router-dom";
import { WithStyles, withStyles, Theme } from "@material-ui/core/styles";
// @ts-ignore
import createDOMForm from "rc-form/lib/createDOMForm";
// @ts-ignore
import { formShape } from "rc-form";
import {
  Location,
  ContactPhone,
  PlanFeaturesPermissions,
} from "@udok/lib/api/models";
import {
  CircularProgress,
  Button,
  TextField,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { normalizeMoney, onlyNumbers } from "@udok/lib/internal/util";
import { UfList } from "@udok/lib/internal/constants";
import SelectHealthplans from "containers/Healthplan/Search";
import SearchCep, { SearchCepReturn } from "./SearchCep";
import Selector from "@udok/lib/components/Selector";
import RenderRequiredStatuslabel from "@udok/lib/components/RequiredStatuslabel";
import PrescriptionLayoutSelect from "containers/PrescriptionLayout/Select";
import ContactsInput from "@udok/lib/components/Contacts/Input";
import FormRow from "@udok/lib/components/FormRow";
import SwitchField from "@udok/lib/components/Input/SwitchField";
import ResourceAlertPopover from "containers/LimitedResourceAlert/Popover";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";

export type initialValuesType = Partial<Location> & {
  region?: Partial<SearchCepReturn>;
  isDefault?: boolean;
};

export interface LocationFormProps {
  onSubmit: (err: any, values: any, form: formShape) => void;
  initialValues?: initialValuesType;
  form?: formShape;
  loading: boolean;
  autoSearch?: boolean;
  validateResource?: boolean;
}

const styles = (theme: Theme) => ({
  table: {},
  modelText: {
    marginBottom: theme.spacing(1),
  },
});

const LayoutFilter = { doctor: true };
const [rendererResourceAlert, promiseResourceAlert] =
  createModal(ResourceAlertPopover);
export const LocationForm = withStyles(styles, { withTheme: true })(
  class extends React.Component<
    LocationFormProps & WithStyles<typeof styles> & { theme: Theme }
  > {
    handleSubmit = (e: any) => {
      e.preventDefault();
      e?.stopPropagation?.();
      this.props.form.validateFieldsAndScroll(
        { scroll: { offsetTop: this.props.theme.spacing(10) } },
        async (err: any, values: any) => {
          if (!err && this.props.validateResource) {
            const viewOffer = await promiseResourceAlert({
              anchorEl: (e.nativeEvent as any).submitter,
              feature: PlanFeaturesPermissions.UnlimitedAddresses,
            });
            if (viewOffer) {
              return;
            }
          }
          this.props.onSubmit(err, values, this.props.form);
        }
      );
    };

    validateString = (a: any, value: string, cb: (e?: string) => void) => {
      if (!value || !value.trim()) {
        cb("Campo obrigatorio");
        return;
      }
      cb();
    };
    validateContacts(
      _: string,
      src: ContactPhone[] | undefined,
      cb: (s?: string) => void
    ) {
      if (
        !src ||
        (src ?? []).filter((p) => !!onlyNumbers(p.phone)).length === 0
      ) {
        cb("Campo obrigatório");
      }
      cb();
    }

    render() {
      const { classes, initialValues, loading, form, autoSearch } = this.props;
      const { getFieldProps, getFieldError, getFieldValue, setFieldsValue } =
        form;
      const shrinker = (v: any): object => {
        return (Boolean(v) && { shrink: Boolean(v) }) || {};
      };

      return (
        <form className={classes.table} onSubmit={this.handleSubmit}>
          <SearchCep
            title="CEP"
            required
            style={{ marginBottom: 10 }}
            variant="outlined"
            margin="dense"
            error={Boolean(getFieldError("regionSelected"))}
            errorMessage={getFieldError("regionSelected") as string}
            automaticSearch={autoSearch}
            InputProps={{ required: false }}
            {...getFieldProps("regionSelected", {
              rules: [
                {
                  type: "object",
                },
                (a: any, value: SearchCepReturn, cb: (e?: string) => void) => {
                  if (!value || !value.latitude || !value.longitude) {
                    cb("Campo obrigatorio");
                    return;
                  }
                  cb();
                },
              ],
              initialValue: initialValues?.region,
              onChange: (value: SearchCepReturn) => {
                setFieldsValue({
                  street: value?.street,
                  uf: value?.stateAcronym,
                  neighborhood: value?.district,
                  city: value?.city,
                });
              },
            })}
          />
          <FormRow row>
            <TextField
              type="text"
              required
              label="Rua"
              margin="normal"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                ...shrinker(getFieldValue("street") as string),
              }}
              InputProps={{ required: false }}
              error={Boolean(getFieldError("street"))}
              helperText={getFieldError("street") as string}
              {...getFieldProps("street", {
                rules: [this.validateString],

                initialValue: initialValues?.addressInfo?.street,
              })}
            />

            <TextField
              type="text"
              label="Bairro"
              required
              fullWidth
              helperText={getFieldError("neighborhood") as string}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                ...shrinker(getFieldValue("neighborhood") as string),
              }}
              InputProps={{ required: false }}
              error={Boolean(getFieldError("neighborhood"))}
              {...getFieldProps("neighborhood", {
                rules: [this.validateString],
                initialValue: initialValues?.addressInfo?.neighborhood,
              })}
            />

            <TextField
              type="text"
              margin="normal"
              required
              label="Cidade"
              InputLabelProps={{
                ...shrinker(getFieldValue("city") as string),
              }}
              InputProps={{ required: false }}
              variant="outlined"
              error={Boolean(getFieldError("city"))}
              helperText={getFieldError("city") as string}
              {...getFieldProps("city", {
                rules: [this.validateString],

                initialValue: initialValues?.addressInfo?.city,
              })}
            />

            <Selector
              list={UfList}
              required
              label="Estado"
              variant="outlined"
              error={Boolean(getFieldError("uf"))}
              margin="normal"
              errorMessage={getFieldError("uf") as string}
              style={{ minWidth: 150 }}
              InputProps={{ required: false }}
              {...getFieldProps("uf", {
                rules: [
                  {
                    type: "string",
                    required: true,
                    message: "Campo obrigatorio",
                  },
                ],
                initialValue: initialValues?.addressInfo?.uf ?? "",
              })}
            />
            <TextField
              type="text"
              label={<RenderRequiredStatuslabel label="CNES" />}
              variant="outlined"
              autoComplete="none"
              InputLabelProps={{
                ...shrinker(getFieldValue("cnes") as string),
              }}
              error={Boolean(getFieldError("cnes"))}
              margin="normal"
              {...getFieldProps("cnes", {
                rules: [{ type: "string" }],
                initialValue: initialValues?.addressInfo?.cnes,
              })}
            />
            <TextField
              type="text"
              label="Descrição do endereço"
              autoComplete="none"
              variant="outlined"
              error={Boolean(getFieldError("description"))}
              helperText={getFieldError("description") as string}
              margin="normal"
              fullWidth
              required
              InputProps={{ required: false }}
              {...getFieldProps("description", {
                rules: [this.validateString],
                initialValue: initialValues?.name ?? "",
              })}
            />
          </FormRow>
          <ContactsInput
            title="Contatos do local"
            buttonLabel="Novo contato"
            errors={getFieldError("locationPhones")}
            typographyProps={{
              variant: "h6",
              style: { marginTop: 10, marginBottom: 10 },
            }}
            {...getFieldProps("locationPhones", {
              validateTrigger: false,
              rules: [this.validateContacts],
              initialValue: initialValues?.addressInfo?.locationPhones,
            })}
          />
          <SelectHealthplans
            multiple
            required
            fullWidth
            validatePermission
            label="Selecione os planos"
            style={{ marginTop: 10 }}
            variant="outlined"
            error={Boolean(getFieldError("healthPlans"))}
            errorMessage={getFieldError("healthPlans")}
            {...getFieldProps("healthPlans", {
              rules: [
                {
                  type: "array",
                  required: true,
                  message: "Campo obrigatorio",
                },
              ],
              initialValue:
                (initialValues?.healthPlans?.length || 0) > 0
                  ? initialValues?.healthPlans
                  : ["1"],
            })}
          />
          <TextField
            type="text"
            label={<RenderRequiredStatuslabel label="Valor do agendamento" />}
            variant="outlined"
            error={Boolean(getFieldError("price"))}
            margin="normal"
            placeholder="0,00"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
            {...getFieldProps("price", {
              initialValue: initialValues?.price
                ?.toString()
                ?.replace?.(".", ","),
              normalize: normalizeMoney,
            })}
          />
          <div style={{ marginTop: 10 }}>
            <SwitchField
              name="isDefault"
              color="primary"
              label="Definir este endereço como padrão para os documentos médicos?"
              {...getFieldProps("isDefault", {
                initialValue: initialValues?.isDefault ?? false,
              })}
            />
          </div>
          <Typography variant="h6" style={{ marginTop: 10, marginBottom: 10 }}>
            Customização de receituário
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={classes.modelText}
          >
            <span>
              Selecione uma aparência de documento médico como padrão para as
              prescrições criadas com este endereço.
            </span>
            <br />
            <Link to={`/admin/prescriptionlayout`}>
              Clique aqui para gerenciar as customizações de receituário
            </Link>
          </Typography>

          <PrescriptionLayoutSelect
            fullWidth
            validatePermission
            label={
              <RenderRequiredStatuslabel label="Aparências de documentos" />
            }
            variant="outlined"
            margin="none"
            errorMessage={getFieldError("plteID")}
            error={Boolean(getFieldError("plteID"))}
            filter={LayoutFilter}
            {...getFieldProps("plteID", {
              initialValue: initialValues?.prescriptionLayouts?.[0],
            })}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: 24,
            }}
          >
            <Button
              id="SaveLocation"
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              <span>Salvar</span>
              {loading && <CircularProgress size={24} />}
            </Button>
          </div>
          {rendererResourceAlert}
        </form>
      );
    }
  }
);

export default createDOMForm()(LocationForm) as typeof LocationForm;
