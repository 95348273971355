import * as React from "react";
import { Typography, LinearProgress, Link } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import StepItemView from "containers/OnboardingStepper/StepItemView";
import Illustration from "@udok/lib/components/Illustrations";
import Icons from "@udok/lib/components/Icon";
import { useGetSEOListing } from "hooks/user";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    illustration: {
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(1, 5, 0, 0),
      },
    },
    stepperButton: {
      marginBottom: 0,
      marginTop: theme.spacing(4),
    },
  })
);

const SuccessStep = ({ onFinishing }: { onFinishing: () => void }) => {
  const classes = useStyles();
  const [loading, listing] = useGetSEOListing();
  const url = `${process.env.REACT_APP_WEB_APPLICATION_URL}/${
    listing?.slug ?? ""
  }`;
  return (
    <StepItemView
      text={
        <Typography>
          Dados atualizados com sucesso, agora você está pronto para utilizar a
          plataforma!
          <br />
          Compartilhe o link do seu perfil público com seus pacientes para
          poderem visualizar seus horários e realizar agendamentos.
          <br />
          <br />
          <div>
            <span>Perfil público:</span>
            <br />
            {loading ? (
              <LinearProgress />
            ) : (
              <Link
                href={url}
                color="secondary"
                target="_blank"
                rel="noopener noreferrer"
              >
                {url}
              </Link>
            )}
          </div>
        </Typography>
      }
      illustration={<Illustration.SelfScheduling width="21em" height="auto" />}
      classes={{
        illustration: classes.illustration,
        stepperButton: classes.stepperButton,
      }}
      actions={[
        {
          color: "success",
          label: "Concluir",
          onClick: onFinishing,
          buttonProps: {
            startIcon: <Icons.CheckCircleOutline fontSize="large" />,
          },
        },
      ]}
    />
  );
};

export default SuccessStep;
