import { createSelector } from "reselect";
import { createReducer } from "@udok/lib/internal/store";
import { RootState, AppThunk } from "./state";
import { jwt_payload } from "@udok/lib/internal/jwt";
import { getCookie, eraseCookie, setCookie } from "@udok/lib/internal/cookie";
import { Action } from "ducks";
import {
  newNotification,
  dismissAllNotificationsFrom,
  NotificationActions,
} from "./notification";
import { ThunkDispatch } from "redux-thunk";
import {
  authenticate,
  requestPasswordRecover,
  validateVerification,
  resetToNewPassword,
  LoginResponse,
  createNewDoctor,
  OnboardingDoctorForm,
  setDoctorVerification,
  emailTokenSignin,
  emailTokenRequest,
  LoginForm,
  emailVerification,
  otpLogin,
} from "@udok/lib/api/auth";
import { EmailValidateStatus } from "@udok/lib/api/models";

export const REQUEST_SIGNIN = "REQUEST_SIGNIN";
export const RESPONSE_SIGNIN = "RESPONSE_SIGNIN";
export const FAIL_SIGNIN = "FAIL_SIGNIN";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const TOKENSWAP = "TOKENSWAP";
export const REQUEST_RECOVERY_PASSWORD = "REQUEST_RECOVERY_PASSWORD";
export const RESPONSE_RECOVERY_PASSWORD = "REQUEST_RECOVERY_PASSWORD";
export const SAVE_STATUS_CODE = "SAVE_STATUS_CODE";
export const CLEAR_RESETID_STATUSCODE = "CLEAR_RESETID_STATUSCODE";
export const TOKENACCESSSTARTED = "TOKENACCESSSTARTED";

export type JWTPayload = {
  [k: string]: any;
  exp: number;
  userID: string;
  doctID: string;
};

export interface AuthState {
  statuscode: boolean;
  verification: string;
  resetID: string;
  token: {
    raw: string;
    payload: JWTPayload;
  };
  currentAcveID?: { acveID: string; email: string };
}

export type InitialState = AuthState;

const COOKIENAME = `token${process.env.REACT_APP_APPLICATION_ID}`;
const fromCookie = getCookie(COOKIENAME) || "";
const initialState: InitialState = {
  statuscode: false,
  verification: "",
  resetID: "",
  token: {
    raw: fromCookie,
    payload: jwt_payload(fromCookie) || { exp: 0 },
  },
};

// Reducers
export const authReducer = createReducer(initialState, {
  [REQUEST_SIGNIN](state: InitialState, a: Action) {
    return state;
  },
  [RESPONSE_SIGNIN](state: InitialState, a: Action) {
    return state;
  },
  [TOKENSWAP](state: InitialState, a: Action & { token: string }) {
    let token = a.token;
    state.token.raw = token;
    state.token.payload = jwt_payload(token);
    return state;
  },
  [FAIL_SIGNIN](state: InitialState, a: Action) {
    return state;
  },
  [UNAUTHORIZED](state: InitialState, a: Action) {
    state.token.raw = "";
    state.token.payload = { exp: 0, userID: "", doctID: "" };
    return state;
  },
  [SAVE_STATUS_CODE](state: InitialState, a: any) {
    state.statuscode = a.statuscode;
    state.verification = a.verification;
    return state;
  },
  [RESPONSE_RECOVERY_PASSWORD](state: InitialState, a: any) {
    state.resetID = a.resetID;
    return state;
  },
  [CLEAR_RESETID_STATUSCODE](state: InitialState, a: any) {
    state.statuscode = false;
    state.resetID = "";
    return state;
  },
  [TOKENACCESSSTARTED](state: InitialState, a: Action) {
    state.currentAcveID = a.payload;
    return state;
  },
});

// Selectors
const authSelector = (state: RootState) => state.auth;

export const getToken = createSelector(authSelector, (auth) => ({
  token: auth.token || "",
}));

export const getPayload = createSelector(authSelector, (auth) => ({
  payload: auth.token.payload || {},
}));

export const getPassword = createSelector(authSelector, (auth) => {
  return {
    statuscode: auth.statuscode,
    code: auth.verification || "",
    resetID: auth.resetID || "",
  };
});

export const getEmailTokenView = createSelector(authSelector, (auth) => ({
  activeTokenAccess: auth.currentAcveID,
}));

// Actions
export function requestSignin(formData: any) {
  return {
    type: REQUEST_SIGNIN,
    form: formData,
  };
}

function responseSignin(formData: any, json: any) {
  return {
    type: RESPONSE_SIGNIN,
    form: formData,
    response: json,
    receivedAt: Date.now(),
  };
}

function responseRecoveryPassword(response: any) {
  return {
    type: RESPONSE_RECOVERY_PASSWORD,
    resetID: response.acveID,
  };
}

function SaveStatusCode(status: boolean, verification: string) {
  return {
    type: SAVE_STATUS_CODE,
    statuscode: status,
    verification: verification,
  };
}

export function tokenAccessStarted(p: { email: string; acveID: string }) {
  return {
    type: TOKENACCESSSTARTED,
    payload: p,
  };
}

export function tokenSwap(formData: any, json: any) {
  let token = json.jwt ? json.jwt.replace("Bearer ", "") : "";
  if (formData?.rememberMe) {
    const t = jwt_payload(token);
    const d = new Date(t.exp * 1000);
    setCookie(COOKIENAME, token, d);
  } else {
    eraseCookie(COOKIENAME);
  }
  return {
    type: TOKENSWAP,
    form: formData,
    token,
    response: json,
    receivedAt: Date.now(),
  };
}

function failSignin(formData: any, json?: any) {
  return {
    type: FAIL_SIGNIN,
    form: formData,
    response: json,
    receivedAt: Date.now(),
  };
}

export function unauthorized() {
  eraseCookie(COOKIENAME);
  return {
    type: UNAUTHORIZED,
    receivedAt: Date.now(),
  };
}

export function login(formData: LoginForm): AppThunk<Promise<LoginResponse>> {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(dismissAllNotificationsFrom("auth"));
    dispatch(requestSignin(formData));

    return authenticate(formData)
      .then((r) => {
        dispatch(responseSignin(formData, r));
        dispatch(tokenSwap(formData, r));
        return Promise.resolve(r);
      })
      .catch((e) => {
        dispatch(
          newNotification("auth", {
            status: "error",
            message: e,
          })
        );
        dispatch(
          failSignin(formData, {
            error: [e],
          })
        );
        return Promise.reject(e);
      });
  };
}

export function loginOTP(otp: string): any {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    return otpLogin(otp)
      .then((r) => {
        dispatch(tokenSwap("", r));
      })
      .catch((e) => {
        dispatch(
          newNotification("auth", {
            status: "info",
            message: "Token inválido.",
          })
        );
        throw e;
      });
  };
}

export function recoveryPassword(e: {
  email: string;
}): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch({ type: REQUEST_RECOVERY_PASSWORD });
    dispatch({ type: CLEAR_RESETID_STATUSCODE });
    return requestPasswordRecover(e)
      .then((r) => {
        dispatch(responseRecoveryPassword(r));
        dispatch(
          newNotification("general", {
            status: "general",
            message: "Mensagem enviada!",
          })
        );
      })
      .catch((e) => {
        dispatch({
          type: RESPONSE_RECOVERY_PASSWORD,
          payload: e,
        });
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function validateVerificationCode(
  verification: string,
  resetID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return validateVerification(apiToken, resetID, verification)
      .then((r) => {
        dispatch(SaveStatusCode(r.isValid, verification));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function resetPassword(
  resetID: string,
  password: string,
  verification: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;

    const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[a-z]).{8,}$/);

    if (!passwordRegex.test(password)) {
      dispatch(
        newNotification("general", {
          status: "error",
          message: "A senha digitada não possui os critérios especificados",
        })
      );

      throw new Error("A senha digitada não possui os critérios especificados");
    }

    dispatch({ type: REQUEST_RECOVERY_PASSWORD });

    return resetToNewPassword(apiToken, resetID, password, verification).catch(
      (e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      }
    );
  };
}

export function onboardingCreate(
  formData: OnboardingDoctorForm & { privatePhoneContact?: string }
): AppThunk<Promise<any>> {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    return createNewDoctor(formData)
      .then((r) => {
        newNotification("auth", {
          status: "success",
          message: "Conta criada com sucesso! Você já pode fazer login",
        });
        dispatch(tokenSwap(formData, r));
        return setDoctorVerification("Bearer " + r.jwt, {
          ...formData.license,
          doctID: r?.user?.doctor?.doctID ?? "",
          phone: formData.privatePhoneContact,
        });
      })
      .catch((e) => {
        let errMsg = e;
        let actions: NotificationActions[] | undefined;
        let temporary = true;
        if (errMsg.indexOf("Password invalid") > -1) {
          errMsg =
            "Senha inválida. A senha deve conter 8 caracteres com ao menos um número e uma letra";
        }
        if (errMsg.indexOf("exists user") > -1) {
          temporary = false;
          errMsg = "Este email já está cadastrado.";
          actions = [
            {
              name: "Faça o login",
              url: "/",
              suffix: " ou ",
            },
            {
              name: "recupere a sua senha.",
              url: "/password_reset",
            },
          ];
        }
        dispatch(
          newNotification("auth", {
            status: "error",
            message: errMsg,
            actions,
            temporary,
          })
        );
        dispatch(
          failSignin(formData, {
            error: [e],
          })
        );
        return Promise.reject(e);
      });
  };
}

export function tokenAcessRequest(email: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return emailTokenRequest(email)
      .then((res) => {
        dispatch(tokenAccessStarted({ acveID: res.acveID, email }));
      })
      .catch((e) => {
        dispatch(
          newNotification("auth", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function tokenAcessSignin(
  acveID: string,
  verification: string,
  rememberMe: boolean
): AppThunk<Promise<void>> {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    return emailTokenSignin({ acveID, verification })
      .then((r) => {
        dispatch(responseSignin({ email: r.user.email }, r));
        dispatch(tokenSwap({ email: r.user.email, rememberMe }, r));
      })
      .catch((e) => {
        dispatch(
          newNotification("auth", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function validateEmail(
  email: string
): AppThunk<Promise<EmailValidateStatus | undefined>> {
  return async (dispatch, getState) => {
    return emailVerification("doctor", email)
      .then((r) => {
        return r;
      })
      .catch((e) => {
        throw e;
      });
  };
}
