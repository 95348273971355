import axios from "axios";
import {
  AppointmentFile,
  AppointmentFileForm,
  FilterAppointmentFile,
} from "@udok/lib/api/models";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";

export const createAppointmentFile = async (
  apiToken: string,
  data: AppointmentFileForm
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/appointment-file`, data, {
      headers: { Authorization: apiToken },
    })
    .then((r) => {
      return r.data.data.item as AppointmentFile;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APF1");
      return Promise.reject(new Error(err));
    });
};

export const fetchAppointmentFile = async (
  apiToken: string,
  appoID: string,
  filter?: FilterAppointmentFile
) => {
  const q = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointment-file/${appoID}${q}`, {
      headers: {
        Authorization: apiToken,
      },
    })
    .then((r) => {
      return r.data.data.items as AppointmentFile[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APF2");
      return Promise.reject(new Error(err));
    });
};

export const deleteAppointmentFile = async (
  token: string,
  appoID: string,
  fileID: string
) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/appointment-file/${appoID}/${fileID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentFile;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "APF3");
      return Promise.reject(err);
    });
};
