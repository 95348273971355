import * as React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { ScheduleType } from "@udok/lib/api/models";
import { StepItem, StepStatus } from "@udok/lib/components/Stepper";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import StepItemView, {
  Action,
  StepItemDialog,
} from "containers/OnboardingStepper/StepItemView";
import EditForm from "containers/Schedule/Edit";
import CreateForm from "containers/Schedule/Create";
import Delete from "containers/Schedule/Delete";
import Icons from "@udok/lib/components/Icon";
import Illustration from "@udok/lib/components/Illustrations";
import { useGetOneSchedule } from "hooks/schedule";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepperButton: {
      width: "100%",
      borderRadius: 25,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    alert: {
      margin: theme.spacing(0, 3, 1),
      cursor: "pointer",
      color: theme.palette.neutral.dark,
      border: `1px solid ${theme.palette.neutral.dark}`,
      borderRadius: theme.spacing(1),
      "&:hover": {
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
      },
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(0, 1, 2),
      },
    },
  })
);

type ManageSchedulesProps = {
  sescID?: string;
  locaID?: string;
  open: boolean;
  close: (success: boolean) => void;
};

export const ManageSchedules = (props: ManageSchedulesProps) => {
  const { open, close, sescID, locaID } = props;
  const classes = useStyles();

  const handleSuccess = React.useCallback(() => {
    close(true);
  }, [close]);

  const handleClose = React.useCallback(() => {
    close(false);
  }, [close]);

  return (
    <StepItemDialog
      open={open}
      onClose={handleClose}
      title="Gerenciar horários"
    >
      <>
        {!sescID ? (
          <CreateForm
            onSubmit={handleSuccess}
            initialValues={{ type: ScheduleType.place, locaID }}
          />
        ) : (
          <>
            <Delete
              resourceID={sescID}
              onDelete={handleClose}
              component={Alert}
              title="Remover horário"
              description="Você realmente deseja apagar este horário?"
              className={classes.alert}
              variant="outlined"
              severity="error"
              icon={<Icons.Delete color="inherit" />}
            />
            <EditForm sescID={sescID} onSubmit={handleClose} />
          </>
        )}
      </>
    </StepItemDialog>
  );
};

const [rendererForm, promiseForm] = createModal(ManageSchedules);

const ScheduleStep = ({
  step,
  sescID,
  locaID,
  handleNext,
  handleComplet,
}: {
  sescID?: string;
  locaID?: string;
  step?: StepItem;
  handleNext: () => void;
  handleComplet: () => void;
}) => {
  const classes = useStyles();
  const [, schedule] = useGetOneSchedule(sescID ?? "");

  const handleClick = React.useCallback(
    (values?: { sescID?: string; locaID?: string }) => {
      promiseForm(values).then((resp) => {
        if (resp) {
          handleComplet();
        }
      });
    },
    [handleComplet]
  );

  const action = {
    create: {
      label: "Adicionar novo horário",
      onClick: () => handleClick({ locaID }),
    } as Action,
    edit: {
      label: "Ajustar agenda de telemedicina",
      onClick: () => handleClick({ sescID }),
      buttonProps: {
        variant: "outlined",
        color: "primary",
        className: classes.stepperButton,
      },
    } as Action,
    complet: {
      label: "Continuar",
      onClick: handleNext,
      color: "success",
      buttonProps: {
        startIcon: <Icons.CheckCircleOutline fontSize="large" />,
      },
    } as Action,
    skip: {
      label: "Pular etapa",
      onClick: handleNext,
      buttonProps: {
        variant: "text",
        color: "inherit",
        className: classes.stepperButton,
      },
    } as Action,
  };
  return (
    <>
      <StepItemView
        illustration={
          <Illustration.DoctorSchedule width="30em" height="auto" />
        }
        text="Você já possui um horário de telemedicina pré-configurado. Adicione um novo horário para o agendamento de consultas presenciais."
        actions={[
          action.create,
          ...(!!schedule && !schedule?.deletedAt ? [action.edit] : []),
          ...(step?.state === StepStatus.completed
            ? [action.complet]
            : [action.skip]),
        ]}
      />
      {rendererForm}
    </>
  );
};

export default ScheduleStep;
