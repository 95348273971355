import * as React from "react";
import { StepItem, StepStatus } from "@udok/lib/components/Stepper";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import StepItemView, {
  Action,
  StepItemDialog,
} from "containers/OnboardingStepper/StepItemView";
import EditForm from "containers/User/Edit";
import Icons from "@udok/lib/components/Icon";
import Illustration from "@udok/lib/components/Illustrations";

type EditUserProps = {
  open: boolean;
  close: (success: boolean) => void;
};

export const EditUser = (props: EditUserProps) => {
  const { open, close } = props;

  const handleSuccess = React.useCallback(() => {
    close(true);
  }, [close]);

  const handleClose = React.useCallback(() => {
    close(false);
  }, [close]);

  return (
    <StepItemDialog open={open} onClose={handleClose} title="Atualizar perfil">
      <EditForm onSuccess={handleSuccess} />
    </StepItemDialog>
  );
};

const [rendererForm, promiseForm] = createModal(EditUser);

const ProfileStep = ({
  step,
  handleNext,
  handleComplet,
}: {
  step?: StepItem;
  handleNext: () => void;
  handleComplet: () => void;
}) => {
  const handleClick = React.useCallback(() => {
    promiseForm().then((complet: boolean) => {
      if (complet) {
        handleComplet();
      }
    });
  }, [handleComplet]);

  const handleContinue = React.useCallback(() => {
    handleNext();
  }, [handleNext]);

  const action = {
    create: {
      label: "Atualizar perfil",
      onClick: handleClick,
    } as Action,
    complet: {
      label: "Continuar",
      onClick: handleContinue,
      color: "success",
      buttonProps: {
        startIcon: <Icons.CheckCircleOutline fontSize="large" />,
      },
    } as Action,
  };

  return (
    <>
      <StepItemView
        text="Complete as informações do seu perfil para torná-lo mais atrativo aos pacientes."
        illustration={
          <Illustration.ProfessionalProfile width="30em" height="auto" />
        }
        actions={
          step?.state === StepStatus.completed
            ? [action.complet]
            : [action.create]
        }
      />
      {rendererForm}
    </>
  );
};

export default ProfileStep;
