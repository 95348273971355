import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import {
  fetchCachedCreditCard,
  oneCreditCardView,
  loadCreditCards,
  creditCardsView,
} from "ducks/billing";
import {
  fetchOneDoctorPreference,
  getOneDoctorPreferences,
} from "ducks/doctorPreferences";
import { Preferences } from "@udok/lib/api/models";

export const useGetCreditCardView = (crcaID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (crcaID) {
      setLoading(true);
      Promise.all([
        dispatch(fetchCachedCreditCard(crcaID)),
        dispatch(
          fetchOneDoctorPreference(Preferences.financesDefaultCreditCard)
        ),
      ]).finally(() => setLoading(false));
    }
  }, [dispatch, crcaID]);

  const getCard = React.useCallback(oneCreditCardView({ crcaID }), [crcaID]);
  const getPerf = React.useCallback(
    getOneDoctorPreferences({ prefID: Preferences.financesDefaultCreditCard }),
    []
  );
  const { creditCard } = useSelector(getCard);
  const { location } = useSelector(getPerf);

  return [loading, creditCard, location] as [
    typeof loading,
    typeof creditCard,
    typeof location
  ];
};

export const useListCreditCard = () => {
  const [loading, setLoading] = React.useState(true);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(loadCreditCards()),
      dispatch(fetchOneDoctorPreference(Preferences.financesDefaultCreditCard)),
    ]).finally(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, [dispatch]);

  const getPerf = React.useCallback(
    getOneDoctorPreferences({ prefID: Preferences.financesDefaultCreditCard }),
    []
  );

  const { list } = useSelector(creditCardsView);
  const { location } = useSelector(getPerf);

  return [loading, list, location] as [
    typeof loading,
    typeof list,
    typeof location
  ];
};
