import React from "react";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { configureStore } from "store";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Main from "screens";

// prepare store
const history = createBrowserHistory();
const { store, persistor } = configureStore(history);

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Switch>
            <Route path="/" component={Main} />
          </Switch>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
