import * as React from "react";
import { StepItem, StepStatus } from "@udok/lib/components/Stepper";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import StepItemView, {
  Action,
  StepItemDialog,
} from "containers/OnboardingStepper/StepItemView";
import CreateForm from "containers/Location/Create";
import Icons from "@udok/lib/components/Icon";
import Illustration from "@udok/lib/components/Illustrations";

type CreateLocationProps = {
  open: boolean;
  close: (locaID?: string) => void;
};

export const CreateLocation = (props: CreateLocationProps) => {
  const { open, close } = props;

  const handleSuccess = React.useCallback(
    (locaID?: string) => {
      close(locaID);
    },
    [close]
  );

  const handleClose = React.useCallback(() => {
    close();
  }, [close]);

  return (
    <StepItemDialog
      open={open}
      onClose={handleClose}
      title="Adicionar endereço"
    >
      <CreateForm
        onSubmit={(loc) => handleSuccess(loc?.locaID)}
        initialValues={{
          isDefault: true,
        }}
      />
    </StepItemDialog>
  );
};

const [rendererForm, promiseForm] = createModal(CreateLocation);

const LocationStep = ({
  step,
  handleNext,
  handleComplet,
}: {
  step?: StepItem;
  handleNext: () => void;
  handleComplet: (locaID?: string) => void;
}) => {
  const handleClick = React.useCallback(() => {
    promiseForm().then((locaID?: string) => {
      if (!!locaID) {
        handleComplet(locaID);
      }
    });
  }, [handleComplet]);

  const handleContinue = React.useCallback(() => {
    handleNext();
  }, [handleNext]);

  const action = {
    create: {
      label: "Adicionar endereço",
      onClick: handleClick,
    } as Action,
    complet: {
      label: "Continuar",
      onClick: handleContinue,
      color: "success",
      buttonProps: {
        startIcon: <Icons.CheckCircleOutline fontSize="large" />,
      },
    } as Action,
  };

  return (
    <>
      <StepItemView
        text="Cadastre seu endereço de atendimento para poder utilizá-lo em suas prescrições digitais e agendamentos de consulta presenciais."
        illustration={<Illustration.MyLocation width="30em" height="auto" />}
        actions={
          step?.state === StepStatus.completed
            ? [action.complet]
            : [action.create]
        }
      />
      {rendererForm}
    </>
  );
};

export default LocationStep;
