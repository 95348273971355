import React from "react";
import { useListCreditCard } from "hooks/billing";
import {
  Button,
  createStyles,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  IconButton,
} from "@material-ui/core";
import { CreditCard } from "@udok/lib/api/models";
import { format } from "@udok/lib/internal/util";
import Icons from "@udok/lib/components/Icon";
import Create, {
  CreateCreditCardProps,
} from "containers/Finances/CreditCard/Create";
import Delete from "containers/Finances/CreditCard/Delete";

import moment from "moment";
moment.locale("pt-br");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    itemIcon: {
      minWidth: theme.spacing(3),
    },
    nextButton: {
      marginTop: theme.spacing(1),
    },
    listItemGutters: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
      },
    },
    formContainer: {
      width: "100%",
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
      },
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "end",
    },
    buttonDelete: {
      color: theme.palette.error.main,
    },
  })
);

export enum ReturnTypeKeys {
  string = "string",
  CreditCard = "CreditCard",
}

export type ReturnType = {
  [ReturnTypeKeys.string]: string;
  [ReturnTypeKeys.CreditCard]: CreditCard | undefined;
};

type CreditCardPickerProps = {
  returnType?: keyof typeof ReturnTypeKeys;
  value?: string | CreditCard;
  onSelected?: (value?: string | CreditCard) => void;
  formProps?: Omit<CreateCreditCardProps, "onCreated">;
  loading?: boolean;
  list: CreditCard[];
  preferredCardID?: string;
};

const DEFAULT_OPTION = "new";

export const CreditCardPicker = React.forwardRef(
  (props: CreditCardPickerProps, ref: React.Ref<any>) => {
    const {
      returnType = "string",
      onSelected,
      value,
      formProps,
      loading,
      list,
      preferredCardID,
    } = props;
    const [option, setOption] = React.useState(DEFAULT_OPTION);
    const classes = useStyles();
    const crcaIDValue = typeof value === "string" ? value : value?.crcaID;

    const options = [
      {
        title: "Novo cartão de crédito",
        description: "",
        crcaID: DEFAULT_OPTION,
      },
      ...list.map((cc) => ({
        title: `Cartão terminado em •••• ${cc.name}`,
        description: `Válido até ${moment(cc.expiresAt).format(
          format.MONYEAR
        )}`,
        crcaID: cc.crcaID,
      })),
    ];

    const handleSelect = React.useCallback(() => {
      if (option === DEFAULT_OPTION) {
        return;
      }
      const card = list.find((c) => c.crcaID === option);
      const values: ReturnType = {
        [ReturnTypeKeys.string]: option,
        [ReturnTypeKeys.CreditCard]: card,
      };

      onSelected?.(values[returnType]);
    }, [returnType, option, list, onSelected]);

    const handleDelete = React.useCallback(
      (crcaID: string) => {
        if (crcaID === crcaIDValue) {
          onSelected?.(undefined);
        }
      },
      [crcaIDValue, onSelected]
    );

    const handleCCCreated = React.useCallback((cc: CreditCard) => {
      setOption(cc.crcaID);
    }, []);

    React.useEffect(() => {
      if (!loading) {
        const starterCard =
          list.find((c) => c.crcaID === (crcaIDValue ?? preferredCardID)) ??
          list?.[(list?.length ?? 1) - 1];
        setOption((option) => {
          if (list?.length === 0) {
            return DEFAULT_OPTION;
          }
          if (option === DEFAULT_OPTION && !!starterCard?.crcaID) {
            return starterCard.crcaID;
          }
          return option;
        });
      }
    }, [list, preferredCardID, crcaIDValue, loading]);

    return (
      <div ref={ref} className={classes.root}>
        {loading ? <LinearProgress /> : null}
        <div style={{ width: "100%" }}>
          <RadioGroup
            aria-label="card"
            name="selected-card"
            value={option}
            onChange={(e) => {
              setOption(e.target.value);
            }}
          >
            <List>
              {options
                .map((o) => (
                  <ListItem
                    key={o.crcaID}
                    dense
                    button
                    onClick={() => setOption(o.crcaID)}
                    className={classes.listItemGutters}
                  >
                    <ListItemIcon className={classes.itemIcon}>
                      <Radio
                        edge="start"
                        value={o.crcaID}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": `option-list-${o.crcaID}`,
                        }}
                        color={o.crcaID === option ? "primary" : "default"}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={`option-list-${o.crcaID}`}
                      primary={o.title}
                      secondary={o.description}
                    />
                    <ListItemSecondaryAction>
                      {o.crcaID === DEFAULT_OPTION ? null : (
                        <Delete
                          className={classes.buttonDelete}
                          resourceID={o.crcaID}
                          title={<Icons.Delete />}
                          dialogTitle="Remover cartão"
                          description="Tem certeza que deseja remover esse cartão?"
                          onDelete={handleDelete}
                          component={IconButton}
                        />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
                .reverse()}
            </List>
          </RadioGroup>
        </div>
        {option === DEFAULT_OPTION ? (
          <div className={classes.formContainer}>
            <Create onCreated={handleCCCreated} {...formProps} />
          </div>
        ) : (
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.nextButton}
              onClick={handleSelect}
            >
              Continuar
            </Button>
          </div>
        )}
      </div>
    );
  }
);

const ConnectedCreditCardPicker = (
  props: Omit<CreditCardPickerProps, "list" | "loading" | "preferredCardID">
) => {
  const [loading, list, pref] = useListCreditCard();
  return (
    <CreditCardPicker
      list={list}
      loading={loading}
      preferredCardID={pref?.options?.crcaID}
      {...props}
    />
  );
};

export default ConnectedCreditCardPicker;
