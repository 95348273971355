import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import { DoctorNote, FilterDoctorNote } from "@udok/lib/api/models";

export const createDoctorNote = async (token: string, note: DoctorNote) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/doctor-notes`, note, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as DoctorNote;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "DON1");
      return Promise.reject(new Error(err));
    });
};

export const fetchDoctorNotes = (token: string, filter?: FilterDoctorNote) => {
  const f = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/doctor-notes${f}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as DoctorNote[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "DON2");
      return Promise.reject(new Error(err));
    });
};

export const fetchDoctorNote = (token: string, donoID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/doctor-notes/${donoID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as DoctorNote;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "DON3");
      return Promise.reject(new Error(err));
    });
};

export const updateDoctorNote = async (
  token: string,
  donoID: string,
  text: string
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/doctor-notes/${donoID}`,
      { text },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as DoctorNote;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "DON4");
      return Promise.reject(new Error(err));
    });
};
