import * as React from "react";
import { WithStyles, withStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme: Theme) => ({
  root: {},
  button: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.neutral.lightest,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
});

export type DeleteProps<C extends React.ElementType> = {
  doDelete: (resourceID: string) => Promise<void>;
  onDelete: (resourceID: string) => void;
  resourceID?: string;
  title: string | React.ReactNode | React.ReactNodeArray;
  dialogTitle?: string | React.ReactNode | React.ReactNodeArray;
  description: React.ReactNode;
  disabled?: boolean;
  component?: C;
} & WithStyles<typeof styles> &
  Omit<React.ComponentProps<C>, "options">;
export interface DeleteState {
  confirm: boolean;
  loading: boolean;
}

export const Delete = <C extends React.ElementType>(props: DeleteProps<C>) => {
  const {
    classes,
    title,
    dialogTitle,
    description,
    component,
    disabled,
    resourceID,
    onDelete,
    doDelete,
    ...others
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);

  const handleOk = () => {
    const rid = resourceID;
    if (!rid) {
      return;
    }
    setLoading(true);
    setConfirm(false);
    doDelete(rid)
      .then(() => {
        onDelete(rid);
      })
      .finally(() => setLoading(false))
      .catch(console.warn);
  };

  const BTN = component ?? Button;
  return (
    <div className={classes.root}>
      <BTN
        variant="contained"
        color="secondary"
        {...others}
        className={others?.className || classes.button}
        onClick={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
          setConfirm(true);
        }}
        disabled={!resourceID || loading || disabled}
      >
        {title}
        {loading && <CircularProgress size={24} />}
      </BTN>
      <Dialog
        open={confirm}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">
          {dialogTitle || title}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirm(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleOk} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(Delete);
