import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { Drug } from "@udok/lib/api/models";
import { fetchDrugs } from "@udok/lib/api/drug";
import { autoCompletDrugRepository } from "ducks/prescription";
import { getToken, UNAUTHORIZED } from "./auth";
import axios from "axios";

export type InitialState = {
  drugByID: { [patiID: string]: Drug };
  filtredDrugList: string[];
  lastFetchAt: number;
};

// Reducers
const initialState: InitialState = {
  drugByID: {},
  lastFetchAt: 0,
  filtredDrugList: [],
};

class DrugSlice extends Hen<InitialState> {
  drugsLoaded(v: Drug[], n: number) {
    let list: string[] = [];
    this.state.lastFetchAt = n;
    v.forEach((s) => {
      if (s.drugID) {
        list = [...list, s.drugID];
      }
      this.state.drugByID[String(s.drugID)] = s;
    });
    this.state.filtredDrugList = list;
  }
}

export const [Reducer, actions] = hen(new DrugSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.drug;

export const drugRepository = (state: RootState) =>
  mainSelector(state).drugByID;
const filtredDrugListRepository = (state: RootState) =>
  mainSelector(state).filtredDrugList;

export const drugListView = createSelector(
  [filtredDrugListRepository, drugRepository, autoCompletDrugRepository],
  (filtredDrugList, drugByID, autoCompletDrug) => {
    const drugList = filtredDrugList.map((patiID) => drugByID[patiID]);
    let list = drugList;
    if (drugList.length < 5) {
      const nonUdokDrugs = autoCompletDrug
        .filter((acd) => !acd.drugID)
        .filter(
          (acd, i) =>
            autoCompletDrug.findIndex((a) => a.drugName === acd.drugName) === i
        )
        .map(
          (acd) =>
            ({
              title: acd.drugName,
              type: acd.drugType,
              schedule: acd.drugType,
            } as Drug)
        );
      list = [...drugList, ...nonUdokDrugs];
    }
    return {
      list,
    };
  }
);

// Actions
export function loadDrugs(filter?: {
  searchText?: string;
  limit?: number;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const now = new Date().getTime();

    const f = {
      limit: 100,
      ...filter,
    };
    return fetchDrugs(apiToken, f)
      .then((r) => {
        dispatch(actions.drugsLoaded(r, now));
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
