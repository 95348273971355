import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import {
  createOneBillingInformation,
  updateOneBillingInformation,
  activateOneSubscription,
} from "ducks/user";
import { CustomerAddress, BillingInformationForm } from "@udok/lib/api/models";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  CircularProgress,
  Link,
  Collapse,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Illustrations from "@udok/lib/components/Illustrations";
import AlertDialog from "@udok/lib/components/Dialog/AlertDialog";
import { generateWhatsappURL } from "@udok/lib/internal/util";
import {
  UdokSupportContactNumber,
  ErroDeadlineForChangeSubscription,
} from "@udok/lib/internal/constants";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > *": {
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.down("sm")]: {
        "& > h2": {
          display: "none",
        },
      },
    },
    illustration: {
      flex: 1,
      display: "flex",
      paddingRight: theme.spacing(2),
      maxHeight: "45vh",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    errorContainer: {
      maxWidth: 500,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "&> :first-child": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        marginBottom: theme.spacing(1),
      },
    },
    link: {
      textDecoration: "underline",
    },
  })
);

type Error = { message: React.ReactNode; details?: string };

type ConfirmSubscriptionProps = {
  suofID: string;
  crcaID?: string;
  biinID?: string;
  free?: boolean;
  selectedAddress?: CustomerAddress;
  selectedInfo?: Omit<BillingInformationForm, "billingAddress">;
  loading: boolean;
  setLoading: (l: boolean) => void;
  onConfirm?: () => void;
};

const [rendererAlertDialog, promiseAlertDialog] = createModal(
  ({
    description,
    open,
    close,
  }: {
    description: React.ReactNode;
    open: boolean;
    close: () => void;
  }) => (
    <AlertDialog
      description={description}
      visible={open}
      onClose={close}
      rejectionButton={false}
      acceptText="Ok"
    />
  )
);

const ConfirmSubscription = (props: ConfirmSubscriptionProps) => {
  const {
    suofID,
    crcaID,
    biinID,
    free,
    selectedAddress,
    selectedInfo,
    loading,
    setLoading,
    onConfirm,
  } = props;
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const [err, setErr] = React.useState<Error>();

  const validatePaymentInformation = () => {
    if (free) {
      return true;
    }
    if (!crcaID) {
      setErr({ message: "Verifique se o cartão de crédito foi selecionado." });
      return false;
    }
    if (!selectedInfo) {
      setErr({ message: "Verifique seus dados para cobranças." });
      return false;
    }
    if (!selectedAddress) {
      setErr({ message: "Verifique seu endereço para cobranças." });
      return false;
    }
    return true;
  };

  const changePaymentInformation = () => {
    const info: BillingInformationForm = {
      ...selectedInfo!,
      billingAddress: selectedAddress!,
    };
    if (biinID) {
      return dispatch(updateOneBillingInformation(info));
    }
    return dispatch(createOneBillingInformation(info));
  };

  const activateSubscription = (form: { suofID: string; crcaID?: string }) => {
    return dispatch(activateOneSubscription(form))
      .then(onConfirm)
      .catch((e) => {
        let errMsg: React.ReactNode = "Falha ao ativar assinatura.";
        if (
          ((e as any)?.message ?? "").indexOf(
            ErroDeadlineForChangeSubscription
          ) > -1
        ) {
          errMsg = (
            <span>
              <span>Para atualizar sua assinatura entre em contato com o </span>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={generateWhatsappURL(UdokSupportContactNumber)}
                className={classes.link}
              >
                suporte da Udok
              </Link>
              <span>.</span>
            </span>
          );
          promiseAlertDialog({ description: errMsg });
        }
        setErr({
          message: errMsg,
          details: (e as any)?.message,
        });
        console.warn(e);
      });
  };

  const handleSubmit = async () => {
    setErr(undefined);
    if (!validatePaymentInformation()) {
      return;
    }
    setLoading(true);
    if (free) {
      activateSubscription({ suofID }).finally(() => setLoading(false));
      return;
    }
    changePaymentInformation()
      .then(async () => {
        await activateSubscription({ suofID, crcaID });
      })
      .catch((e) => {
        setErr({
          message: "Falha ao atualizar informações de pagamento.",
          details: (e as any)?.message,
        });
        console.warn(e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2" color="primary" style={{ fontSize: 32 }}>
        Confirmar assinatura!
      </Typography>
      <Typography variant="body1" color="textPrimary">
        Confirme os dados informados para finalizar a contratação do plano.
      </Typography>
      <div className={classes.illustration}>
        <Illustrations.OnlineDoctor width="30em" height="auto" />
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          endIcon={loading ? <CircularProgress size={25} /> : undefined}
        >
          Confirmar
        </Button>
      </div>
      <RenderError err={err} />
      {rendererAlertDialog}
    </div>
  );
};

const RenderError = ({ err }: { err?: Error }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return !!err ? (
    <div className={classes.errorContainer}>
      <div>
        <Typography variant="body1" color="error">
          {err.message}
        </Typography>
        {err?.details ? (
          <Link
            component="button"
            variant="caption"
            color="textSecondary"
            onClick={() => setOpen(!open)}
          >
            Detalhes do erro
          </Link>
        ) : null}
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Alert severity="error">{err?.details}</Alert>
      </Collapse>
    </div>
  ) : null;
};

export default ConfirmSubscription;
