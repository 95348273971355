import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import {
  fetchOneDoctorPreference,
  getOneDoctorPreferences,
} from "ducks/doctorPreferences";
import { Preferences } from "@udok/lib/api/models";

export const useGetOneDoctorPreference = (prefID: Preferences) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchOneDoctorPreference(prefID)).finally(() => setLoading(false));
  }, [prefID, dispatch]);

  const getPreference = React.useCallback(getOneDoctorPreferences({ prefID }), [
    prefID,
  ]);
  const { location } = useSelector(getPreference);

  return [loading, location] as [typeof loading, typeof location];
};
