import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import { BillingInformationForm } from "@udok/lib/api/models";
import {
  onlyNumbers,
  breakPhone,
  removeNamePrefixes,
} from "@udok/lib/internal/util";
import { getDocumentType } from "components/DocumentTypePicker";
import FormPersonalInformation, {
  InitialValues,
} from "containers/Finances/BillingInformation/FormPersonalInformation";
import { getUserMe } from "ducks/user";
// @ts-ignore
import createDOMForm from "rc-form/lib/createDOMForm";
// @ts-ignore
import { formShape } from "rc-form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "end",
      marginTop: theme.spacing(2),
    },
  })
);

export type BillingInformationProps = {
  onSubmit: (a: Omit<BillingInformationForm, "billingAddress">) => void;
  initialValues?: Omit<Partial<BillingInformationForm>, "billingAddress">;
  form?: formShape;
};

const EditSubscriptionInfo = (props: BillingInformationProps) => {
  const { form, initialValues: valuesProps, onSubmit } = props;
  const { currentUser } = useSelector(getUserMe);
  const theme = useTheme();
  const classes = useStyles();

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      form.validateFieldsAndScroll(
        { scroll: { offsetTop: theme.spacing(10) } },
        (err: any, values: any) => {
          if (err) {
            return;
          }
          const val: Omit<BillingInformationForm, "billingAddress"> = {
            name: values.name,
            email: values.email,
            documentNumber: onlyNumbers(values.documentNumber),
            billingPhone: breakPhone(values.phone),
          };
          onSubmit(val);
        }
      );
    },
    [theme, form, onSubmit]
  );

  const initialValues: InitialValues = !!valuesProps
    ? {
        ...valuesProps,
        documentType: getDocumentType(valuesProps?.documentNumber ?? ""),
      }
    : {
        name: removeNamePrefixes(currentUser?.doctor?.name ?? ""),
        email: currentUser?.user?.email,
      };

  return (
    <form onSubmit={handleSubmit}>
      <FormPersonalInformation initialValues={initialValues} form={form} />
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" type="submit">
          <span>Continuar</span>
        </Button>
      </div>
    </form>
  );
};

export default createDOMForm()(
  EditSubscriptionInfo
) as typeof EditSubscriptionInfo;
