import * as React from "react";
import {
  TextFieldProps,
  IconButton,
  Button,
  Toolbar,
  Typography,
  TypographyProps,
  FormHelperText,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Selector from "@udok/lib/components/Selector";
import AutoCompletePhone from "@udok/lib/components/Input/AutoCompletePhone";
import Icons from "@udok/lib/components/Icon";
import {
  ContactPhone,
  ContactPhoneApps,
  getPhoneAppsLabel,
} from "@udok/lib/api/models";
import { onlyNumbers } from "@udok/lib/internal/util";

import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      padding: 0,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    listContainer: {
      backgroundColor: "#f8f8f8",
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3, 3, 2),
      "& > *:nth-child(n+2)": {
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2, 2, 1),
      },
    },
    inputContainer: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      alignItems: "center",
      position: "relative",
      paddingRight: 35,
      maxWidth: 565,
      "& > *:first-child": {
        marginRight: theme.spacing(1),
      },
      "& > *": {
        marginBottom: theme.spacing(1),
      },
    },
    inputContact: {
      minWidth: 252,
    },
    cleanButton: {
      position: "absolute",
      right: 1,
      padding: theme.spacing(1),
      opacity: 0.5,
      height: `calc( 25px + ${theme.spacing(1)}px )`,
      width: `calc( 25px + ${theme.spacing(1)}px )`,
    },
    helperText: {
      margin: theme.spacing(0, 1.75),
    },
  })
);

export type ContactsInputProps = {
  onChange?: (value: ContactPhone[]) => void;
  value?: ContactPhone[];
  title?: string | React.ReactNode;
  errors?: string[];
  buttonLabel?: string;
  phoneFieldProps?: Omit<TextFieldProps, "onChange" | "value">;
  typographyProps?: TypographyProps;
  clases?: {
    toolbar?: string;
    listContainer?: string;
    inputPhone?: string;
    inputApps?: string;
  };
};
const DefaultContact: ContactPhone = { phone: "", linkedApps: [] };
const ContactsInput = React.forwardRef(
  (props: ContactsInputProps, ref: React.Ref<any>) => {
    const {
      value,
      onChange,
      title,
      phoneFieldProps,
      typographyProps,
      errors,
      buttonLabel = "Novo telefone",
      clases: classesPorps,
    } = props;
    const [contacts, setContacts] = React.useState<ContactPhone[]>([]);
    const classes = useStyles();

    React.useEffect(() => {
      if (!!value && value.length > 0 && contacts.length === 0) {
        setContacts(value);
      }
    }, [value, contacts]);

    const handleChange = React.useCallback(
      (val: ContactPhone[]) => {
        onChange?.(val.filter((p) => !!onlyNumbers(p.phone)));
      },
      [onChange]
    );
    const updateContacts = React.useCallback(
      (phone: ContactPhone, index: number) => {
        setContacts((contacts) => {
          let newList = [...contacts];
          if (!!newList[index]) {
            newList[index] = phone;
          } else {
            newList = [...newList, phone];
          }
          handleChange(newList);
          return newList;
        });
      },
      [handleChange]
    );
    const clearContact = React.useCallback(
      (index: number) => {
        setContacts((contacts) => {
          let newList = [...contacts];
          if (!!newList[index]) {
            newList.splice(index, 1);
          }
          handleChange(newList);
          return newList;
        });
      },
      [handleChange]
    );
    const addNewContact = React.useCallback(() => {
      setContacts((contacts) => [...contacts, DefaultContact]);
    }, []);

    const contactList = React.useMemo(
      () => (contacts?.length === 0 ? [DefaultContact] : contacts),
      [contacts]
    );
    return (
      <div ref={ref}>
        <Toolbar className={clsx(classes.toolbar, classesPorps?.toolbar)}>
          <Typography {...typographyProps}>{title ?? ""}</Typography>
        </Toolbar>
        <div
          className={clsx(classes.listContainer, classesPorps?.listContainer)}
        >
          {contactList.map((cont, i) => (
            <Input
              key={i}
              value={cont}
              onChange={(v) => updateContacts(v, i)}
              onClear={() => clearContact(i)}
              clearable={i != 0}
              error={!!errors?.[i]}
              helperText={errors?.[i]}
              clases={classesPorps}
              {...phoneFieldProps}
            />
          ))}
          <div>
            <Button
              variant="text"
              color="primary"
              onClick={addNewContact}
              startIcon={<Icons.AddPhone />}
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

const Input = ({
  value,
  onChange,
  onClear,
  clearable,
  error,
  helperText,
  clases: classesProps,
  ...phoneFieldProps
}: {
  value?: ContactPhone;
  onChange?: (val: ContactPhone) => void;
  onClear?: () => void;
  clearable?: boolean;
  clases?: {
    inputPhone?: string;
    inputApps?: string;
  };
} & Omit<TextFieldProps, "onChange" | "value">) => {
  const classes = useStyles();
  const handleChangePhone = React.useCallback(
    (phone: string) => {
      onChange?.({ phone, linkedApps: value?.linkedApps ?? [] });
    },
    [onChange, value]
  );
  const handleChangeApps = React.useCallback(
    (linkedApps: string[]) => {
      onChange?.({ phone: value?.phone ?? "", linkedApps });
    },
    [onChange, value]
  );
  const selectorList = React.useMemo(
    () =>
      Object.keys(ContactPhoneApps).map((key) => {
        const k = key as keyof typeof ContactPhoneApps;
        return {
          value: ContactPhoneApps[k],
          label: getPhoneAppsLabel(ContactPhoneApps[k]),
        };
      }),
    []
  );
  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <div className={classes.inputContainer}>
        <AutoCompletePhone
          label="Celular"
          margin="none"
          variant="outlined"
          size="small"
          value={value?.phone ?? ""}
          onChange={handleChangePhone}
          className={clsx(classes.inputContact, classesProps?.inputPhone)}
          error={error}
          {...phoneFieldProps}
        />
        <Selector
          label="Apps vinculados"
          variant="outlined"
          margin="none"
          size="small"
          list={selectorList}
          value={value?.linkedApps ?? []}
          onChange={handleChangeApps}
          error={error}
          multiple
          SelectProps={{
            className: clsx(classes.inputContact, classesProps?.inputApps),
          }}
          {...phoneFieldProps}
        />
        {clearable ? (
          <IconButton className={classes.cleanButton} onClick={onClear}>
            <Icons.Delete fontSize="small" />
          </IconButton>
        ) : null}
      </div>
      <FormHelperText error={error} className={classes.helperText}>
        {helperText}
      </FormHelperText>
    </div>
  );
};

export default ContactsInput;
