import React from "react";
import OnBeforeUnload from "./OnBeforeUnload";

const ConfirmNavigationWhenDirty = ({ form }: { form: any }) => {
  const isDirty =
    Object.keys(form.getFieldsValue()).filter((x) => form.isFieldTouched(x))
      .length > 0;

  return (
    <OnBeforeUnload
      disabled={!isDirty}
      message="Você não salvou as alterações. Tem certeza que quer sair?"
    />
  );
};

export default ConfirmNavigationWhenDirty;
