import React from "react";
import {
  OutlinedInput,
  Select,
  SelectProps,
  MenuItem,
  LinearProgress,
  Input,
  InputLabel,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Icons from "@udok/lib/components/Icon";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectSVGIcon: {
      "& > svg": {
        display: "none",
      },
    },
  })
);

enum Variant {
  outlined = "outlined",
}

export interface SelectorProps {
  onChange?: (value?: any) => void;
  list: Array<{ label: string; value: string | React.ReactText | number }>;
  error?: boolean;
  errorMessage?: string;
  value?: Array<string> | string;
  style?: React.CSSProperties;
  fullWidth?: SelectProps["fullWidth"];
  label?: string;
  placeholder?: string | React.ReactNode;
  clearable?: boolean;
  SelectProps?: SelectProps;
  loading?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  variant?: "outlined";
  margin?: "dense" | "none" | "normal";
  size?: "small" | "medium";
  className?: string;
  classes?: {
    container?: string;
  };
  formControlProps?: FormControlProps;
}

const CustomPicker = ({
  style,
  multiple,
  list,
  error,
  errorMessage,
  value,
  label,
  fullWidth,
  disabled,
  placeholder,
  clearable,
  SelectProps,
  loading = false,
  variant,
  size,
  className,
  onChange,
  margin,
  classes,
  formControlProps,
}: SelectorProps) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };
  const styles = useStyles();

  const shrinker = (v: any): object => {
    return (
      (Boolean(v) &&
        (Array.isArray(v) ? v.length > 0 : true) && { shrink: Boolean(v) }) ||
      {}
    );
  };

  const placeView = (opValue: Array<string> | string) => {
    if (Array.isArray(opValue)) {
      const valueList: Array<string> = [];
      opValue.forEach((v) => {
        const vLabel = (list ?? []).filter((o) => o.value === v);
        valueList.push(vLabel.length > 0 ? vLabel[0].label : "");
      });
      return valueList.join(", ");
    }
    const opLabel = (list ?? []).filter((o) => o.value === opValue);
    return opLabel.length > 0 ? opLabel[0].label : "";
  };

  const renderPlace = () => {
    if (label && (!value || value?.length === 0)) {
      return undefined;
    }
    if (!value || value?.length === 0) {
      return (
        <option
          style={{
            color: "rgba(0, 0, 0, 0.54)",
            padding: 0,
          }}
        >
          {placeholder}
        </option>
      );
    }
    return placeView(value);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          ...style,
        }}
        className={classes?.container}
      >
        <FormControl
          fullWidth={fullWidth}
          disabled={disabled}
          style={{ minWidth: 150 }}
          variant={variant}
          size={size}
          margin={margin}
          {...formControlProps}
        >
          {label ? (
            <InputLabel
              {...shrinker(value)}
              error={error}
              id="Custom-picker-label"
            >
              {label}
            </InputLabel>
          ) : null}
          <Select
            labelId="Custom-picker-label"
            multiple={multiple}
            value={value ?? (multiple ? [] : "")}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              onChange?.(event.target.value);
            }}
            className={clsx(className, {
              [styles.selectSVGIcon]: value && clearable,
            })}
            error={error}
            fullWidth={fullWidth}
            displayEmpty
            inputProps={{
              margin,
            }}
            endAdornment={
              value && clearable ? (
                <InputAdornment position="end">
                  <IconButton
                    style={{ padding: 4 }}
                    onClick={() => onChange?.(undefined)}
                  >
                    <Icons.CloseOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : null
            }
            input={
              variant === Variant.outlined ? (
                <OutlinedInput
                  id="Custom-picker-label"
                  labelWidth={label ? (label.length + 3) * 8 : 0}
                />
              ) : (
                <Input />
              )
            }
            MenuProps={MenuProps}
            renderValue={renderPlace}
            {...SelectProps}
          >
            {placeholder ? (
              <MenuItem
                value="placeholder"
                disabled={typeof placeholder === "string"}
              >
                {placeholder}
              </MenuItem>
            ) : null}
            {list?.map?.((item) => {
              return (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText error={error}>{errorMessage}</FormHelperText>
        </FormControl>
      </div>
      {loading && <LinearProgress />}
    </>
  );
};

export default CustomPicker;
