import MarkdownTheme from "@udok/lib/components/Markdown/MarkdownTheme";
import { dataUrlToFile } from "@udok/lib/internal/util";

function splitTextMarkdown(markdown: string, size: number) {
  if (markdown.length <= size) {
    return markdown;
  }
  let stack: string[] = [];
  let newText = Array.from(markdown.substring(0, size)).map((char, i, l) => {
    const isLast = i === l.length - 1;
    const prefix = l?.[i - 1];
    const sufix = l?.[i + 1];
    if (prefix != "\\") {
      if (char === "~" && isLast) {
        return "";
      }
      if (
        char === "*" &&
        (prefix != "*" || prefix + l?.[i - 2] === "**") &&
        sufix != "*"
      ) {
        const ind = stack.indexOf(char);
        if (ind === -1) {
          if (isLast) {
            return "";
          }
          stack = [...stack, char];
        } else {
          stack.splice(ind, 1);
        }
        return char;
      }
      const cs = char + sufix;

      if (prefix != "*" && cs === "**") {
        const ind = stack.indexOf(cs);
        if (ind === -1) {
          stack = [...stack, cs];
        } else {
          stack.splice(ind, 1);
        }
        return char;
      }
      if (cs === "~~") {
        const ind = stack.indexOf(cs);
        if (ind === -1) {
          stack = [...stack, cs];
        } else {
          stack.splice(ind, 1);
        }
        return char;
      }
    }
    return char;
  });

  let text = newText.join("").trim();
  text += stack.reverse().join("") + "...";
  return text;
}

function clearMarkdownTags(markdown: string) {
  if ((markdown ?? "").length == 0) {
    return markdown;
  }
  let newText = Array.from(markdown).map((char, i, l) => {
    if (l?.[i - 1] != "\\" && (char === "~" || char === "*")) {
      return "";
    }
    return char;
  });

  return newText.join("");
}

const RegexMarkdownBase6 = /data:image\/[^;]+;base64,[^)]+/g;
async function getMarkdownImageFiles(markdown: string) {
  const matches = markdown.match(RegexMarkdownBase6) || [];

  const files = await Promise.all(
    matches.map((b64) =>
      dataUrlToFile(b64, `${Math.floor(Math.random() * 4200)}.png`)
    )
  );
  return files;
}

function replaceMarkdownEmbeddedImages(
  markdown: string,
  replacements: string[]
) {
  let currentIndex = 0;

  const replacedText = markdown.replace(RegexMarkdownBase6, (match) => {
    const replacement = replacements?.[currentIndex] ?? match;
    currentIndex++;
    return replacement;
  });

  return replacedText;
}

export {
  MarkdownTheme,
  splitTextMarkdown,
  clearMarkdownTags,
  getMarkdownImageFiles,
  replaceMarkdownEmbeddedImages,
};
