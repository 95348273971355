import { connect } from "react-redux";

import Delete from "@udok/lib/components/CRUD/Delete";
import { removeOneSchedule } from "ducks/schedule";

export default connect(
  () => ({}),
  (dispatch: any) => ({
    doDelete: (sescID: string) => dispatch(removeOneSchedule(sescID)),
  })
)(Delete);
