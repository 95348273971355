import React from "react";
import { useSelector } from "react-redux";
import { getSubscriptionOffers } from "ducks/user";
import OffersViewer, { Offer } from "@udok/lib/components/OffersViewer";
import { SubscriptionOffer, ActiveSubscription } from "@udok/lib/api/models";

export type SubscriptionOfferListProps = {
  currentSubscription?: ActiveSubscription;
  onSelect: (offer: SubscriptionOffer) => void;
};

function getPriceLabel(price: number, custom: boolean) {
  if (custom) {
    return "Personalizado";
  }
  return price > 0 ? price : "Gratuito";
}

const SubscriptionOfferList = (props: SubscriptionOfferListProps) => {
  const { currentSubscription, onSelect } = props;
  const { offers = [] } = useSelector(getSubscriptionOffers);
  const currentSuplID = currentSubscription?.suplID;
  let listOffer = offers
    .filter((offer) => offer.suplID !== currentSuplID)
    .map((offer) => {
      return {
        price: getPriceLabel(
          offer.price,
          Boolean(offer?.displaySettings?.contactSalesForPricing)
        ),
        id: offer.suofID,
        name: offer.name,
        description: offer.description,
        planDescription: offer.planDescription,
        recommended: offer.displaySettings?.recommended,
        billingDescription: offer.billingDescription,
      } as Offer;
    });
  if (!!currentSubscription) {
    listOffer = [
      {
        price: getPriceLabel(
          currentSubscription.price,
          Boolean(currentSubscription.info?.customPlan)
        ),
        id: currentSubscription.subsID,
        name: currentSubscription.planName,
        description: "",
        planDescription: currentSubscription.planDescription,
        recommended: false,
        billingDescription: currentSubscription.billingDescription,
        buttonLabel: "Plano atual",
        buttonProps: { disabled: true },
      } as Offer,
      ...listOffer,
    ];
  }

  const handleSelect = React.useCallback(
    (suofID: string) => {
      const offer = offers.find((o) => o.suofID === suofID);
      if (offer) {
        onSelect(offer);
      }
    },
    [offers, onSelect]
  );

  return <OffersViewer offers={listOffer} onSelect={handleSelect} />;
};

export default SubscriptionOfferList;
