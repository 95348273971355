import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "ducks/state";
import {
  fetchOneDoctorPreference,
  getOneDoctorPreferences,
} from "ducks/doctorPreferences";
import {
  DoctorLicense,
  Preferences,
  PlanFeaturesPermissions,
} from "@udok/lib/api/models";
import {
  loadUserMe,
  getUserMe,
  getMyLicenses,
  loadMyLicenses,
  fetchCachedActiveSubscription,
  activeSubscriptionView,
  getValidSubscription,
  loadSEOListing,
  seoListingView,
  loadSubscriptionOffers,
  getSubscriptionOffers,
  fetchCachedUserMe,
  getBillingInformation,
  fetchCachedBillingInformation,
  fetchCachedLicense,
  getMyLicense,
  getFiltredLicenses,
} from "ducks/user";
import {
  locationByLocaID,
  loadLocations,
  getDoctorLocations,
} from "ducks/location";
import {
  loadAllSchedules,
  getDoctorSchedules,
  listDoctorSchedulesLocks,
} from "ducks/schedule";
import { prescriptionLayoutListView } from "ducks/prescriptionLayout";
import { getConversationsView } from "ducks/socket";
import { featureAccessPermission } from "@udok/lib/app/subscription";

export const useGetUserMe = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(loadUserMe()),
      dispatch(
        fetchOneDoctorPreference(
          Preferences.prescriptionUseDigitalizedSignature
        )
      ),
    ]).finally(() => setLoading(false));
  }, [dispatch]);

  const getPreference = React.useCallback(
    getOneDoctorPreferences({
      prefID: Preferences.prescriptionUseDigitalizedSignature,
    }),
    []
  );

  const { currentUser } = useSelector(getUserMe);
  const { location: signaturePref } = useSelector(getPreference);
  const signatureVisible = !!signaturePref?.options?.useInSignature;

  return [loading, currentUser, signatureVisible] as [
    typeof loading,
    typeof currentUser,
    typeof signatureVisible
  ];
};

export const useGetSignatureVisible = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(
      fetchOneDoctorPreference(Preferences.prescriptionUseDigitalizedSignature)
    ).finally(() => setLoading(false));
  }, [dispatch]);

  const getPreference = React.useCallback(
    getOneDoctorPreferences({
      prefID: Preferences.prescriptionUseDigitalizedSignature,
    }),
    []
  );

  const { location: signaturePref } = useSelector(getPreference);
  const signatureVisible = !!signaturePref?.options?.useInSignature;

  return [loading, signatureVisible] as [
    typeof loading,
    typeof signatureVisible
  ];
};

export const useGetMyLicenses = (filter?: {
  prescriptionAllowedLicenses?: boolean;
}) => {
  const [loading, setLoading] = React.useState(true);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadMyLicenses(filter)).finally(() => setLoading(false));
  }, [dispatch, filter]);

  const { doctorLicenses } = useSelector(getMyLicenses);
  const { filtredLicenses } = useSelector(getFiltredLicenses);

  return [loading, doctorLicenses, filtredLicenses] as [
    typeof loading,
    typeof doctorLicenses,
    typeof filtredLicenses
  ];
};

export const usePrescriptionLicensesByLocation = () => {
  const locationByID = useSelector(locationByLocaID);
  const { doctorLicenses } = useSelector(getMyLicenses);
  const dispatch: AppDispatch = useDispatch();

  const licenseForLocation = React.useCallback(
    async (locaID: string) => {
      let licenses: DoctorLicense[] = doctorLicenses;
      if (licenses.length === 0) {
        try {
          licenses = await dispatch(
            loadMyLicenses({ prescriptionAllowedLicenses: true })
          );
        } catch (e) {
          return;
        }
      }
      const uf = locationByID[locaID].addressInfo?.uf?.toLowerCase?.();
      const d = licenses.find((l) => l.region?.toLowerCase?.() === uf)?.doliID;
      return d;
    },
    [dispatch, doctorLicenses, locationByID]
  );

  return [licenseForLocation] as [typeof licenseForLocation];
};

export const useCurrentUserProfile = () => {
  const { currentUser } = useSelector(getUserMe);
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (!!currentUser) {
      return;
    }
    setLoading(true);
    dispatch(loadUserMe()).finally(() => setLoading(false));
  }, [currentUser, dispatch]);

  return [currentUser, loading] as [typeof currentUser, typeof loading];
};

export const useActiveSubscriptionView = () => {
  const [loading, setLoading] = React.useState(true);
  const dispatch: AppDispatch = useDispatch();
  const { subscription, payload } = useSelector(activeSubscriptionView);

  React.useEffect(() => {
    if (!payload?.userID) {
      return;
    }
    dispatch(fetchCachedActiveSubscription()).finally(() => setLoading(false));
  }, [payload, dispatch]);

  return [loading, subscription, payload] as [
    typeof loading,
    typeof subscription,
    typeof payload
  ];
};

export const useGetValidSubscription = () => {
  const [loading, setLoading] = React.useState(true);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchCachedActiveSubscription()).finally(() => setLoading(false));
  }, [dispatch]);

  const { subscription } = useSelector(getValidSubscription);

  return [loading, subscription] as [typeof loading, typeof subscription];
};

export const useGetFeatureAccessPermission = (
  feature: PlanFeaturesPermissions | PlanFeaturesPermissions[] = []
) => {
  const features = typeof feature === "string" ? [feature] : feature;
  const { subscription } = useSelector(getValidSubscription);
  const { list: LocList = [] } = useSelector(getDoctorLocations);
  const { list: schList = [] } = useSelector(getDoctorSchedules);
  const { list: pltList = [] } = useSelector(prescriptionLayoutListView);
  const { list: locksList = [] } = useSelector(listDoctorSchedulesLocks);
  const { listActivities } = useSelector(getConversationsView);
  const permissions = subscription?.planFeatures?.permissions ?? [];

  const featurePermissions = featureAccessPermission(
    features,
    LocList,
    schList,
    pltList,
    locksList,
    listActivities,
    permissions
  );

  return [featurePermissions, subscription] as [
    typeof featurePermissions,
    typeof subscription
  ];
};

export const useGetDoctorInformation = () => {
  const [loading, setLoading] = React.useState(true);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    Promise.all([
      dispatch(loadUserMe()),
      dispatch(loadLocations()),
      dispatch(loadAllSchedules()),
    ]).finally(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, [dispatch]);

  const { currentUser } = useSelector(getUserMe);
  const { list: LocList } = useSelector(getDoctorLocations);
  const { list: schList } = useSelector(getDoctorSchedules);

  return [loading, currentUser, LocList, schList] as [
    typeof loading,
    typeof currentUser,
    typeof LocList,
    typeof schList
  ];
};

export const useGetSEOListing = () => {
  const [loading, setLoading] = React.useState(true);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);

    dispatch(loadSEOListing()).finally(() => setLoading(false));
  }, [dispatch]);

  const { listing } = useSelector(seoListingView);

  return [loading, listing] as [typeof loading, typeof listing];
};

export const useGetSubscriptionOffers = () => {
  const [loading, setLoading] = React.useState(true);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(loadSubscriptionOffers()).finally(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, [dispatch]);

  const { offers = [] } = useSelector(getSubscriptionOffers);

  return [loading, offers] as [typeof loading, typeof offers];
};

export const useGetBillingInformation = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(fetchCachedUserMe()),
      dispatch(fetchCachedBillingInformation()),
    ]).finally(() => setLoading(false));
  }, [dispatch]);

  const { currentUser } = useSelector(getUserMe);
  const { billingInformation } = useSelector(getBillingInformation);

  return [loading, currentUser, billingInformation] as [
    typeof loading,
    typeof currentUser,
    typeof billingInformation
  ];
};

export const useGetOneLicense = (doliID: number) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchCachedLicense(doliID)).finally(() => setLoading(false));
  }, [doliID, dispatch]);

  const getLicense = React.useCallback(getMyLicense({ doliID }), [doliID]);
  const { license } = useSelector(getLicense);

  return [loading, license] as [typeof loading, typeof license];
};
