import React from "react";
import { Typography, IconButton } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  SubscriptionOffer,
  CreditCard,
  CustomerAddress,
  BillingInformationForm,
} from "@udok/lib/api/models";
import Icons from "@udok/lib/components/Icon";
import CreditcardImage from "@udok/lib/components/CreditCard/Image";
import {
  formatBRL,
  cpfNormalize,
  cnpjNormalize,
} from "@udok/lib/internal/util";
import {
  getDocumentType,
  BankDocumentType,
} from "components/DocumentTypePicker";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: "flex",
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: theme.spacing(1),
      margin: theme.spacing(1, 0),
      padding: theme.spacing(2),
      cursor: "pointer",
      color: theme.palette.primary.main,
      alignItems: "center",
      "&:hover": {
        boxShadow: theme.shadows[3],
        borderColor: theme.palette.primary.dark,
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
        minWidth: 0,
      },
    },
    activeCard: {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.dark,
      background: `${theme.palette.primary.light} linear-gradient(20deg, ${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
      cursor: "auto",
      pointerEvents: "none",
      "& > *:nth-child(2)": {
        display: "none",
      },
      "& .itemSecondary": {
        color: theme.palette.common.white,
      },
    },
    cardDisable: {
      cursor: "auto",
      pointerEvents: "none",
      "& > *:nth-child(2)": {
        display: "none",
      },
    },
    primary: {
      display: "flex",
      alignItems: "center",
      textTransform: "capitalize",
      "& > *:nth-child(n+2)": {
        marginLeft: theme.spacing(1),
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(1),
      },
    },
    secondary: {
      [theme.breakpoints.down("sm")]: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
    },
  })
);

export enum SubscriptionProperties {
  offer = "offer",
  creditCard = "creditCard",
  address = "address",
  billingInfo = "billingInfo",
  confirm = "confirm",
  success = "success",
}

type SelectedTypeView = {
  primary: React.ReactNode;
  secondary: React.ReactNode;
  screenKey: SubscriptionProperties;
  disabled?: boolean;
};

function makeOfferView(selectedOffer?: SubscriptionOffer) {
  let offerView: SelectedTypeView = {
    primary: "Offerta",
    secondary: "Selecione uma oferta",
    screenKey: SubscriptionProperties.offer,
  };
  if (!!selectedOffer) {
    let price =
      (selectedOffer?.price ?? 0) > 0
        ? `R$${formatBRL(selectedOffer!.price)}`
        : "Gratuito";
    if (selectedOffer?.displaySettings?.contactSalesForPricing) {
      price = "Personalizado";
    }
    offerView = {
      ...offerView,
      primary: selectedOffer?.name,
      secondary: (
        <b>
          <span>{price}</span>&nbsp;
          <span>{selectedOffer?.billingDescription ?? ""}</span>
        </b>
      ),
    };
  }
  return offerView;
}

function makeCardView(selectedCard?: CreditCard) {
  let cardView: SelectedTypeView = {
    primary: "Cartão de crédito",
    secondary: "Selecione seu cartão.",
    screenKey: SubscriptionProperties.creditCard,
  };
  if (!!selectedCard) {
    cardView = {
      ...cardView,
      disabled: false,
      primary: (
        <>
          <CreditcardImage
            creditcard={selectedCard?.brand ?? ""}
            height="auto"
            width={40}
          />
          <span>{selectedCard?.brand}</span>
        </>
      ),
      secondary: (
        <b>
          <span>Cartão de crédito terminando em •••• </span>
          <span>{selectedCard?.name}</span>
        </b>
      ),
    };
  }
  return cardView;
}

function makeAddressView(selectedAddress?: CustomerAddress) {
  let addressView: SelectedTypeView = {
    primary: "Endereço de cobrança",
    secondary: "Atualize seu endereço para cobranças.",
    screenKey: SubscriptionProperties.address,
  };
  if (!!selectedAddress) {
    const address = `${selectedAddress?.street ?? ""}, Nº ${
      selectedAddress?.street_number ?? ""
    } - ${selectedAddress?.zipcode ?? ""}`;
    addressView = {
      ...addressView,
      disabled: false,
      secondary: <b>{address}</b>,
    };
  }
  return addressView;
}

function makeInformationView(
  selectedInfo?: Omit<BillingInformationForm, "billingAddress">
) {
  let addressView: SelectedTypeView = {
    primary: "Informações pessoais",
    secondary: "Atualize os seus dados para cobranças.",
    screenKey: SubscriptionProperties.billingInfo,
  };
  if (!!selectedInfo) {
    const docType = getDocumentType(selectedInfo?.documentNumber ?? "");
    let doc = `CPF: ${cpfNormalize(selectedInfo?.documentNumber ?? "", "")}`;
    if (docType === BankDocumentType.cnpj) {
      doc = `CNPJ: ${cnpjNormalize(selectedInfo?.documentNumber ?? "", "")}`;
    }
    addressView = {
      ...addressView,
      disabled: false,
      primary: selectedInfo?.name,
      secondary: <b>{`${doc}, Email: ${selectedInfo?.email}`}</b>,
    };
  }
  return addressView;
}

export type ScreenHistoryProps = {
  selectedOffer?: SubscriptionOffer;
  selectedCard?: CreditCard;
  selectedAddress?: CustomerAddress;
  selectedInfo?: Omit<BillingInformationForm, "billingAddress">;
  editable?: boolean;
  screen: SubscriptionProperties;
  setScreen: (screen: SubscriptionProperties) => void;
};

const ScreenHistory = (props: ScreenHistoryProps) => {
  const {
    selectedOffer,
    selectedCard,
    selectedAddress,
    selectedInfo,
    editable = true,
    screen,
    setScreen,
  } = props;
  const classes = useStyles();

  let selectedItens = [
    ...(!!selectedOffer ? [makeOfferView(selectedOffer)] : []),
    ...(!!selectedOffer &&
    selectedOffer.price !== 0 &&
    selectedOffer.billingEnabled
      ? [
          makeCardView(selectedCard),
          makeAddressView(selectedAddress),
          makeInformationView(selectedInfo),
        ]
      : []),
  ];
  return (
    <>
      {selectedItens.map((item) => (
        <CardView
          onClick={() => setScreen(item.screenKey)}
          disabled={!editable || item.disabled}
          active={screen === item.screenKey}
        >
          <Typography variant="h6" color="inherit" className={classes.primary}>
            {item.primary}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={clsx(classes.secondary, "itemSecondary")}
          >
            {item.secondary}
          </Typography>
        </CardView>
      ))}
    </>
  );
};

type CardViewProps = {
  children: React.ReactNode;
  disabled?: boolean;
  active?: boolean;
  onClick: () => void;
};

const CardView = (props: CardViewProps) => {
  const { children, disabled, active, onClick } = props;
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.card, {
        [classes.cardDisable]: disabled,
        [classes.activeCard]: active,
      })}
      onClick={onClick}
    >
      <div style={{ width: "90%" }}>{children}</div>
      <div style={{ flex: 0 }}>
        <IconButton color="default">
          <Icons.EditRounded />
        </IconButton>
      </div>
    </div>
  );
};

export default ScreenHistory;
