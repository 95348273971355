import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import { Autocomplete, FilterOptionsState } from "@material-ui/lab";
import { SelectProps, TextField, CircularProgress } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  searchProceduresListView,
  searchListProcedures,
  fetchCachedProcedure,
} from "ducks/procedure";
import { debounce } from "@udok/lib/internal/util";
import { ExamProcedure } from "@udok/lib/api/models";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tag: {
      maxWidth: 350,
    },
  })
);

export type SearchExamsAndProceduresProps = {
  onChange?: (v: string | string[] | undefined | null) => void;
  value?: string | string[];
  error?: boolean;
  multiple?: boolean;
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  fullWidth?: SelectProps["fullWidth"];
  placeholder?: string;
  disabled?: boolean;
  errorMessage?: string;
  size?: "small" | "medium";
  margin?: "none" | "dense" | "normal";
  variant?: "filled" | "outlined" | "standard";
};

const DefaultExamProcedure: ExamProcedure = {
  exprID: "",
  title: "",
  description: "",
  slug: "",
};

const SearchExamsAndProcedures = React.forwardRef(
  (props: SearchExamsAndProceduresProps, ref: React.Ref<any>) => {
    const {
      onChange,
      error,
      multiple,
      value,
      label,
      fullWidth,
      placeholder,
      disabled,
      style,
      className,
      errorMessage,
      size = "small",
      margin = "none",
      variant = "outlined",
    } = props;
    const dispatch: AppDispatch = useDispatch();
    const { allProcedures, filteredProcedures } = useSelector(
      searchProceduresListView
    );
    const [open, setOpen] = React.useState(false);
    const [searchload, setSearchLoad] = React.useState(false);
    const classes = useStyles();

    const search = React.useCallback(
      debounce(
        (searchText: string) => {
          setSearchLoad(true);
          dispatch(searchListProcedures({ searchText })).finally(() =>
            setSearchLoad(false)
          );
        },
        500,
        false
      ),
      [dispatch]
    );

    React.useEffect(() => {
      if (value) {
        const val = Array.isArray(value) ? value : [value];
        setSearchLoad(true);
        Promise.all(val.map((v) => dispatch(fetchCachedProcedure(v)))).finally(
          () => setSearchLoad(false)
        );
      }
    }, [value, dispatch]);

    React.useEffect(() => {
      if (open) {
        setSearchLoad(true);
        dispatch(searchListProcedures()).finally(() => setSearchLoad(false));
      }
    }, [open, dispatch]);

    const handleFilter = React.useCallback(
      (options: ExamProcedure[], params: FilterOptionsState<ExamProcedure>) => {
        if (filteredProcedures.length > 0) {
          return options.filter(
            (o) =>
              filteredProcedures.findIndex((f) => f.exprID === o.exprID) !== -1
          );
        }
        return options.slice(0, 100);
      },
      [filteredProcedures]
    );

    let val = React.useMemo(() => {
      if (multiple) {
        return (Array.isArray(value) ? (value as string[]) : [value])
          .map((v) => allProcedures.find((e) => e.exprID === v))
          .filter((o) => !!o) as ExamProcedure[];
      }
      return (
        allProcedures.find((e) => e.exprID === (String(value) ?? "")) ??
        DefaultExamProcedure
      );
    }, [value, multiple, allProcedures]);

    return (
      <Autocomplete
        ref={ref}
        multiple={multiple}
        fullWidth={fullWidth}
        value={val || []}
        options={allProcedures}
        loading={searchload}
        style={style}
        filterOptions={handleFilter}
        getOptionLabel={(option) => {
          if (!option.exprID) {
            return "";
          }
          return `${option?.tussCode ? option.tussCode + " - " : ""}${
            option.title
          }`;
        }}
        disabled={disabled}
        clearText="Limpar"
        closeText="Fechar"
        noOptionsText="Nenhum item encontrado"
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) => {
          return option?.exprID === value?.exprID;
        }}
        onChange={(e, value) => {
          if (multiple && Array.isArray(value)) {
            onChange?.(value?.map?.((e) => e?.exprID ?? ""));
          } else {
            onChange?.((value as ExamProcedure)?.exprID);
          }
        }}
        className={className}
        classes={{
          tag: classes.tag,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant as any}
            margin={margin}
            size={size}
            label={label}
            placeholder={placeholder}
            error={error}
            helperText={errorMessage}
            onChange={(event) => {
              const value: string = event?.target?.value ?? "";
              if ((value?.trim?.()?.length ?? 0) !== 0) {
                search(value);
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {searchload ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }
);

export default SearchExamsAndProcedures;
