import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Card, Typography, Button, Chip } from "@material-ui/core";
import { CardOfferProps } from "@udok/lib/components/OffersViewer/Types";
import PlanDescriptionView from "@udok/lib/components/OffersViewer/PlanDescriptionView";
import { formatBRL } from "@udok/lib/internal/util";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => {
  const colorWhite = theme.palette.common.white;
  const colorBlack = theme.palette.common.black;
  const colorMain = theme.palette.primary.main;
  const colorMainDark = theme.palette.primary.dark;
  const colorMainLight = theme.palette.primary.light;
  const colorNeutralLight = theme.palette.neutral.light;
  return createStyles({
    root: {
      minWidth: 300,
      maxWidth: 300,
      padding: theme.spacing(2),
      boxShadow: "none",
      background: colorWhite,
      color: colorBlack,
      border: `1px solid ${colorMain}`,
      "&:hover": {
        borderColor: colorMainDark,
        boxShadow: theme.shadows[3],
      },
      "& > *:first-child": {
        marginBottom: theme.spacing(2),
      },
      "& > *:nth-child(n+2)": {
        marginBottom: theme.spacing(3),
      },
      "& button": {
        color: colorWhite,
        backgroundColor: colorMain,
      },
      "& button:hover": {
        backgroundColor: colorMainDark,
      },
      "& .secondary": {
        minHeight: 21,
      },
    },
    recommended: {
      color: colorWhite,
      backgroundColor: colorMain,
      "&:hover": {
        borderColor: colorMainDark,
        boxShadow: theme.shadows[6],
      },
      "& button": {
        color: colorBlack,
        backgroundColor: colorWhite,
      },
      "& button:hover": {
        backgroundColor: colorNeutralLight,
      },
      "& .secondary": {
        color: colorNeutralLight,
      },
    },
    text: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    chip: {
      borderRadius: theme.spacing(),
      color: colorWhite,
      backgroundColor: colorMainLight,
    },
    chipHidden: {
      visibility: "hidden",
    },
  });
});

const CardOffer = React.forwardRef(
  (props: CardOfferProps, ref: React.Ref<any>) => {
    const { onSelect, classes: classesProps } = props;
    const {
      id,
      name,
      description,
      planDescription,
      price,
      billingDescription,
      buttonLabel = "Adquirir",
      buttonProps,
      recommended,
    } = props.offer;
    const classes = useStyles();

    const handleSelect = React.useCallback(() => {
      onSelect(id);
    }, [id, onSelect]);

    const priceLabel =
      typeof price === "number" ? `R$${formatBRL(price)}` : price;
    const priceDesc =
      (billingDescription ?? "").length > 0 ? billingDescription : " ";
    return (
      <Card
        ref={ref}
        className={clsx(classes.root, classesProps?.root, {
          [classes.recommended]: recommended,
        })}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Chip
            size="small"
            label="Recomendado"
            className={clsx(classes.chip, {
              [classes.chipHidden]: !recommended,
            })}
          />
        </div>
        <div>
          <Typography variant="h6" color="inherit" className={classes.text}>
            {name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={clsx(classes.text, "secondary")}
          >
            {description}
          </Typography>
        </div>
        <div>
          <Typography variant="h4" color="inherit" className={classes.text}>
            {priceLabel}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={clsx(classes.text, "secondary")}
          >
            {priceDesc}
          </Typography>
        </div>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSelect}
          className={classesProps?.button}
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
        <PlanDescriptionView
          recommended={recommended}
          description={planDescription}
        />
      </Card>
    );
  }
);

export default CardOffer;
