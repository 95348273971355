import * as React from "react";
import { connect } from "react-redux";
import { Location, Healthplan } from "@udok/lib/api/models";
import { changeLocation, SchedulePlansAlertView } from "ducks/location";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import AlertDialog from "@udok/lib/components/Dialog/AlertDialog";

export interface Props {
  locaID: string;
  extraPlans?: string[];
  open: boolean;
  close: (p?: any) => void;
  location: Location;
  listPlans: Healthplan[];
  changeLocation: (location: Location) => Promise<void>;
}

const DialogSchedulePlans = (props: Props) => {
  const { open, close, location, extraPlans, listPlans, changeLocation } =
    props;
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    if (!extraPlans) {
      return;
    }
    const newPlanList = [...(location.healthPlans ?? []), ...extraPlans];
    const locationParam = {
      ...location,
      healthPlans: newPlanList,
    } as Location;

    setLoading(true);
    changeLocation(locationParam)
      .then(() => {
        close();
      })
      .catch(console.warn)
      .finally(() => setLoading(false));
  };

  const listplansName = (extraPlans ?? []).map((id) => {
    const h = listPlans.filter((p) => p.heplID === id)[0];
    return {
      heplID: h.heplID,
      name: h.name,
    };
  });

  const description = (
    <div>
      <Typography variant="body1">
        {`Voce adicionou planos de saúde neste horário que não estão cadastrados
        em ${location.name}. Gostaria de atualizar os planos de ${location.name} e
        adicionar os planos abaixo?`}
      </Typography>
      <List style={{ maxHeight: 250, overflow: "auto" }}>
        {listplansName.map((p, i) => (
          <ListItem key={p.heplID}>
            <ListItemText primary={p.name} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <AlertDialog
      title="Planos de saúde"
      description={description}
      visible={open}
      onAcept={handleSubmit}
      onClose={() => close()}
      loading={loading}
    />
  );
};

export default connect(SchedulePlansAlertView, (dispatch: any) => ({
  changeLocation: (l: Location) => dispatch(changeLocation(l)),
}))(DialogSchedulePlans);
