import React from "react";

import {
  Button,
  DialogTitle,
  DialogContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  IconButton,
  Typography,
} from "@material-ui/core";
import Dialog from "@udok/lib/components/Dialog/ResponsiveDialog";
import { Close } from "@material-ui/icons";
import SignatureBuilder from "./SignatureBuilder";
import { dataUrlToFile } from "@udok/lib/internal/util";
import TabPanel from "@udok/lib/components/TabPanel";
import FileAsDataURLInput from "@udok/lib/components/Input/FileAsDataURLInput";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

export enum selectType {
  upload = "upload",
  signature = "signature",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inpuImageContainer: {
      margin: theme.spacing(2, 0),
    },
    ButtonContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      margin: theme.spacing(3, 0),
    },
    buttonSave: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  })
);

const ModalSignature = ({
  open,
  close,
  onChange,
}: {
  open: boolean;
  close: (p?: any) => void;
  onChange?: (value?: File) => void;
}) => {
  const [component, setComponent] = React.useState<string>(
    selectType.signature
  );
  const [imgData, setImgData] = React.useState<string>();
  const classes = useStyles();

  const saveSignature = async (img: string) => {
    await dataUrlToFile(img, "modal-signature-result.png").then((img) => {
      onChange?.(img);
    });
    close();
  };

  const width = 716;
  const height = 148;
  return (
    <Dialog
      open={open}
      closeOnGoBack
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      onClose={close}
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle
        disableTypography
        style={{ padding: 10, paddingLeft: 20, display: "flex" }}
      >
        <Typography variant="h6" style={{ flex: 1 }}>
          Editar assinatura
        </Typography>
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" style={{ paddingTop: 10 }}>
          <FormLabel component="legend">Opções</FormLabel>
          <RadioGroup
            aria-label="options"
            name="option1"
            value={component}
            style={{ flexDirection: "row" }}
            onChange={(event) => {
              const val = event.target.value as selectType;
              setComponent(val);
            }}
          >
            <FormControlLabel
              value={selectType.upload}
              control={<Radio />}
              label="Escolher imagem"
            />
            <FormControlLabel
              value={selectType.signature}
              control={<Radio />}
              label="Desenhar Assinatura"
            />
          </RadioGroup>
        </FormControl>

        <TabPanel index={selectType.signature} value={component}>
          <>
            <FormLabel component="legend" style={{ paddingTop: 10 }}>
              Construa sua assinatura abaixo:
            </FormLabel>
            <SignatureBuilder
              width={width}
              height={height}
              onChange={saveSignature}
            />
          </>
        </TabPanel>

        <TabPanel index={selectType.upload} value={component}>
          <>
            <div className={classes.inpuImageContainer}>
              <FileAsDataURLInput
                htmlFor="modal-signature"
                value={imgData}
                onChange={(val) => setImgData(val.src ?? "")}
                resizeImage={true}
                resizeHeight={152}
                resizeWidth={698}
                imageBackgroundColor="#ffff"
              />
            </div>
            <div className={classes.ButtonContainer}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.buttonSave}
                onClick={() => {
                  if (imgData) {
                    saveSignature(imgData ?? "");
                  }
                }}
              >
                Salvar
              </Button>
            </div>
          </>
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};
export default ModalSignature;
