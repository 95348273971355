import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Stepper as UIStepper,
  Step,
  StepButton,
  StepContent,
  Typography,
  StepConnector,
  StepConnectorProps,
} from "@material-ui/core";
import {
  StepStatus,
  StepPalette,
  StepperProps,
} from "@udok/lib/components/Stepper/Types";
import Icons from "@udok/lib/components/Icon";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uIStepper: {
      padding: 0,
      marginTop: theme.spacing(2),
    },
    StepContentLast: {
      paddingLeft: 0,
      paddingRight: theme.spacing(1.5),
    },
  })
);

type VerticalStepperProps = StepperProps & {
  connectorProps?: StepConnectorProps;
  classes?: {
    stepper?: string;
    stepActive?: string;
    stepCompleted?: string;
    stepDefault?: string;
  };
};

const VerticalStepper = (props: VerticalStepperProps) => {
  const {
    value = 0,
    children,
    steps = [],
    classes: classesProps,
    connectorProps,
    handleClick,
  } = props;
  const classes = useStyles();

  const returnPalette = (index: number) => {
    const step = steps[index];
    switch (step.state) {
      case StepStatus.completed:
        return (
          step?.completedStyle ?? {
            backgroundColor: StepPalette.completedLight,
            textColor: StepPalette.completedMain,
            borderColor: StepPalette.completedMain,
          }
        );
      case StepStatus.active:
        return (
          step?.activeStyle ?? {
            backgroundColor: StepPalette.activeLight,
            textColor: StepPalette.activeMain,
            borderColor: StepPalette.activeMain,
          }
        );
      default:
        return (
          step?.defaultStyle ?? {
            backgroundColor: StepPalette.defaultLight,
            textColor: StepPalette.defaultMain,
            borderColor: StepPalette.defaultMain,
          }
        );
    }
  };

  const StepIcon = (index: number) => {
    const step = steps[index];
    return (
      <Icons.StepIcon
        width={24}
        height={24}
        text={step?.state !== StepStatus.completed ? String(index + 1) : ""}
        {...returnPalette(index)}
      />
    );
  };

  return (
    <UIStepper
      className={clsx(classes.uIStepper, classesProps?.stepper)}
      activeStep={value}
      orientation="vertical"
      connector={<StepConnector {...connectorProps} />}
    >
      {steps.map((step, index) => (
        <Step
          key={index}
          className={clsx({
            [classesProps?.stepActive ?? ""]: step.state === StepStatus.active,
            [classesProps?.stepCompleted ?? ""]:
              step.state === StepStatus.completed,
            [classesProps?.stepDefault ?? ""]:
              step.state === StepStatus.default,
          })}
          completed={step.state === StepStatus.completed}
        >
          <StepButton
            icon={StepIcon(index)}
            onClick={() => handleClick?.(index)}
            disabled={step.disabled}
          >
            <Typography style={{ marginTop: 0 }}>{step.title}</Typography>
          </StepButton>
          <StepContent
            classes={{
              last: classes.StepContentLast,
            }}
          >
            {children}
          </StepContent>
        </Step>
      ))}
    </UIStepper>
  );
};

export default VerticalStepper;
