import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import Illustrations from "@udok/lib/components/Illustrations";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "100%",
      color: theme.palette.success.main,
      "& > *": {
        marginBottom: theme.spacing(3),
      },
    },
    illustration: {
      flex: 1,
      display: "flex",
      paddingRight: theme.spacing(2),
      maxHeight: "45vh",
      maxWidth: "100%",
    },
  })
);

type SuccessSubscriptionProps = {
  successMessage?: string;
  onStart?: () => void;
};

const SuccessSubscription = (props: SuccessSubscriptionProps) => {
  const { successMessage, onStart } = props;
  const classes = useStyles();

  const message =
    (successMessage?.length ?? 0) > 0
      ? successMessage
      : "Assinatura realizada com sucesso!\nVocê já pode começar a utilizar a plataforma.";
  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        color="inherit"
        style={{ fontSize: 32, textAlign: "center" }}
      >
        Obrigado por utilizar a Udok!
      </Typography>
      <div className={classes.illustration}>
        <Illustrations.UdokDoctorGroup width="30em" height="auto" />
      </div>
      <Typography
        variant="body1"
        color="textPrimary"
        style={{
          whiteSpace: "break-spaces",
        }}
      >
        {message}
      </Typography>
      <div>
        <Button variant="contained" color="primary" onClick={onStart}>
          Iniciar na plataforma
        </Button>
      </div>
    </div>
  );
};

export default SuccessSubscription;
