import { PaperProps } from "@material-ui/core";

export enum StepStatus {
  completed = "completed",
  active = "active",
  default = "default",
}

export type PaletteProps = {
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
};

export type StepItem = {
  title: string;
  alertTitle?: string;
  subtitle?: string;
  icon?: string;
  isFinished?: boolean;
  activeStyle?: PaletteProps;
  completedStyle?: PaletteProps;
  defaultStyle?: PaletteProps;
  state: StepStatus;
  disabled?: boolean;
};

export enum Orientation {
  horizontal = "horizontal",
  vertical = "vertical",
}

export type StepperProps = {
  steps: StepItem[];
  value?: number;
  orientation?: Orientation;
  children?: React.ReactNode;
  paperProps?: PaperProps;
  showCardInfo?: boolean;
  showConnector?: boolean;
  handleClick?: (n: number) => void;
};

export enum StepPalette {
  completedMain = "#05974e",
  completedLight = "#f3fff9",
  activeMain = "#2680eb",
  activeLight = "#eff6ff",
  defaultMain = "#bcbcbc",
  defaultLight = "#ffffff",
}
