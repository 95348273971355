import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "ducks/state";
import {
  getListlocation,
  loadLocations,
  fetchCachedLocation,
  getOneLocation,
} from "ducks/location";
import { loadAllClinics } from "ducks/schedule";

export const useListLocations = (
  listAll: boolean,
  locaID?: string,
  loadData?: boolean
) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();
  const load = React.useMemo(() => {
    if (typeof loadData !== "boolean") {
      return true;
    }
    return loadData;
  }, [loadData]);

  React.useEffect(() => {
    if (load) {
      setLoading(true);
      Promise.all([
        locaID ? dispatch(fetchCachedLocation(locaID)) : undefined,
        dispatch(loadLocations()),
        dispatch(loadAllClinics()),
      ])
        .catch(console.warn)
        .finally(() => setLoading(false));
    }
  }, [load, locaID, dispatch]);

  const { list, listAllLocation, clinicByID } = useSelector(getListlocation);
  const listLocation = listAll ? listAllLocation : list;

  return [loading, listLocation, clinicByID] as [
    typeof loading,
    typeof listLocation,
    typeof clinicByID
  ];
};

export const useGetLocation = (locaID?: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (locaID) {
      setLoading(true);
      dispatch(fetchCachedLocation(locaID)).finally(() => setLoading(false));
    }
  }, [dispatch, locaID]);

  const { location } = useSelector((state: RootState) =>
    getOneLocation(state, { locaID: locaID ?? "" })(state)
  );

  return [loading, location] as [typeof loading, typeof location];
};
