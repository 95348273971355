import React from "react";
import {
  PrescriptionLayoutFilter,
  PlanFeaturesPermissions,
} from "@udok/lib/api/models";
import { DefaultPlteID } from "@udok/lib/internal/constants";
import { usePrescriptionListLayouts } from "hooks/prescriptionLayout";
import Selector, { SelectorProps } from "@udok/lib/components/Selector";
import ResourceAlertDialog from "containers/LimitedResourceAlert/Dialog";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";

export type SelectPrescriptionlayoutProps = {
  filter?: PrescriptionLayoutFilter & { plteID?: string; doctor?: boolean };
  value?: string;
  onChange?: (val: string) => void;
  validatePermission?: boolean;
} & Omit<SelectorProps, "onChange" | "value" | "list" | "placeholder">;

const [rendererResourceDialog, promiseResourceDialog] =
  createModal(ResourceAlertDialog);

const SelectPrescriptionlayout = React.forwardRef(
  (props: SelectPrescriptionlayoutProps, ref: React.Ref<any>) => {
    const { filter, value, validatePermission, onChange, ...others } = props;
    const [activeResourceAlert, setActive] = React.useState(true);
    const [loading, list, clinicByID] = usePrescriptionListLayouts(filter);

    const listItens = list.map((item) => {
      let clinicName = "";
      if (item?.clinID) {
        const clinic = clinicByID[item.clinID];
        clinicName = clinic?.name ? ` ( ${clinic?.name} )` : "( Clinica )";
      }
      return {
        label: `${item.name}${clinicName}`,
        value: item.plteID,
      };
    });

    let val = value;
    if (value && listItens.findIndex((i) => i.value === value) === -1) {
      const existDefault =
        listItens.findIndex((i) => i.value === DefaultPlteID) !== -1;
      val = existDefault ? DefaultPlteID : listItens?.[0]?.value;
    }

    let placeholder: string | undefined;
    if (listItens.length === 0) {
      placeholder = "Nenhum item encontrado";
    }

    const handleChange = React.useCallback(
      async (value: string) => {
        if (
          validatePermission &&
          activeResourceAlert &&
          value !== DefaultPlteID
        ) {
          await promiseResourceDialog({
            feature: PlanFeaturesPermissions.PrescriptionCustomization,
          }).then(() => setActive(false));
        }
        onChange?.(value);
      },
      [activeResourceAlert, validatePermission, onChange]
    );

    return (
      <>
        <Selector
          ref={ref}
          value={val}
          onChange={handleChange}
          placeholder={placeholder}
          list={listItens}
          loading={loading || others?.loading}
          {...others}
        />
        {rendererResourceDialog}
      </>
    );
  }
);

export default SelectPrescriptionlayout;
