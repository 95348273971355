import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { fileDisplayView, loadFile, loadFileMetadata } from "ducks/files";
import { uploadOneFile } from "ducks/collectDocument";
import { AppDispatch, RootState } from "ducks/state";

export const useFileLoader = (fileID: string) => {
  const [loading, setL] = React.useState(false);
  const [failed, setF] = React.useState(false);
  const [dataurl, setD] = React.useState("");
  const { src, filename } = useSelector((state: RootState) =>
    fileDisplayView(state, { fileID })(state)
  );
  const dispatch: AppDispatch = useDispatch();

  const reload = React.useCallback(() => {
    setL(true);
    if (fileID) {
      Promise.all([
        dispatch(loadFileMetadata(fileID)),
        dispatch(loadFile(fileID)),
      ])
        .finally(() => {
          setL(false);
        })
        .then(() => setF(false))
        .catch(() => {
          setF(true);
        });
    }
  }, [fileID, dispatch]);

  React.useEffect(() => {
    reload();
  }, [fileID, reload]);

  React.useEffect(() => {
    if (!src) {
      return;
    }
    var a = new FileReader();
    a.onload = function (e) {
      setD(String(e?.target?.result ?? ""));
    };
    a.readAsDataURL(src);
  }, [src, setD]);

  return [dataurl, filename, loading, failed] as [
    string,
    string,
    boolean,
    boolean
  ];
};

export const useUploadOneFile = () => {
  const dispatch: AppDispatch = useDispatch();

  const onUpload = React.useCallback(
    (f: File | string) => {
      if (typeof f === "string") {
        return Promise.resolve(f);
      }
      return dispatch(uploadOneFile(f));
    },
    [dispatch]
  );

  return [onUpload] as [typeof onUpload];
};
