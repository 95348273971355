import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { DoctorNote, FilterDoctorNote } from "@udok/lib/api/models";
import {
  createDoctorNote,
  fetchDoctorNotes,
  fetchDoctorNote,
  updateDoctorNote,
} from "@udok/lib/api/doctorNote";
import { getToken, UNAUTHORIZED } from "./auth";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  doctorNoteByID: { [donoID: string]: DoctorNote };
};

// Reducers
const initialState: InitialState = {
  doctorNoteByID: {},
};

class DoctorNoteSlice extends Hen<InitialState> {
  noteLoaded(note: DoctorNote) {
    if (note.replaceDonoID) {
      delete this.state.doctorNoteByID[note.replaceDonoID];
    }
    this.state.doctorNoteByID[note.donoID] = note;
  }
  notesLoaded(notes: DoctorNote[]) {
    notes.forEach((note) => {
      this.state.doctorNoteByID[note.donoID] = note;
    });
  }
}

export const [Reducer, actions] = hen(new DoctorNoteSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const doctorNoteSelector = (state: RootState) =>
  state.doctorNote.doctorNoteByID;

function sortNoteList(a: DoctorNote, b: DoctorNote) {
  const bDate = b?.editedAt ?? b.createdAt;
  const aDate = a?.editedAt ?? a.createdAt;
  return moment(bDate).diff(moment(aDate));
}

export const getAppointmentNote = (props: { appoID: string }) =>
  createSelector(doctorNoteSelector, (doctorNoteByID) => {
    const listNotation = Object.keys(doctorNoteByID)
      .map((donoID) => doctorNoteByID[donoID])
      .filter((note) => note?.appoID === props.appoID)
      .sort(sortNoteList);
    return {
      appointmentNote: listNotation?.[0],
    };
  });

export const getPatientNote = (state: RootState, props: { patiID: string }) =>
  createSelector(doctorNoteSelector, (doctorNoteByID) => {
    const listNotation = Object.keys(doctorNoteByID)
      .map((donoID) => doctorNoteByID[donoID])
      .filter((note) => !note?.appoID && note?.patiID === props.patiID)
      .sort(sortNoteList);
    return {
      note: listNotation?.[0],
    };
  });

export const getNote = (props: { donoID: string }) =>
  createSelector(doctorNoteSelector, (doctorNoteByID) => {
    return {
      note: doctorNoteByID[props.donoID],
    };
  });

// Actions
export function createOneDoctorNote(
  note: DoctorNote
): AppThunk<Promise<DoctorNote>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createDoctorNote(apiToken, note)
      .then((r) => {
        dispatch(actions.noteLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Salvo com sucesso",
          })
        );
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadDoctorNotes(f?: FilterDoctorNote): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchDoctorNotes(apiToken, f)
      .then((r) => {
        dispatch(actions.notesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneDoctorNote(donoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchDoctorNote(apiToken, donoID)
      .then((r) => {
        dispatch(actions.noteLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function changeDoctorNote(
  donoID: string,
  text: string
): AppThunk<Promise<DoctorNote>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateDoctorNote(apiToken, donoID, text)
      .then((r) => {
        dispatch(actions.noteLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Salvo com sucesso",
          })
        );
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedDoctorNote(donoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const noteExist = Boolean(state.doctorNote.doctorNoteByID[donoID]);
    if (noteExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneDoctorNote(donoID));
  };
}
