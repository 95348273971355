import * as React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { useParams, useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import Loadable from "react-loadable";
import Loader from "@udok/lib/components/PageLoader";
import {
  HeaderIconTitle,
  HeaderTitle,
  ActiveChatUserContext,
  ChatVariant,
  ChatMessageSize,
} from "@udok/lib/components/UI/UIContext";
import ItemComponent from "containers/Chat/Conversations/ItemComponent";
import { PlanFeaturesPermissions } from "@udok/lib/api/models";
import ResourceAlertPopover from "containers/LimitedResourceAlert/Popover";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import clsx from "clsx";

export const Conversations = Loadable({
  loader: () => import("containers/Chat/Conversations/Conversations"),
  loading: Loader,
});

export const DirectMessage = Loadable({
  loader: () => import("containers/Chat/DirectMessage"),
  loading: Loader,
});

export const DirectMessageSimple = Loadable({
  loader: () => import("containers/Chat/DirectMessage/DirectMessageSimple"),
  loading: Loader,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "#eaeff1",
      flex: 1,
      flexDirection: "row" as "row",
      display: "flex",
    },
    menu: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      marginTop: 24,
    },
    closed: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

const [rendererResourceAlert, promiseResourceAlert] = createModal(
  (props: any) => (
    <ResourceAlertPopover
      {...props}
      feature={PlanFeaturesPermissions.UnlimitedChat}
      validateIfExceeded
    />
  )
);
export const ConversationsScreen = () => {
  const { userID } = useParams<{ userID: string }>();
  const { setChat } = React.useContext(ActiveChatUserContext);
  const history = useHistory();
  const classes = useStyles();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const megrID = query.get("megrID") ?? undefined;
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const onOpenChat = React.useCallback(
    (userID: string, e: React.MouseEvent<HTMLDivElement>) => {
      promiseResourceAlert({ anchorEl: e.target }).then((openOffer) => {
        if (openOffer) {
          return;
        }
        setChat({
          userID,
          messageSize: ChatMessageSize.large,
          variant: ChatVariant.wrap,
        });
      });
    },
    [setChat]
  );

  const handleClickLink = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      const button = e.button;
      if (button !== 2) {
        e.preventDefault();
        e.stopPropagation();
        const href = e.currentTarget.pathname;
        if (href === window.location.pathname) {
          return;
        }
        promiseResourceAlert({ anchorEl: e.target }).then(
          (openOffers: boolean) => {
            if (openOffers) {
              return;
            }
            if (button === 0) {
              history.push(href);
            } else {
              window.open(href, "_blank", "noopener noreferrer");
            }
          }
        );
      }
    },
    [history]
  );

  const itemComponent = React.useCallback(
    (props: any) => {
      let itemProps = { ...props };
      if (isSmallerScreen) {
        itemProps.onClick = onOpenChat;
      }
      return <ItemComponent {...itemProps} handleClickLink={handleClickLink} />;
    },
    [onOpenChat, handleClickLink, isSmallerScreen]
  );

  return (
    <main className={classes.root}>
      <HeaderTitle>
        <HeaderIconTitle title="Mensagens" icon="Chat" />
      </HeaderTitle>
      <Grid md={3} xs={12}>
        <Conversations
          selectedUserID={userID}
          listItemComponent={itemComponent}
        />
      </Grid>
      <Grid
        md={9}
        xs={12}
        className={clsx({ [classes.closed]: isSmallerScreen })}
      >
        {userID ? (
          <DirectMessage
            key={userID}
            userID={userID}
            megrID={megrID}
            variant={ChatVariant.wrap}
          />
        ) : null}
      </Grid>
      {rendererResourceAlert}
    </main>
  );
};

export default React.memo(ConversationsScreen);
